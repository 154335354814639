import { Component, OnInit } from '@angular/core';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaHeaderComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-header',
  templateUrl: './tcfa-header.component.html',
  styleUrls: ['./tcfa-header.component.scss']
})
export class TcfaHeaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
