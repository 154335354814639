import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
// Models.
import { BaseAppError } from '../error/base-app-error';
import { ISessionCreateResponse } from './i-session-create-response';
// Providers.
import { ErrorService } from '../error/error.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'SessionService';
_logger.debug(_TAG, 'loaded.');


@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private static _sessionToken: string;
  private static _sessionId: number;

  private baseURL = `${_config.apiBaseURL}/session`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {
  }

  public static getSessionToken(): string {
    return SessionService._sessionToken;
  }

  private static setSessionToken(token: string): boolean {
    SessionService._sessionToken = token;
    return true;
  }

  public static getSessionId(): number {
    return SessionService._sessionId;
  }

  private static setSessionId(id: number): boolean {
    SessionService._sessionId = id;
    return true;
  }

  public getOrCreateSession(): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'getOrCreateSession';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    if (SessionService.getSessionToken()) {
      return of(true);
    }

    const __endpointURL: string = this.baseURL,
      __payload = {};
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload:', __payload);

    return this.http.post<ISessionCreateResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<ISessionCreateResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
          SessionService.setSessionId(response.body.data.id);
        }),
        // Save & parse JWT session token. Returns flag.
        map((response: HttpResponse<ISessionCreateResponse>) => SessionService.setSessionToken(response.body.data.session_token)),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }
}
