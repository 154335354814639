export class InstallmentModel {
  number: number;
  cuotaPura: number;
  canon: number;
  costoMensual: number;
  iva: number;

  constructor(number: number, cuota_pura: number, canon: number, costo_mensual: number, iva: number) {
    this.number = number;
    this.cuotaPura = cuota_pura;
    this.canon = canon;
    this.costoMensual = costo_mensual;
    this.iva = iva;
  }

  public getCuotaFinal(loan_amount: number, insurance_installment: number, isUva: boolean = false): number {
    return (this.cuotaPura || 0) + (insurance_installment || 0) + (this.costoMensual || 0) +
      (this.iva || 0) + ((!isUva && this.number === 1) ? (loan_amount * 0.012) : 0);
  }

  public getCanonFinal(insurance_installment: number): number {
    return (this.canon || 0) + (insurance_installment || 0) + (this.costoMensual || 0) + (this.iva || 0);
  }
}
