import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
// Models.
import { BaseAppError } from '../error/base-app-error';
import { IDateroLogDoLogResponse } from './i-datero-log-do-log-response';
import { IDateroLogDoLogResponseData } from './i-datero-log-do-log-response-data';
// Providers.
import { ErrorService } from '../error/error.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'DateroLogService';
_logger.debug(_TAG, 'loaded.');


@Injectable({
  providedIn: 'root'
})
export class DateroLogService {
  private baseURL = `${_config.apiBaseURL}/datero`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {
  }

  public doLog(log_type: number, plazo: number = null, cuit: string = null, plan_id: number = null, subplan_id: number = null,
               tasa: number = null, brand_id: number = null, model_id: number = null, version_id: number = null,
               vehicle_type: number = null, email: string = null): Observable<number | BaseAppError> {
    const __SUBTAG = 'doLog';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');
    _logger.debug(_TAG, __SUBTAG, 'inputs:', 'Log Type:', log_type, '; Plazo:', plazo, '; CUIT:', cuit);

    const __endpointURL = `${this.baseURL}/log`;
    let __payload: any;
    switch (log_type) {
      case 7: // Fall-through.
      case 11:
        __payload = {
          lt: log_type, // Log type.
          vb: brand_id, // Brand ID.
          vm: model_id, // Model ID.
          vv: version_id, // Version ID.
          vt: vehicle_type, // Vehicle Type.
          ce: email, // E-mail.
          pl: plazo, // Installments count.
          ipl: plan_id, // Plan ID.
          isl: subplan_id // Subplan ID.
        };
        break;
      case 8: // Fall-through.
      case 9:
        __payload = {
          lt: log_type // Log type.
        };
        break;
      default:
        __payload = {
          lt: log_type,
          pl: plazo,
          ipl: plan_id, // Plan ID.
          isl: subplan_id, // Subplan ID.
          ic: null, // Campaign ID.
          co: null, // Campaign origin.
          dcu: cuit, // Dealer's CUIT.
          ccu: null, // Client's CUIT.
          ce: null, // Client's e-mail.
          ip: null, // Publication ID.
          tasa: tasa // Tasa
        };
    }
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', __payload);

    return this.http.post<IDateroLogDoLogResponse | IDateroLogDoLogResponseData>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDateroLogDoLogResponse | IDateroLogDoLogResponseData>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IDateroLogDoLogResponse | IDateroLogDoLogResponseData>): number => {
          if (response.body.hasOwnProperty('data')) {
            return (response.body as IDateroLogDoLogResponse).data.IdDateroTrackingData;
          }
          if (response.body.hasOwnProperty('IdDateroTrackingData')) {
            return (response.body as IDateroLogDoLogResponseData).IdDateroTrackingData;
          }
          return null;
        }),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public doLogRaw(data: any): Observable<number | BaseAppError> {
    const __SUBTAG = 'doLog';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    const __endpointURL = `${this.baseURL}/log`;

    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', data);

    return this.http.post<IDateroLogDoLogResponse | IDateroLogDoLogResponseData>(__endpointURL, data, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDateroLogDoLogResponse | IDateroLogDoLogResponseData>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IDateroLogDoLogResponse | IDateroLogDoLogResponseData>): number => {
          if (response.body.hasOwnProperty('data')) {
            return (response.body as IDateroLogDoLogResponse).data.IdDateroTrackingData;
          }
          if (response.body.hasOwnProperty('IdDateroTrackingData')) {
            return (response.body as IDateroLogDoLogResponseData).IdDateroTrackingData;
          }
          return null;
        }),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }
}
