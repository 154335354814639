import { DateroLogService } from './../../services/datero-log/datero-log.service';
import { Component, Input, OnInit, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Models.
import { InsuranceModel } from '../../models/insurance.model';
import { ISegurosCotizacion } from '../../services/seguros/i-seguros-cotizacion';
// Providers.
import { ScriptLoaderService } from '../../services/script-loader/script-loader.service';
import { SessionService } from '../../services/session/session.service';
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaSegurosModalComponent';
_logger.debug(_TAG, 'loaded.');


declare var TCFASegurosSDK: any;


@Component({
  selector: 'app-tcfa-seguros-modal',
  templateUrl: './tcfa-seguros-modal.component.html',
  styleUrls: ['./tcfa-seguros-modal.component.scss']
})
export class TcfaSegurosModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public message: string;

  private tcfaSegurosSdkPromise: Promise<any>;

  protected modalWidthTierIsFull = false;

  constructor(
    public activeModal: NgbActiveModal,
    private scriptLoaderService: ScriptLoaderService,
    private tcfaContextService: TcfaContextService,
    private renderer: Renderer2,
    private dateroLogService: DateroLogService,
  ) {
  }

  ngAfterViewInit() {
    const __SUBTAG = 'ngAfterViewInit';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    this.tcfaSegurosSdkPromise
      .then(() => {
        const __SUBTAG_SCRIPT_LOADER = 'tcfaSegurosSdkPromise';
        _logger.debug(_TAG, __SUBTAG_SCRIPT_LOADER, 'TCFA Seguros loaded.');

        if (!TCFASegurosSDK) {
          _logger.debug(_TAG, __SUBTAG_SCRIPT_LOADER, 'TCFA Seguros not loaded.');
          this.activeModal.dismiss('fail');
        }

        TCFASegurosSDK.init({
          environment: _config.tcfaSegurosSdkEnv,
          theme: _config.tcfaSegurosSdkTheme,
          container: 'iframe-modal-seguros-container',
          operation: {
            // Required params.
            p_tipo_operacion: this.tcfaContextService.selectedOperation.id,
            p_cuit_dealer: this.tcfaContextService.dealer.cuit,
            p_id_vehiculo_i: this.tcfaContextService.selectedVersion.vehicleId,

            // Optional params.
            // Se omiten las propiedades id_cotizacion, id_publicacion y p_edad, y p_cod_uso, dado que no existen en este contexto.
            p_cod_cond_fiscal: this.tcfaContextService.selectedTaxCondition.id,
            p_es_usado: !this.tcfaContextService.selectedVersion.isNew,
            tipo_vehiculo: this.tcfaContextService.VEHICLE_TYPE,
            p_es_auto: this.tcfaContextService.VEHICLE_TYPE,
            p_es_persona_fisica: (this.tcfaContextService.selectedPersonType.code === 'F'),
            p_anio: (!this.tcfaContextService.selectedVersion.isNew && this.tcfaContextService.selectedVersion.year ||
              (new Date()).getFullYear()),
            pre_cotizacion: (this.tcfaContextService.insurance && this.tcfaContextService.insurance.getInsuranceDateForSdkSeguros()),
            origin_application: 'Cotizador Lexus',
            origin_session_id: SessionService.getSessionId()
          },
          done: (operation_result: ISegurosCotizacion) => {
            const __SUBTAG_SDK_DONE = 'TCFA Seguros SDK done';
            _logger.debug(_TAG, __SUBTAG_SDK_DONE, 'Operation result:', operation_result);

            if (operation_result.success) {
              this.dateroLogService.doLogRaw({
                lt: 7, // Log type.
                sid: JSON.stringify(operation_result),
                App: 'LEXUS'
              }).subscribe();

              const __insurance = new InsuranceModel(operation_result);
              this.tcfaContextService.changeInsuranceData(__insurance);
              this.activeModal.close(__insurance);
            } else {
              this.activeModal.dismiss('fail');
            }
          },
          fail: (error: any) => {
            const __SUBTAG_SDK_FAIL = 'TCFA Seguros SDK fail';
            _logger.debug(_TAG, __SUBTAG_SDK_FAIL, 'Error:', error);
            this.activeModal.dismiss(error);
          },
          update: (status: any) => {
            const __SUBTAG_SDK_UPDATE = 'TCFA Seguros SDK update';
            _logger.debug(_TAG, __SUBTAG_SDK_UPDATE, 'Status:', status);
            this.modalWidthTierIsFull = (status.width === 'full');
          }
        });
      })
      .catch(error => this.activeModal.dismiss(error));
  }

  public ngOnInit(): void {
    this.renderer.addClass(document.body, 'fixed-screen-hack');
    this.tcfaSegurosSdkPromise = this.scriptLoaderService.load('TCFASeguros');
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'fixed-screen-hack');
  }
}
