import { Component, Input, OnInit } from '@angular/core';
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaModalSuccessComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-modal-success',
  templateUrl: './tcfa-modal-success.component.html',
  styleUrls: ['./tcfa-modal-success.component.scss']
})
export class TcfaModalSuccessComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
