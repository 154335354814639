// Vendor.
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewportScroller } from '@angular/common';
// Models.
import { CanalAnonimoModelDataModel } from '../../models/canal-anonimo-model-data.model';
import { TcfaCommonModalService } from '../../services/tcfa-common-modal/tcfa-common-modal.service';
import { BaseAppError } from '../../services/error/base-app-error';
import { PersonTypeModel } from '../../models/person-type.model';
import { CotizacionModel } from '../../models/cotizacion.model';
import { ParamModel } from '../../models/param.model';
import { CanonModel } from '../../models/canon.model';
import { ICotizadorGetVehicleLoanInfoResponse } from '../../services/cotizador/i-cotizador-get-vehicle-loan-info-response';
import { ICotizadorGetAllVehicleImagesResponseImageItem } from '../../services/cotizador/i-cotizador-get-all-vehicle-images-response-image-item'; // tslint:disable-line:max-line-length
import { CanalAnonimoVersionDataModel } from '../../models/canal-anonimo-version-data.model';
import { OperationTypeModel } from '../../models/operation-type.model';
import { TaxConditionModel } from '../../models/tax-condition.model';
// Components.
import { TcfaModalShareComponent } from '../../components/tcfa-modal-share/tcfa-modal-share.component';
import { LoansComponent } from '../../components/loans/loans.component';
// Providers.
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
import { CotizadorService } from '../../services/cotizador/cotizador.service';
import { ParamsService } from '../../services/params/params.service';
import { DateroLogService } from '../../services/datero-log/datero-log.service';
import { ErrorService } from '../../services/error/error.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'CarPageComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-car-page',
  templateUrl: './car-page.component.html',
  styleUrls: ['./car-page.component.scss']
})
export class CarPageComponent implements OnInit, OnDestroy {
  @ViewChild(LoansComponent) private loansComponent: LoansComponent;

  protected isSelCotizadorCollapsed = true;

  protected disableOperationChange = false;
  protected disableVehicleModelChange = false;
  protected disableVersionChange = false;
  protected disablePersonTypeChange = false;
  protected disableCanonChange = false;
  protected disableTaxConditionChange = false;

  protected formCotizarChanged = true;

  private __initializingModal: Promise<NgbModalRef>;

  private __prevLoanAmount: number;

  private shouldReInit = false;

  protected get selectedVehicle(): CanalAnonimoModelDataModel {
    return this.tcfaContextService.selectedVehicle;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cotizadorService: CotizadorService,
    protected tcfaContextService: TcfaContextService,
    private tcfaCommonModalService: TcfaCommonModalService,
    private paramsService: ParamsService,
    private viewportScroller: ViewportScroller,
    private modalService: NgbModal,
    private dateroLogService: DateroLogService,
    private errorService: ErrorService
  ) {
    // Detect URL changes.
    this.router.events.subscribe((event: RouterEvent) => {
      if ((event instanceof NavigationStart) && (event.navigationTrigger === 'popstate')) {
        this.shouldReInit = true;
      } else if ((event instanceof NavigationEnd) && this.shouldReInit) {
        this.shouldReInit = false;
        this.ngOnInit();
      }
    });
  }

  private displayLoadingMessage(): void {
    if (!this.__initializingModal) {
      this.__initializingModal = this.tcfaCommonModalService.showLoadingModal('Cargando información. Por favor espere.');
    }
  }

  private dismissLoadingMessage(): void {
    if (this.__initializingModal) {
      this.__initializingModal.then(modal => {
        modal.dismiss();
        this.__initializingModal = null;
      });
    }
  }

  public ngOnDestroy(): void {
    this.dismissLoadingMessage();
  }

  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    const __vehicleId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (!this.tcfaContextService.vehicles || !this.tcfaContextService.vehicles.models.some(model => model.id === __vehicleId)) {
      this.router.navigate(['/']);
      return;
    }

    if (!this.tcfaContextService.selectedVehicle || (this.tcfaContextService.selectedVehicle.id !== __vehicleId)) {
      this.tcfaContextService.selectedVehicle = this.tcfaContextService.vehicles.models.find(model => model.id === __vehicleId);
      this.tcfaContextService.selectedVersion = this.tcfaContextService.selectedVehicle.versions[0];
    }

    // Selected version from shared link.
    let __versionFromSharedLink: CanalAnonimoVersionDataModel;
    if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.version_id) {
      __versionFromSharedLink = this.tcfaContextService.selectedVehicle.versions.find(
        v => v.id === this.tcfaContextService.sharedLinkData.version_id);
    }

    if (!this.tcfaContextService.selectedVersion || (__versionFromSharedLink &&
      (__versionFromSharedLink.id !== this.tcfaContextService.selectedVersion.id))) {
      if (__versionFromSharedLink) {
        this.tcfaContextService.selectedVersion = __versionFromSharedLink;
      } else {
        this.tcfaContextService.selectedVersion = this.tcfaContextService.selectedVehicle.versions[0];
      }
    }
    _logger.debug(_TAG, __SUBTAG, 'Selected version:', this.tcfaContextService.selectedVersion);

    this.displayLoadingMessage();
    const __promises: Promise<any>[] = [];

    // Selected operation from shared link.
    let __operationFromSharedLink: OperationTypeModel;
    if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.operation_code) {
      __operationFromSharedLink = this.tcfaContextService.operationTypes.find(
        o => o.key === this.tcfaContextService.sharedLinkData.operation_code);
    }

    if (!this.tcfaContextService.selectedOperation || (__operationFromSharedLink &&
      (__operationFromSharedLink.id !== this.tcfaContextService.selectedOperation.id))) {
      if (__operationFromSharedLink) {
        this.tcfaContextService.selectedOperation = __operationFromSharedLink;
      } else {
        this.tcfaContextService.selectedOperation = this.tcfaContextService.operationTypes[0];
      }
    }
    _logger.debug(_TAG, __SUBTAG, 'Selected operation:', this.tcfaContextService.selectedOperation);

    // Selected loan amount from shared link.
    if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.loan_amount) {
      this.tcfaContextService.loanAmount = this.tcfaContextService.sharedLinkData.loan_amount;
    }

    let __personsPromise: Promise<any>;
    const __needLoadPersonTypes = (!this.tcfaContextService.personTypes || (this.tcfaContextService.personTypes.length === 0));
    _logger.debug(_TAG, __SUBTAG, 'Need load person types:', __needLoadPersonTypes);
    if (__needLoadPersonTypes) {
      __personsPromise = new Promise((resolve, reject) => {
        this.cotizadorService.getDatosCalculoCuotas()
          .subscribe((result_or_error: PersonTypeModel[] | BaseAppError) => {
            // Failed.
            if (result_or_error instanceof BaseAppError) {
              reject(result_or_error);
              return;
            }

            // Succeed.
            this.tcfaContextService.personTypes = result_or_error;

            // Selected person from shared link.
            let __personFromSharedLink: PersonTypeModel;
            if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.person_type_id) {
              __personFromSharedLink = this.tcfaContextService.personTypes.find(
                p => p.id === this.tcfaContextService.sharedLinkData.person_type_id);
            }
            if (__personFromSharedLink) {
              this.tcfaContextService.selectedPersonType = __personFromSharedLink;
            } else {
              this.tcfaContextService.selectedPersonType = this.tcfaContextService.personTypes[0];
            }

            // Selected tax condition from shared link.
            let __taxConditionFromSharedLink: TaxConditionModel;
            if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.tax_condition_code) {
              __taxConditionFromSharedLink = this.tcfaContextService.selectedPersonType.taxConditions.find(
                t => t.code === this.tcfaContextService.sharedLinkData.tax_condition_code);
            }
            if (__taxConditionFromSharedLink) {
              this.tcfaContextService.selectedTaxCondition = __taxConditionFromSharedLink;
            } else {
              this.tcfaContextService.selectedTaxCondition = this.tcfaContextService.selectedPersonType.taxConditions.find(
                t => t.isDefault);
            }
            if (!this.tcfaContextService.selectedTaxCondition) {
              this.tcfaContextService.selectedTaxCondition = this.tcfaContextService.selectedPersonType.taxConditions[0];
            }

            this.paramsService.getAllParams()
              .subscribe((result_or_error_params: ParamModel[] | BaseAppError) => {
                // Failed.
                if (result_or_error_params instanceof BaseAppError) {
                  reject(result_or_error_params);
                  return;
                }

                // Succeed.
                this.tcfaContextService.personTypes
                  .forEach(person => {
                    person.canons = [];
                    const __param = result_or_error_params
                      .find(p => p.key.toLowerCase() === `cotizador_canon_ant_${person.loanCode.toLowerCase()}`);
                    if (__param) {
                      (__param.value as string).split(';')
                        .forEach(canon => {
                          person.canons.push(new CanonModel(`${canon}%`, canon));
                        });
                    }
                  });
                this.tcfaContextService.selectedCanon = this.tcfaContextService.selectedPersonType.canons[0];
                resolve(result_or_error);
              }, (error: any) => {
                reject(error);
              });
          }, (error: any) => {
            reject(error);
          });
      });
      __promises.push(__personsPromise);
    } else {
      // Selected person from shared link.
      let __personFromSharedLink: PersonTypeModel;
      if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.person_type_id) {
        __personFromSharedLink = this.tcfaContextService.personTypes.find(
          p => p.id === this.tcfaContextService.sharedLinkData.person_type_id);
      }
      if (__personFromSharedLink) {
        this.tcfaContextService.selectedPersonType = __personFromSharedLink;
      } else {
        this.tcfaContextService.selectedPersonType = this.tcfaContextService.personTypes[0];
      }

      // Selected tax condition from shared link.
      let __taxConditionFromSharedLink: TaxConditionModel;
      if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.tax_condition_code) {
        __taxConditionFromSharedLink = this.tcfaContextService.selectedPersonType.taxConditions.find(
          t => t.code === this.tcfaContextService.sharedLinkData.tax_condition_code);
      }
      if (__taxConditionFromSharedLink) {
        this.tcfaContextService.selectedTaxCondition = __taxConditionFromSharedLink;
      } else {
        this.tcfaContextService.selectedTaxCondition = this.tcfaContextService.selectedPersonType.taxConditions.find(t => t.isDefault);
      }
      if (!this.tcfaContextService.selectedTaxCondition) {
        this.tcfaContextService.selectedTaxCondition = this.tcfaContextService.selectedPersonType.taxConditions[0];
      }
      __personsPromise = Promise.resolve();
    }

    const __selectedVehicle = this.tcfaContextService.selectedVehicle,
      __selectedVersion = this.tcfaContextService.selectedVersion,
      __needLoadImages = !__selectedVehicle.isImageLoaded && !__selectedVehicle.errorImage;
    _logger.debug(_TAG, __SUBTAG, 'Need load vehicle image:', __needLoadImages);
    if (__needLoadImages) {
      __promises.push(new Promise((resolve) => {
        __selectedVehicle.isImageLoading = true;
        this.cotizadorService
          .getAllVehicleImages(this.tcfaContextService.vehicles.id, [__selectedVehicle.id])
          .subscribe((result_or_error: ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError) => {
            if (result_or_error instanceof BaseAppError) {
              // Failed.
              __selectedVehicle.image = null;
              __selectedVehicle.errorImage = true;
              __selectedVehicle.isImageLoading = false;
            } else {
              // Succeed.
              const __image = result_or_error
                .find(image_item => image_item.file_exists &&
                  (parseInt(image_item.model_id, 10) === __selectedVehicle.id));
              if (__image) {
                __selectedVehicle.image = __image.image;
                __selectedVehicle.errorImage = false;
              } else {
                __selectedVehicle.image = null;
                __selectedVehicle.errorImage = true;
              }
              __selectedVehicle.isImageLoading = false;
            }
          }, () => {
            __selectedVehicle.image = null;
            __selectedVehicle.errorImage = true;
            __selectedVehicle.isImageLoading = false;
          }, () => {
            resolve();
          });
      }));
    }

    const __needLoadImageLogos = !__selectedVehicle.isLogoImageLoaded && !__selectedVehicle.errorLogoImage;
    _logger.debug(_TAG, __SUBTAG, 'Need load vehicle image logo:', __needLoadImageLogos);
    if (__needLoadImageLogos) {
      __promises.push(new Promise((resolve) => {
        __selectedVehicle.isLogoImageLoading = true;
        this.cotizadorService
          .getAllVehicleImageLogos(this.tcfaContextService.vehicles.id, [__selectedVehicle.id])
          .subscribe((result_or_error: ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError) => {
            if (result_or_error instanceof BaseAppError) {
              // Failed.
              __selectedVehicle.logoImage = null;
              __selectedVehicle.errorLogoImage = true;
              __selectedVehicle.isLogoImageLoading = false;
            } else {
              // Succeed.
              const __image = result_or_error
                .find(image_item => image_item.file_exists &&
                  (parseInt(image_item.model_id, 10) === __selectedVehicle.id));
              if (__image) {
                __selectedVehicle.logoImage = __image.image;
                __selectedVehicle.errorLogoImage = false;
              } else {
                __selectedVehicle.logoImage = null;
                __selectedVehicle.errorLogoImage = true;
              }
              __selectedVehicle.isLogoImageLoading = false;
            }
          }, () => {
            __selectedVehicle.logoImage = null;
            __selectedVehicle.errorLogoImage = true;
            __selectedVehicle.isLogoImageLoading = false;
          }, () => {
            resolve();
          });
      }));
    }

    const __needLoadPrice = !__selectedVersion.price || !__selectedVersion.maxLoanAmount ||
      !__selectedVersion.vehicleId || !__selectedVersion.vehicleIVA;
    _logger.debug(_TAG, __SUBTAG, 'Need load vehicle price:', __needLoadPrice);
    if (__needLoadPrice) {
      __promises.push(new Promise((resolve, reject) => {
        __personsPromise
          .then(() => {
            this.cotizadorService.getVehicleLoanInfo(__selectedVersion.isNew,
              __selectedVersion.year, this.tcfaContextService.selectedPersonType.code,
              this.tcfaContextService.dealer.cuit, this.tcfaContextService.VEHICLE_TYPE, this.tcfaContextService.vehicles.id,
              __selectedVehicle.id, __selectedVersion.id)
              .subscribe((result_or_error: ICotizadorGetVehicleLoanInfoResponse | BaseAppError) => {
                // Failed.
                if (result_or_error instanceof BaseAppError) {
                  reject(result_or_error);
                  return;
                }

                // Succeed.
                __selectedVersion.price = Math.round(result_or_error.data.vehiculo_precio);
                __selectedVersion.maxLoanAmount = Math.floor(result_or_error.data.max_financiacion);
                __selectedVersion.vehicleId = result_or_error.data.vehiculo_id;
                __selectedVersion.vehicleIVA = result_or_error.data.vehiculo_iva;
                __selectedVersion.loanAmount = result_or_error.data.financiacion_sugerida;
                this.tcfaContextService.loanAmount = result_or_error.data.financiacion_sugerida;
                resolve();
              }, (error: any) => {
                reject(error);
              });
          })
          .catch(() => {
            reject();
          });
      }));
    }

    const __needLoadMinLoanAmount = !this.tcfaContextService.selectedVersion.minLoanAmount;
    _logger.debug(_TAG, __SUBTAG, 'Need load vehicle min loan amount:', __needLoadMinLoanAmount);
    if (__needLoadMinLoanAmount) {
      __promises.push(new Promise((resolve, reject) => {
        this.paramsService.getAllParams()
          .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
            // Failed.
            if (result_or_error instanceof BaseAppError) {
              reject(result_or_error);
              return;
            }

            // Succeed.
            const __param = result_or_error.find(p => p.key.toLowerCase() === 'minimo_valor_financiable');
            if (__param) {
              this.tcfaContextService.selectedVersion.minLoanAmount = parseFloat(__param.value as string);
              resolve(result_or_error);
            } else {
              reject();
            }
          }, (error: any) => {
            reject(error);
          });
      }));
    }

    Promise.all(__promises)
      .then(() => {
        // Selected canon from shared link.
        let __canonFromSharedLink: CanonModel;
        if (this.tcfaContextService.sharedLinkData && this.tcfaContextService.sharedLinkData.canon_code) {
          __canonFromSharedLink = this.tcfaContextService.selectedPersonType.canons.find(
            c => c.key === this.tcfaContextService.sharedLinkData.canon_code);
        }
        if (__canonFromSharedLink) {
          this.tcfaContextService.selectedCanon = __canonFromSharedLink;
        }

        this.onChangeLoanAmount();

        // Parse shared link.
        if (this.tcfaContextService.sharedLinkData && (this.tcfaContextService.sharedLinkData.link_id >= 2)) {
          this.onCotizar();
        }
      })
      .catch((error: any) => {
        _logger.error(_TAG, __SUBTAG, 'Promises failed. Error:', error);
        this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
      })
      .finally(() => {
        this.dismissLoadingMessage();
      });
  }

  private onChangeForm(): void {
    const __SUBTAG = 'onChangeForm';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');
    this.formCotizarChanged = true;
    this.tcfaContextService.cotizacion = null;
  }

  protected onChangeOperationType(): void {
    this.onChangeForm();
  }

  protected onChangeVehicleModel(): void {
    const __SUBTAG = 'onChangeVehicleModel';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    // Update URL.
    this.router.navigate([`/car/${this.tcfaContextService.selectedVehicle.id}`]);

    this.displayLoadingMessage();
    const __promises: Promise<any>[] = [];

    if (!this.tcfaContextService.selectedVehicle.isImageLoaded && !this.tcfaContextService.selectedVehicle.errorImage) {
      __promises.push(new Promise((resolve) => {
        this.tcfaContextService.selectedVehicle.isImageLoading = true;
        this.cotizadorService
          .getAllVehicleImages(this.tcfaContextService.vehicles.id, [this.tcfaContextService.selectedVehicle.id])
          .subscribe((result_or_error: ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError) => {
            if (result_or_error instanceof BaseAppError) {
              // Failed.
              this.tcfaContextService.selectedVehicle.image = null;
              this.tcfaContextService.selectedVehicle.errorImage = true;
              this.tcfaContextService.selectedVehicle.isImageLoading = false;
            } else {
              // Succeed.
              const __image = result_or_error
                .find(image_item => image_item.file_exists &&
                  (parseInt(image_item.model_id, 10) === this.tcfaContextService.selectedVehicle.id));
              if (__image) {
                this.tcfaContextService.selectedVehicle.image = __image.image;
                this.tcfaContextService.selectedVehicle.errorImage = false;
              } else {
                this.tcfaContextService.selectedVehicle.image = null;
                this.tcfaContextService.selectedVehicle.errorImage = true;
              }
              this.tcfaContextService.selectedVehicle.isImageLoading = false;
            }
          }, () => {
            this.tcfaContextService.selectedVehicle.image = null;
            this.tcfaContextService.selectedVehicle.errorImage = true;
            this.tcfaContextService.selectedVehicle.isImageLoading = false;
          }, () => {
            resolve();
          });
      }));
    }

    if (!this.tcfaContextService.selectedVehicle.isLogoImageLoaded && !this.tcfaContextService.selectedVehicle.errorLogoImage) {
      __promises.push(new Promise((resolve) => {
        this.tcfaContextService.selectedVehicle.isLogoImageLoading = true;
        this.cotizadorService
          .getAllVehicleImageLogos(this.tcfaContextService.vehicles.id, [this.tcfaContextService.selectedVehicle.id])
          .subscribe((result_or_error: ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError) => {
            if (result_or_error instanceof BaseAppError) {
              // Failed.
              this.tcfaContextService.selectedVehicle.logoImage = null;
              this.tcfaContextService.selectedVehicle.errorLogoImage = true;
              this.tcfaContextService.selectedVehicle.isLogoImageLoading = false;
            } else {
              // Succeed.
              const __image = result_or_error
                .find(image_item => image_item.file_exists &&
                  (parseInt(image_item.model_id, 10) === this.tcfaContextService.selectedVehicle.id));
              if (__image) {
                this.tcfaContextService.selectedVehicle.logoImage = __image.image;
                this.tcfaContextService.selectedVehicle.errorLogoImage = false;
              } else {
                this.tcfaContextService.selectedVehicle.logoImage = null;
                this.tcfaContextService.selectedVehicle.errorLogoImage = true;
              }
              this.tcfaContextService.selectedVehicle.isLogoImageLoading = false;
            }
          }, () => {
            this.tcfaContextService.selectedVehicle.logoImage = null;
            this.tcfaContextService.selectedVehicle.errorLogoImage = true;
            this.tcfaContextService.selectedVehicle.isLogoImageLoading = false;
          }, () => {
            resolve();
          });
      }));
    }

    Promise.all(__promises)
      .then(() => {
        this.onChangeForm();
        this.tcfaContextService.selectedVersion = this.tcfaContextService.selectedVehicle.versions[0];
        this.onChangeVehicleVersion();
        this.onChangeLoanAmount();
      })
      .catch((error: any) => {
        _logger.error(_TAG, __SUBTAG, 'Promises failed. Error:', error);
        this.dismissLoadingMessage();
        this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
      });
  }

  protected onChangeVehicleVersion(): void {
    const __SUBTAG = 'onChangeVehicleVersion';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    this.displayLoadingMessage();
    const __promises: Promise<any>[] = [];

    if (!this.tcfaContextService.selectedVersion.price || !this.tcfaContextService.selectedVersion.maxLoanAmount ||
      !this.tcfaContextService.selectedVersion.vehicleId || !this.tcfaContextService.selectedVersion.vehicleIVA) {
      __promises.push(new Promise((resolve, reject) => {
        this.cotizadorService.getVehicleLoanInfo(this.tcfaContextService.selectedVersion.isNew,
          this.tcfaContextService.selectedVersion.year, this.tcfaContextService.selectedPersonType.code,
          this.tcfaContextService.dealer.cuit, this.tcfaContextService.VEHICLE_TYPE, this.tcfaContextService.vehicles.id,
          this.tcfaContextService.selectedVehicle.id, this.tcfaContextService.selectedVersion.id)
          .subscribe((result_or_error: ICotizadorGetVehicleLoanInfoResponse | BaseAppError) => {
            // Failed.
            if (result_or_error instanceof BaseAppError) {
              reject(result_or_error);
              return;
            }

            // Succeed.
            this.tcfaContextService.selectedVersion.price = Math.round(result_or_error.data.vehiculo_precio);
            this.tcfaContextService.selectedVersion.maxLoanAmount = Math.floor(result_or_error.data.max_financiacion);
            this.tcfaContextService.selectedVersion.vehicleId = result_or_error.data.vehiculo_id;
            this.tcfaContextService.selectedVersion.vehicleIVA = result_or_error.data.vehiculo_iva;
            this.tcfaContextService.selectedVersion.loanAmount = result_or_error.data.financiacion_sugerida;

            resolve();
          }, (error: any) => {
            reject(error);
          });
      }));
    }

    if (!this.tcfaContextService.selectedVersion.minLoanAmount) {
      __promises.push(new Promise((resolve, reject) => {
        this.paramsService.getAllParams()
          .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
            // Failed.
            if (result_or_error instanceof BaseAppError) {
              reject(result_or_error);
              return;
            }

            // Succeed.
            const __param = result_or_error.find(p => p.key.toLowerCase() === 'minimo_valor_financiable');
            if (__param) {
              this.tcfaContextService.selectedVersion.minLoanAmount = parseFloat(__param.value as string);
              resolve(result_or_error);
            } else {
              reject();
            }
          }, (error: any) => {
            reject(error);
          });
      }));
    }

    // Datero Log event 7: "Selección Vehículo".
    this.dateroLogService
      .doLog(7, null, this.tcfaContextService.dealer.cuit, null, null, null,
        this.tcfaContextService.vehicles.id, this.tcfaContextService.selectedVehicle.id, this.tcfaContextService.selectedVersion.id, 1)
      .subscribe();

    let __retryVersion = false;
    Promise.all(__promises)
      .then(() => {
        this.tcfaContextService.loanAmount = this.tcfaContextService.selectedVersion.loanAmount;
        this.onChangeForm();
        this.onChangeLoanAmount();
        this.tcfaContextService.checkAndResetInsuranceData();
      })
      .catch((error: any) => {
        _logger.error(_TAG, __SUBTAG, 'Promises failed. Error:', error);
        const __appError: BaseAppError = this.errorService.getAppError(error);
        _logger.error(_TAG, __SUBTAG, 'Error:', __appError.getMessage());

        if (!error || (__appError.getCode() !== ErrorService.getApiHttpErrorCode(404))) {
          this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
        } else {
          // Retry with next version on error.
          const __length = this.tcfaContextService.selectedVehicle.versions.length,
            __searchId = this.tcfaContextService.selectedVersion.id,
            __versionIndex = this.tcfaContextService.selectedVehicle.versions.findIndex(v => v.id === __searchId);
          if (__versionIndex + 1 < __length) {
            _logger.debug(_TAG, __SUBTAG, 'Retrying with next version available, index: ' + (__versionIndex + 1) + ', length: ' + __length);
            this.tcfaContextService.selectedVersion = this.tcfaContextService.selectedVehicle.versions[__versionIndex + 1];
            __retryVersion = true;
          } else {
            _logger.debug(_TAG, __SUBTAG, 'No more retries are available. Redirecting to anonymous channel.');
            this.router.navigate(['/']);
          }
        }
      })
      .finally(() => {
        if (__retryVersion) {
          this.onChangeVehicleVersion();
        } else {
          this.dismissLoadingMessage();
        }
      });
  }

  protected onChangePersonType(): void {
    this.onChangeForm();
    this.tcfaContextService.selectedCanon = this.tcfaContextService.selectedPersonType.canons[0];
    this.onChangeCanon();
    this.tcfaContextService.selectedTaxCondition = this.tcfaContextService.selectedPersonType.taxConditions[0];
    this.onChangeTaxCondition();
  }

  protected onChangeCanon(): void {
    this.onChangeForm();
  }

  protected onChangeTaxCondition(): void {
    this.onChangeForm();
  }

  protected onChangeLoanAmount(show_modal: boolean = false): void {
    const __SUBTAG = 'onChangeLoanAmount';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked. Show modal:', show_modal, '; Loan amount:', this.tcfaContextService.loanAmount);

    const __test = parseInt((this.tcfaContextService.loanAmount || 0).toString(), 10);
    if (__test !== this.tcfaContextService.loanAmount) {
      this.tcfaContextService.loanAmount = __test;
    }
    if (!this.tcfaContextService.loanAmount) {
      this.tcfaContextService.loanAmount = 0;
    }

    if (this.tcfaContextService.selectedVersion) {
      if (this.tcfaContextService.loanAmount > this.tcfaContextService.selectedVersion.maxLoanAmount) {
        if (show_modal) {
          this.tcfaCommonModalService.showAlertModal('El valor excede el máximo permitido.');
        }
        this.tcfaContextService.loanAmount = this.tcfaContextService.selectedVersion.maxLoanAmount;
      }
      if (this.tcfaContextService.loanAmount < this.tcfaContextService.selectedVersion.minLoanAmount) {
        if (show_modal) {
          this.tcfaCommonModalService.showAlertModal('El valor es menor al mínimo permitido.');
        }
        this.tcfaContextService.loanAmount = this.tcfaContextService.selectedVersion.minLoanAmount;
      }
    }
    if (this.__prevLoanAmount !== this.tcfaContextService.loanAmount) {
      this.__prevLoanAmount = this.tcfaContextService.loanAmount;
      this.onChangeForm();
    }
  }

  protected onShare(): void {
    const __modal = this.modalService.open(TcfaModalShareComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: `modal-tcfa-share modal-animate`,
      keyboard: true,
      backdrop: true,
      centered: true,
      size: 'lg'
    });
    __modal.componentInstance.shareUrl = this.cotizadorService.getUrlToSocialShare({
      link_type: 1,
      operation_type: this.tcfaContextService.selectedOperation.key,
      vehicle_brand: this.tcfaContextService.vehicles.id,
      vehicle_brand_name: this.tcfaContextService.vehicles.name,
      vehicle_model: this.tcfaContextService.selectedVehicle.id,
      vehicle_model_name: this.tcfaContextService.selectedVehicle.name,
      vehicle_version: this.tcfaContextService.selectedVersion.id,
      vehicle_version_name: this.tcfaContextService.selectedVersion.name,
      vehicle_condition: ((this.tcfaContextService.selectedVersion.isNew && 1) || 0),
      vehicle_year: this.tcfaContextService.selectedVersion.year,
      vehicle_type: this.tcfaContextService.VEHICLE_TYPE,
      vehicle_id: this.tcfaContextService.selectedVersion.vehicleId,
      vehicle_price: this.tcfaContextService.vehiclePriceByOperation,
      person_type: this.tcfaContextService.selectedPersonType.id,
      loan_amount: this.tcfaContextService.loanAmount,
      tax_condition: this.tcfaContextService.selectedTaxCondition.code,
      canon: (this.tcfaContextService.selectedCanon && this.tcfaContextService.selectedCanon.key),
      plan: null // Plan from campaign.
    });
  }

  // Changes from LoansComponent.
  protected onLoanAmountChange(info: [number, { [key: string]: boolean }]): void {
    this.tcfaContextService.loanAmount = info[0];
    this.onChangeLoanAmount();
    this.onCotizar(false, info[1]);
  }

  protected onCotizar(quiet: boolean = false, pre_selected_plazos: { [key: string]: boolean } = {}): void {
    const __SUBTAG = 'onCotizar';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    const __loadingModal = this.tcfaCommonModalService.showLoadingModal('Cotizando. Por favor espere.');
    this.formCotizarChanged = false;
    this.cotizadorService.doCotizar(
      this.tcfaContextService.dealer.cuit,
      this.tcfaContextService.selectedOperation.key,
      this.tcfaContextService.vehicles.id, this.tcfaContextService.selectedVehicle.id, this.tcfaContextService.selectedVersion.id,
      this.tcfaContextService.selectedPersonType.code,
      this.tcfaContextService.isRetailOperation ? this.tcfaContextService.loanAmount : null,
      !this.tcfaContextService.isRetailOperation ? this.tcfaContextService.selectedCanon.key : null,
      this.tcfaContextService.selectedTaxCondition.id, this.tcfaContextService.selectedVersion.isNew,
      this.tcfaContextService.selectedVersion.vehicleId, this.tcfaContextService.selectedVersion.price, true,
      this.tcfaContextService.selectedVersion.year, this.tcfaContextService.VEHICLE_TYPE
    )
      .subscribe((result_or_error: CotizacionModel | BaseAppError) => {
        // Failed.
        if (result_or_error instanceof BaseAppError) {
          _logger.debug(_TAG, __SUBTAG, 'doCotizar failed.');
          this.onChangeForm();
          this.tcfaCommonModalService.showErrorModal('No se pudo completar la operación.');
          return;
        }

        // Succeed.
        _logger.debug(_TAG, __SUBTAG, 'doCotizar success.');
        this.tcfaContextService.cotizacion = result_or_error;
        _logger.debug(_TAG, __SUBTAG, 'Cotizacion:', this.tcfaContextService.cotizacion);

        if (this.tcfaContextService.cotizacion.plazos && (this.tcfaContextService.cotizacion.plazos.length > 0)) {
          _logger.debug(_TAG, __SUBTAG, 'doCotizar pre plazos:', this.tcfaContextService.cotizacion.plazos);
          this.tcfaContextService.cotizacion.plazos.forEach((plazo_item) => {
            plazo_item.isSelected = plazo_item.isSelected || !!pre_selected_plazos[plazo_item.installments.toString()] ||
              // Parse shared link data.
              (this.tcfaContextService.sharedLinkData && (this.tcfaContextService.sharedLinkData.link_id >= 2) &&
                (this.tcfaContextService.sharedLinkData.plazo === plazo_item.installments));

            if (plazo_item.isSelected) {
              this.tcfaContextService.getPlanesLoanInfo(plazo_item);
            }
          });
          _logger.debug(_TAG, __SUBTAG, 'doCotizar post plazos:', this.tcfaContextService.cotizacion.plazos);

          // Scroll to seccion plazos.
          setTimeout(() => {
            _logger.debug(_TAG, __SUBTAG, 'scrolling...');
            this.viewportScroller.scrollToAnchor('quinto-detalle');

            // Auto open plan (shared link).
            if (this.tcfaContextService.sharedLinkData && (this.tcfaContextService.sharedLinkData.link_id >= 2)) {
              this.loansComponent.autoSelectPlanFromSharedLink();
            }
          }, 500);
        } else {
          _logger.debug(_TAG, __SUBTAG, 'doCotizar naving back.');
          this.onChangeForm();
          this.router.navigate(['/']);
        }
      }, (error: any) => {
        _logger.error(_TAG, __SUBTAG, 'cotizadorService.doCotizar Error:', error);
        this.onChangeForm();
        this.tcfaCommonModalService.showErrorModal('No se pudo completar la operación.');
      }, () => {
        __loadingModal.then(modal => modal.dismiss());
      });
  }
}
