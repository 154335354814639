import { ICotizadorGetCanalAnonimoResponseVersionItem } from '../services/cotizador/i-cotizador-get-canal-anonimo-response-version-item';


export class CanalAnonimoVersionDataModel {
  public name: string;
  public id: number;
  public vehicleId: number;

  public price: number;
  public maxLoanAmount: number;
  public minLoanAmount: number;
  public vehicleIVA: number;
  public loanAmount: number;

  public isNew = true;
  public year = (new Date().getFullYear());

  constructor(data: ICotizadorGetCanalAnonimoResponseVersionItem) {
    this.name = data.descripcion;
    this.id = data.id;
  }
}
