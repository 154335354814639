import { ITcfaEnvironmentConfiguration } from '../app/shared/environment-manager.shared';


export const environment: ITcfaEnvironmentConfiguration = {
  apiBaseURL: 'http://qa2.api.tcfautos.codeicus/index.php',
  pediTuPrestamoURL: 'http://190.216.29.83/ToyotaAppForm/Login/MultiCanal',

  tcfaSegurosSdkURL: 'http://qa.widgetseguros.tcfautos.codeicus/sdk/sdk.js',
  tcfaSegurosSdkEnv: 'QA',

  facebookAppId: '1779429392292463',
  facebookAppSecret: '295e950403db38d00ddf58e730efdfb6',

  reCaptchaApiKey: '6LdQ3UwUAAAAAF4B1gmft8m-YmYrjW6BVDGBZoEE'
};
