import { ICotizadorDoCotizarResponsePlanItem } from '../services/cotizador/i-cotizador-do-cotizar-response-plan-item';
import { PlazoModel } from './plazo.model';
import { PlanModel } from './plan.model';


export class CotizacionModel {
  public plazos: PlazoModel[];

  constructor(data_cotizacion: ICotizadorDoCotizarResponsePlanItem[], loan_amount: number) {
    const __plazos: { [key: string]: { disable_plazo?: boolean, promo?: boolean, planes?: PlanModel[] } } = {};

    // Extract plazos from planes/subplanes.
    data_cotizacion.forEach((plan) => {
      if (plan.p_subplanes_o) {
        plan.p_subplanes_o.forEach((subplan) => {
          const __plazo_id = subplan.p_sup_plazo_n.toString();
          __plazos[__plazo_id] = (__plazos[__plazo_id] || {});
          __plazos[__plazo_id].disable_plazo = (__plazos[__plazo_id].disable_plazo || subplan.disable_plazo);
          __plazos[__plazo_id].promo = (__plazos[__plazo_id].promo || (plan.p_es_plan_subvencionado_i === 1));
          __plazos[__plazo_id].planes = (__plazos[__plazo_id].planes || []);
          __plazos[__plazo_id].planes.push(new PlanModel(plan, subplan, parseInt(__plazo_id, 10), loan_amount));
        });
      }
    });

    // Parse & sort plazos.
    this.plazos = [];
    Object
      .keys(__plazos)
      .forEach((key) => {
        this.plazos.push(new PlazoModel(key, __plazos[key].promo, false, false,
          __plazos[key].disable_plazo, __plazos[key].planes));
      });
    this.plazos.sort((a, b) => {
      return (a.installments - b.installments);
    });
  }
}
