import { Component } from '@angular/core';
import { SimpleLogger } from './shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'AppComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
}
