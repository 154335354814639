/**
 * Base Class to manage app errors.
 */
export class BaseAppError {
  constructor(
    private code: string,
    private message: string
  ) {
    this.setCode(code);
    this.setMessage(message);
  }

  public getCode(): string {
    return this.code;
  }
  private setCode(code: string): void {
    this.code = code;
  }

  public getMessage(): string {
    return this.message;
  }
  private setMessage(message: string): void {
    this.message = message;
  }
}
