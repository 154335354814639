import { ICotizadorGetCanalAnonimoResponseModeloItem } from '../services/cotizador/i-cotizador-get-canal-anonimo-response-modelo-item';
import { CanalAnonimoVersionDataModel } from './canal-anonimo-version-data.model';


export class CanalAnonimoModelDataModel {
  public name: string;
  public id: number;
  public versions: CanalAnonimoVersionDataModel[];

  public image: string;
  public errorImage: boolean;
  public isImageLoading: boolean;
  public isImageLoaded: boolean;

  public logoImage: string;
  public errorLogoImage: boolean;
  public isLogoImageLoading: boolean;
  public isLogoImageLoaded: boolean;

  constructor(data: ICotizadorGetCanalAnonimoResponseModeloItem) {
    this.name = data.descripcion;
    this.id = data.id;
    this.versions = data.version.map(v => new CanalAnonimoVersionDataModel(v));
  }

  public setImageLoaded(): void {
    this.isImageLoaded = true;
  }

  public setLogoImageLoaded(): void {
    this.isLogoImageLoaded = true;
  }
}
