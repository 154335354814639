import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Models.
import { BaseAppError } from '../../services/error/base-app-error';
// Providers.
import { ErrorService } from '../../services/error/error.service';
import { TcfaCommonModalService } from '../../services/tcfa-common-modal/tcfa-common-modal.service';
import { CotizadorService } from '../../services/cotizador/cotizador.service';
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
import { DateroLogService } from '../../services/datero-log/datero-log.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { Utils } from '../../shared/utils.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaModalShareWithFriendsComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-modal-share-with-friends',
  templateUrl: './tcfa-modal-share-with-friends.component.html',
  styleUrls: ['./tcfa-modal-share-with-friends.component.scss']
})
export class TcfaModalShareWithFriendsComponent implements OnInit {
  @Input() shareUrl: string;
  @Input() dateroExtraData: { plazo?: number, plan_id?: number, subplan_id?: number };

  protected reCaptchaApiKey = _config.reCaptchaApiKey;
  protected reCaptchaSize = (Utils.isMobile('any') && 'compact' || 'normal');
  protected captchaResolved = false;

  protected message: any = {
    name: '',
    mail: '',
    friendName: '',
    friendMail: '',
    text: ''
  };
  protected messageMaxLength = 400;
  protected messageLeftLength: number = this.messageMaxLength;
  protected disableSubmit = false;

  constructor(
    public activeModal: NgbActiveModal,
    private errorService: ErrorService,
    private tcfaCommonModalService: TcfaCommonModalService,
    private cotizadorService: CotizadorService,
    private tcfaContextService: TcfaContextService,
    private dateroLogService: DateroLogService
  ) {
  }

  ngOnInit() {
  }

  protected onMessageTextChange(): void {
    this.messageLeftLength = this.messageMaxLength - ((this.message.text && this.message.text.length) || 0);
  }

  protected onMessageSubmit(): void {
    let __error_message = '';
    if ((this.message.name.trim() === '') || (this.message.mail.trim() === '') || (this.message.text.trim() === '') ||
      (this.message.friendMail.trim() === '') || (this.message.friendName.trim() === '')) {
      __error_message = 'Todos los campos son obligatorios.';
    }

    const __email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line:max-line-length
    if (!__email_regex.test(this.message.mail.trim().toLowerCase()) || !__email_regex.test(this.message.friendMail.trim().toLowerCase())) {
      __error_message = 'Debe ingresar un e-mail válido.';
    }
    if (this.message.text.trim().length > this.messageMaxLength) {
      __error_message = `El mensaje no puede exceder los ${this.messageMaxLength} caracteres.`;
    }
    if (__error_message !== '') {
      this.tcfaCommonModalService.showErrorModal(__error_message);
      return;
    }

    this.disableSubmit = true;
    const __loading_modal = this.tcfaCommonModalService.showLoadingModal('Enviando mensaje. Por favor espere.');
    this.cotizadorService.shareWithFriends(this.message.name.trim(), this.message.mail.trim(), this.message.friendName.trim(),
      this.message.friendMail.trim(), this.message.text.trim(), this.tcfaContextService.vehicles.name, this.tcfaContextService.vehicles.id,
      this.tcfaContextService.selectedVehicle.name, this.tcfaContextService.selectedVehicle.id,
      this.tcfaContextService.selectedVersion.name, this.tcfaContextService.selectedVersion.year,
      this.tcfaContextService.selectedVersion.isNew, this.tcfaContextService.vehiclePriceByOperation, this.shareUrl)
      .subscribe((result_or_error: boolean | BaseAppError) => {
        // Failed.
        if (result_or_error instanceof BaseAppError) {
          this.onMessageSubmitFail(result_or_error);
          return;
        }

        // Succeed.
        if (result_or_error) {
          // Datero Log event 11: "Compartir".
          if (!this.dateroExtraData) {
            this.dateroExtraData = {};
          }
          this.dateroLogService
            .doLog(11, this.dateroExtraData.plazo, this.tcfaContextService.dealer.cuit, this.dateroExtraData.plan_id,
              this.dateroExtraData.subplan_id, null, this.tcfaContextService.vehicles.id, this.tcfaContextService.selectedVehicle.id,
              this.tcfaContextService.selectedVersion.id, 1, this.message.mail.trim())
            .subscribe();

          this.tcfaCommonModalService
            .showSuccessModal('El mensaje se envió con éxito.', '')
            .then(modal => modal.result
              .finally(() => {
                this.message.text = '';
                this.onMessageTextChange();
                this.activeModal.close();
              }));
        } else {
          this.onMessageSubmitFail(null);
        }
      }, (error: any) => {
        this.onMessageSubmitFail(error);
      }, () => {
        __loading_modal.then(modal => modal.close());
        this.disableSubmit = false;
        this.captchaResolved = false;
      });
  }

  private onMessageSubmitFail(error: any): void {
    const __SUBTAG = 'onMessageSubmitFail',
      __appError: BaseAppError = this.errorService.getAppError(error);
    _logger.error(_TAG, __SUBTAG, 'Error:', __appError.getMessage());

    let __message: string;
    if (!error || (__appError.getCode() !== ErrorService.getApiHttpErrorCode(400))) {
      if (error instanceof String) {
        __message = error.toString();
      } else if (typeof error === 'string') {
        __message = error;
      } else {
        __message = 'Ha ocurrido un error al procesar la solicitud, por favor intente nuevamente en unos instantes.';
      }
    } else {
      __message = __appError.getMessage();
    }
    this.tcfaCommonModalService.showErrorModal(__message);
  }

  protected onCaptchaResolved(): void {
    this.captchaResolved = true;
  }
}
