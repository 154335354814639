import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaModalAlertComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-modal-alert',
  templateUrl: './tcfa-modal-alert.component.html',
  styleUrls: ['./tcfa-modal-alert.component.scss']
})
export class TcfaModalAlertComponent implements OnInit {
  @Input() message: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }
}
