import { IParamsGetAllParamsResponseParamItem } from '../services/params/i-params-get-all-params-response-param-item';


export class ParamModel {
  public key: string;
  public value: any;

  constructor(data: IParamsGetAllParamsResponseParamItem) {
    this.key = data.clave;
    this.value = data.valor;
  }

  public getValueAsInt(): number {
    return parseInt(this.value, 10);
  }
}
