import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import Swiper from 'swiper';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewportScroller } from '@angular/common';
// Models.
import { BaseAppError } from '../../services/error/base-app-error';
import { CanalAnonimoModel } from '../../models/canal-anonimo.model';
import { ICotizadorGetAllVehicleImagesResponseImageItem } from '../../services/cotizador/i-cotizador-get-all-vehicle-images-response-image-item'; // tslint:disable-line:max-line-length
import { CanalAnonimoModelDataModel } from '../../models/canal-anonimo-model-data.model';
// Providers.
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
import { CotizadorService } from '../../services/cotizador/cotizador.service';
import { ErrorService } from '../../services/error/error.service';
import { TcfaCommonModalService } from '../../services/tcfa-common-modal/tcfa-common-modal.service';
import { SessionService } from '../../services/session/session.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'HomePageComponent';
_logger.debug(_TAG, 'loaded.');


/**
 * Home page. Shows vehicles carrusel.
 */
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(SwiperDirective) protected directiveRef?: SwiperDirective;
  protected swiperConfig = {
    initialSlide: 2,
    slidesPerView: 3,
    centeredSlides: true,
    breakpoints: {
      '767': {
        slidesPerView: 1,
        initialSlide: 2
      }
    },
    observer: true,
    autoHeight: true
  };
  protected swiperSlideIndex = 0;
  private swiperToInitialSlide = true;
  protected swiperDummySlideFirst = null;
  protected swiperDummySlideLast = null;

  private swiperInstance: Swiper;

  private loadingModalFirstImages: NgbModalRef;

  constructor(
    private route: ActivatedRoute,
    private tcfaCommonModalService: TcfaCommonModalService,
    private errorService: ErrorService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService,
    private router: Router,
    private tcfaContextService: TcfaContextService,
    private viewportScroller: ViewportScroller
  ) {
  }

  // Sorting models: following line performs a custom sort of vehicles. Remove this line when ABM for such purpose is created.
  private static sortCanalAnonimo(a: CanalAnonimoModelDataModel, b: CanalAnonimoModelDataModel): number {
    const __predefined = ['ETIOS', 'COROLLA', 'HILUX'],
      aPos = __predefined.indexOf(a.name),
      bPos = __predefined.indexOf(b.name);
    if ((aPos === -1) && (bPos === -1)) {
      return a.name.localeCompare(b.name);
    } else if ((aPos !== -1) && (bPos === -1)) {
      return -1;
    } else if ((aPos === -1) && (bPos !== -1)) {
      return 1;
    } else if (aPos < bPos) {
      return -1;
    } else {
      return 1;
    }
  }

  private parseLinkData(): boolean {
    const __SUBTAG = 'parseLinkData';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    const __idShareLink = parseInt(this.route.snapshot.queryParamMap.get('il'), 10) || 0,
      __idVehicleModel = parseInt(this.route.snapshot.queryParamMap.get('vm'), 10);
    _logger.debug(_TAG, __SUBTAG, 'Shared Link:', __idShareLink, '; Model ID:', __idVehicleModel);

    this.tcfaContextService.sharedLinkData = null;
    if ((__idShareLink >= 1) && __idVehicleModel) {
      const __selectedModel = this.tcfaContextService.vehicles.models.find(m => m.id === __idVehicleModel);
      if (__selectedModel) {
        this.tcfaContextService.sharedLinkData = {
          link_id: __idShareLink,
          version_id: parseInt(this.route.snapshot.queryParamMap.get('vv'), 10),
          loan_amount: parseInt(this.route.snapshot.queryParamMap.get('la'), 10),
          person_type_id: parseInt(this.route.snapshot.queryParamMap.get('pt'), 10),
          tax_condition_code: this.route.snapshot.queryParamMap.get('tc'),
          operation_code: this.route.snapshot.queryParamMap.get('io'),
          canon_code: this.route.snapshot.queryParamMap.get('ca'),
          plazo: parseInt(this.route.snapshot.queryParamMap.get('pl'), 10) || 0,
          subplan_id: parseInt(this.route.snapshot.queryParamMap.get('is'), 10) || 0,
          insurance_id: this.route.snapshot.queryParamMap.get('ii'),
          insurance_company_id: parseInt(this.route.snapshot.queryParamMap.get('ici'), 10) || 0,
          insurance_company_name: this.route.snapshot.queryParamMap.get('icn'),
          insurance_pack_id: this.route.snapshot.queryParamMap.get('ipi'),
          insurance_pack_name: this.route.snapshot.queryParamMap.get('ipn'),
          insurance_pack_zone: this.route.snapshot.queryParamMap.get('izn'),
          insurance_zone_cp: this.route.snapshot.queryParamMap.get('izcp'),
          insurance_prov_id: this.route.snapshot.queryParamMap.get('izpi'),
          insurance_prov_name: this.route.snapshot.queryParamMap.get('izpn'),
          insurance_installment: parseInt(this.route.snapshot.queryParamMap.get('iic'), 10) || 0,
          insurance_detail: atob(this.route.snapshot.queryParamMap.get('idet') || '')
        };
      }
      this.router.navigate(['/home'], {preserveQueryParams: false})
        .finally(() => {
          if (__selectedModel) {
            this.onSelectSlide(__selectedModel, null);
          }
        });
      return !__selectedModel;
    }
    return true;
  }

  private onAnonymousChannelResolve(): void {
    if (this.parseLinkData()) {
      this.onUpdatedVehicleModels();
      this.viewportScroller.scrollToAnchor('app-main-header');
    }
  }

  public ngAfterViewInit(): void {
    this.swiperUpdate();
  }

  public ngOnDestroy(): void {
    if (this.loadingModalFirstImages) {
      this.loadingModalFirstImages.dismiss();
      this.loadingModalFirstImages = null;
    }
  }

  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    this.onChangeVehicleModel(null);

    const __initializingModal = this.tcfaCommonModalService.showLoadingModal('Cargando información. Por favor espere.');
    this.sessionService.getOrCreateSession()
      .subscribe((session_result_or_error: boolean | BaseAppError) => {
        _logger.debug(_TAG, __SUBTAG, 'getOrCreateSession returned.');

        // Failed.
        if (session_result_or_error instanceof BaseAppError) {
          _logger.debug(_TAG, __SUBTAG, 'Session error:', session_result_or_error);
          __initializingModal.then(modal => modal.dismiss());
          this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
          return;
        }

        // Succeed.
        if (session_result_or_error) {
          _logger.debug(_TAG, __SUBTAG, 'Session success.');
          if (this.tcfaContextService.anonymousChannel) {
            this.onAnonymousChannelResolve();
            __initializingModal.then(modal => modal.dismiss());
          } else {
            this.cotizadorService.getCanalAnonimo()
              .subscribe((result_or_error: CanalAnonimoModel | BaseAppError) => {
                // Failed.
                if (result_or_error instanceof BaseAppError) {
                  __initializingModal.then(modal => modal.dismiss());
                  this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
                  return;
                }

                // Succeed.
                this.tcfaContextService.anonymousChannel = result_or_error;
                this.tcfaContextService.anonymousChannel.carsData.models.sort(HomePageComponent.sortCanalAnonimo);
                this.onAnonymousChannelResolve();
              }, () => {
                this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
              }, () => {
                __initializingModal.then(modal => modal.dismiss());
              });
          }
        } else { // sessionService.getOrCreateSession() Fail
          _logger.debug(_TAG, __SUBTAG, 'Session fail.');
          __initializingModal.then(modal => modal.dismiss());
          this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
        }
      }, () => { // sessionService.getOrCreateSession() Fail
        __initializingModal.then(modal => modal.dismiss());
        this.tcfaCommonModalService.showErrorModal('Esta opción ya no se encuentra disponible.');
      });
  }

  private getVehicleImages(): void {
    if (this.tcfaContextService.vehicles) {
      const __modelsToCheck = this.tcfaContextService.vehicles.models.filter((model) => !model.image);
      if (__modelsToCheck.length > 0) {
        this.cotizadorService
          .getAllVehicleImages(this.tcfaContextService.vehicles.id, __modelsToCheck.map(model => {
            model.isImageLoading = true;
            return model.id;
          }))
          .subscribe((result_or_error: ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError) => {
            if (result_or_error instanceof BaseAppError) {
              // Failed.
              __modelsToCheck.forEach(model => {
                model.image = null;
                model.errorImage = true;
                model.isImageLoading = false;
                this.checkFirstImagesLoaded();
              });
            } else {
              // Succeed.
              __modelsToCheck.forEach(model => {
                const __image = result_or_error
                  .find(image_item => image_item.file_exists && (parseInt(image_item.model_id, 10) === model.id));
                if (__image) {
                  model.image = __image.image;
                  model.errorImage = false;
                } else {
                  model.image = null;
                  model.errorImage = true;
                  this.checkFirstImagesLoaded();
                }
                model.isImageLoading = false;
              });
            }
          }, () => {
            __modelsToCheck.forEach(model => {
              model.image = null;
              model.errorImage = true;
              model.isImageLoading = false;
              this.checkFirstImagesLoaded();
            });
          }, () => {
            this.swiperUpdate();
            this.getVehicleImageLogos();
          });
      } else {
        this.swiperUpdate();
        this.getVehicleImageLogos();
      }
    }
  }

  private getVehicleImageLogos(): void {
    if (this.tcfaContextService.vehicles) {
      const __modelsToCheck = this.tcfaContextService.vehicles.models.filter((model) => model.errorImage && !model.logoImage);
      if (__modelsToCheck.length > 0) {
        this.cotizadorService
          .getAllVehicleImageLogos(this.tcfaContextService.vehicles.id, __modelsToCheck.map(model => {
            model.isLogoImageLoading = true;
            return model.id;
          }))
          .subscribe((result_or_error: ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError) => {
            if (result_or_error instanceof BaseAppError) {
              // Failed.
              __modelsToCheck.forEach(model => {
                model.logoImage = null;
                model.errorLogoImage = true;
                model.isLogoImageLoading = false;
                this.checkFirstImagesLoaded();
              });
            } else {
              // Succeed.
              __modelsToCheck.forEach(model => {
                const __image = result_or_error
                  .find(image_item => image_item.file_exists && (parseInt(image_item.model_id, 10) === model.id));
                if (__image) {
                  model.logoImage = __image.image;
                  model.errorLogoImage = false;
                } else {
                  model.logoImage = null;
                  model.errorLogoImage = true;
                  this.checkFirstImagesLoaded();
                }
                model.isLogoImageLoading = false;
              });
            }
          }, () => {
            __modelsToCheck.forEach(model => {
              model.logoImage = null;
              model.errorLogoImage = true;
              model.isLogoImageLoading = false;
              this.checkFirstImagesLoaded();
            });
          }, () => {
            this.swiperUpdate();
          });
      } else {
        this.swiperUpdate();
      }
    }
  }

  /**
   * Returns the configured initial slide for the current respnonsive breakpoint.
   */
  private getSwiperConfiguredInitialSlide(): number {
    if (this.swiperInstance && this.swiperInstance['currentBreakpoint'] && (this.swiperInstance['currentBreakpoint'] === 'max')) {
      return this.swiperConfig.initialSlide;
    } else if (this.swiperConfig.breakpoints['767']) {
      return this.swiperConfig.breakpoints['767'].initialSlide;
    }
    return 0;
  }

  private onUpdateSwiperSlidesArray(): void {
    if (this.swiperInstance) {
      if (!this.swiperInstance.params) {
        this.onSwiperInit();
        this.swiperInstance.params = (this.swiperInstance.params || this.swiperConfig);
      }
      this.swiperInstance.params.initialSlide = this.swiperConfig.initialSlide;
      this.swiperInstance.params.breakpoints['767'].initialSlide = this.swiperConfig.breakpoints['767'].initialSlide;
      setTimeout(() => {
        this.swiperUpdate();
      }, 100);
    }
  }

  private swiperUpdate(): void {
    if (this.swiperInstance) {
      if (this.swiperToInitialSlide && (this.swiperInstance.activeIndex !== this.getSwiperConfiguredInitialSlide()) &&
        this.swiperInstance.slides && (this.swiperInstance.slides.length > this.getSwiperConfiguredInitialSlide())) {
        this.swiperToInitialSlide = false;
        this.swiperSlideToInitial();
      }
      this.swiperInstance.update();
    }
  }

  /** Slides swiper to initial slide. */
  private swiperSlideToInitial(): void {
    const __SUBTAG = 'swiperSlideToInitial';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    if (this.swiperInstance) {
      _logger.debug(_TAG, __SUBTAG, 'Swiper sliding to:', this.getSwiperConfiguredInitialSlide());
      this.swiperInstance.slideTo(this.getSwiperConfiguredInitialSlide());
    }
  }

  protected onSwiperInit(): void {
    const __SUBTAG = 'onSwiperInit';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');
    this.swiperInstance = this.directiveRef.swiper();
  }

  protected onSwiperTransitionEnd(): void {
    let __slide_to = -1,
      __speed: number;
    if ((this.swiperInstance.activeIndex === 0) && (this.swiperInstance.previousIndex === 2)) {
      __slide_to = 1;
    } else {
      if (this.swiperInstance.isBeginning) {
        __slide_to = this.swiperInstance.slides.length - 2;
        __speed = 150;
      }
      if (this.swiperInstance.isEnd) {
        __slide_to = 1;
        __speed = 150;
      }
    }
    if (__slide_to !== -1) {
      this.swiperInstance.slideTo(__slide_to, __speed);
      this.swiperInstance.update();
    }
  }

  private onChangeVehicleModel(vehicle_model: CanalAnonimoModelDataModel): void {
    this.tcfaContextService.selectedVehicle = vehicle_model;
    this.tcfaContextService.selectedVersion = null;
  }

  protected onSelectSlide(vehicle_model: CanalAnonimoModelDataModel, slide_id: number): void {
    const __SUBTAG = 'onSelectSlide';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked. Slide ID:', slide_id);

    this.onChangeVehicleModel(vehicle_model);
    this.tcfaContextService.carruselSelectedSlide = slide_id;
    if (this.tcfaContextService.selectedVehicle) {
      this.router.navigate([`/car/${this.tcfaContextService.selectedVehicle.id}`]);
    }
  }

  protected onSwiperPrev(): void {
    let __index: number,
      __speed: number;
    if (this.swiperInstance) {
      __index = this.swiperInstance.activeIndex - 1;
      if (__index === 0) {
        __index = this.swiperInstance.slides.length - 2;
        __speed = 200;
      }
      this.swiperInstance.slideTo(__index, __speed);
    }
  }

  protected onSwiperNext(): void {
    let __index: number,
      __speed: number;
    if (this.swiperInstance) {
      __index = this.swiperInstance.activeIndex + 1;
      if (__index === this.swiperInstance.slides.length - 1) {
        __index = 1;
        __speed = 200;
      }
      this.swiperInstance.slideTo(__index, __speed);
    }
  }

  /** Vehicle models array update handler. */
  protected onUpdatedVehicleModels(): void {
    const __SUBTAG = 'onUpdatedVehicleModels';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    if (this.tcfaContextService.vehicles.models.length > 1) {
      this.swiperDummySlideFirst = this.tcfaContextService.vehicles.models[this.tcfaContextService.vehicles.models.length - 1];
      this.swiperDummySlideLast = this.tcfaContextService.vehicles.models[0];
      this.swiperConfig.initialSlide = 2;
      this.swiperConfig.breakpoints['767'].initialSlide = 2;
    } else {
      this.swiperDummySlideFirst = null;
      this.swiperDummySlideLast = null;
      this.swiperConfig.initialSlide = 0;
      this.swiperConfig.breakpoints['767'].initialSlide = 0;
    }

    this.onUpdateSwiperSlidesArray();
    this.getVehicleImages();
    this.loadFirstImages();
  }

  private get firstImagesAreLoaded(): boolean {
    return this.tcfaContextService.vehicles.models
      .slice(0, 3)
      .every((model) => model.isImageLoaded ||
        (model.errorImage && (model.isLogoImageLoaded || model.errorLogoImage)));
  }

  private loadFirstImages(): void {
    if (!this.firstImagesAreLoaded) {
      console.log(this.tcfaContextService.vehicles.models
        .slice(0, 3));
      this.tcfaCommonModalService.showLoadingModal('Cargando imágenes. Por favor espere.')
        .then(modal => {
          this.loadingModalFirstImages = modal;
        });
      this.checkFirstImagesLoaded();
    }
  }

  private checkFirstImagesLoaded(): void {
    if (this.firstImagesAreLoaded && this.loadingModalFirstImages) {
      this.loadingModalFirstImages.dismiss();
      this.loadingModalFirstImages = null;
    }
  }

  protected onLoadVehicleImage(vehicle_model: CanalAnonimoModelDataModel): void {
    vehicle_model.setImageLoaded();
    this.checkFirstImagesLoaded();
  }

  protected onLoadVehicleLogoImage(vehicle_model: CanalAnonimoModelDataModel): void {
    vehicle_model.setLogoImageLoaded();
    this.checkFirstImagesLoaded();
  }
}
