import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CurrencyPipe } from '../../pipes/currency/currency.pipe';


@Directive({
  selector: '[appCurrencyFormatter]'
})
export class CurrencyFormatterDirective implements OnInit, OnChanges {
  @Input() public input: any;

  private el: HTMLInputElement;

  private focused = false;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.input && !this.focused) {
      setTimeout(() => {
        this.onBlur(this.input);
      }, 100);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.focused = true;
    this.el.value = this.currencyPipe.parse(value); // opossite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.focused = false;
    this.el.value = this.currencyPipe.transform(value, 0);
  }

  @HostListener('change', ['$event'])
  onChange(event: string | Event) {
    if (event instanceof Event) {
      setTimeout(() => {
        this.onBlur((event.target as HTMLInputElement).value);
      }, 100);
    }
  }
}
