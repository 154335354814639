import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
// Models.
import { DealerModel } from '../../models/dealer.model';
import { CanalAnonimoModel } from '../../models/canal-anonimo.model';
import { CanalAnonimoModelDataModel } from '../../models/canal-anonimo-model-data.model';
import { OperationTypeModel } from '../../models/operation-type.model';
import { CanalAnonimoBrandDataModel } from '../../models/canal-anonimo-brand-data.model';
import { PersonTypeModel } from '../../models/person-type.model';
import { TaxConditionModel } from '../../models/tax-condition.model';
import { CanalAnonimoVersionDataModel } from '../../models/canal-anonimo-version-data.model';
import { CanonModel } from '../../models/canon.model';
import { CotizacionModel } from '../../models/cotizacion.model';
import { PlazoModel } from '../../models/plazo.model';
import { BaseAppError } from '../error/base-app-error';
import { ICotizadorDoCuadroDeMarchaResponseCuadroItem } from '../cotizador/i-cotizador-do-cuadro-de-marcha-response-cuadro-item';
import { InstallmentModel } from '../../models/installment.model';
import { InsuranceModel } from '../../models/insurance.model';
import { PlanModel } from '../../models/plan.model';
import { ParamModel } from '../../models/param.model';
import { ICotizadorGetAllSectionsResponseSectionItem } from '../cotizador/i-cotizador-get-all-sections-response-section-item';
import { ICotizadorGetAllSectionsResponseTerminoCondicion } from '../cotizador/i-cotizador-get-all-sections-response-termino-condicion';
import { ICotizadorGetAllTooltipsResponseTooltipItem } from '../cotizador/i-cotizador-get-all-tooltips-response-tooltip-item';
import { IContextShareLinkData } from './i-context-share-link-data';
// Providers.
import { CotizadorService } from '../cotizador/cotizador.service';
import { ParamsService } from '../params/params.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig();

moment.locale(_config.i18nLocaleShort);

const _TOOLTIP_ERROR_TEXT = 'No se pudo obtener la información solicitada. Por favor intente nuevamente más tarde.' +
  ' Disculpe las molestias ocasionadas.',
  _TOOLTIP_LOADING_TEXT = 'Cargando información...';


@Injectable({
  providedIn: 'root'
})
export class TcfaContextService {
  public anonymousChannel: CanalAnonimoModel;
  public selectedVehicle: CanalAnonimoModelDataModel;
  public selectedVersion: CanalAnonimoVersionDataModel;
  private previousSelectedVersion: CanalAnonimoVersionDataModel;

  public carruselSelectedSlide: number;

  public readonly VEHICLE_TYPE = 1;

  public dealer = new DealerModel(_config.dealerDefaultCUIT, true);

  public operationTypes: OperationTypeModel[] = [
    {
      id: 2,
      key: 'RE',
      name: 'PRENDARIO',
      extra: {
        label: 'MONTO A FINANCIAR',
        tooltip: _TOOLTIP_LOADING_TEXT
      }
    },
    {
      id: 1,
      key: 'LE',
      name: 'LEASING',
      extra: {
        label: 'CANON ANTICIPADO',
        tooltip: _TOOLTIP_LOADING_TEXT
      }
    }
  ];
  public selectedOperation: OperationTypeModel;

  public get isRetailOperation(): boolean {
    return (this.selectedOperation.key === 'RE');
  }

  public selectedCanon: CanonModel;

  public personTypes: PersonTypeModel[];
  public selectedPersonType: PersonTypeModel;
  public selectedTaxCondition: TaxConditionModel;

  public sharedLinkData: IContextShareLinkData;

  public get taxConditions(): TaxConditionModel[] {
    if (!this.selectedPersonType) {
      return [];
    }
    return this.selectedPersonType.taxConditions;
  }

  public get vehicles(): CanalAnonimoBrandDataModel {
    if (!this.anonymousChannel) {
      return null;
    }
    return this.anonymousChannel.carsData;
  }

  public tooltipCanon = _TOOLTIP_LOADING_TEXT;
  public tooltipCanonAnticipado = _TOOLTIP_LOADING_TEXT;
  public tooltipCuotaFinal = _TOOLTIP_LOADING_TEXT;
  public tooltipCuotaFinalUva = _TOOLTIP_LOADING_TEXT;
  public tooltipCanonFinal = _TOOLTIP_LOADING_TEXT;

  public loanAmount = 0;

  public cotizacion: CotizacionModel;

  public insurance: InsuranceModel;

  public get loanInsuranceInstallment(): number {
    if (!this.insurance) {
      return null;
    }

    return this.insurance.installments || 0;
  }

  public get vehiclePriceByOperation(): number {
    if (!this.selectedVersion.price) {
      return null;
    }

    // RETAIL / PRENDARIO --> Vehicle Price.
    if (this.isRetailOperation) {
      return this.selectedVersion.price;
    }
    // LEASING --> Vehicle Price without IVA (uses Vehicle IVA to perform calculations).
    return this.selectedVersion.price / ((this.selectedVersion.vehicleIVA + 100) / 100);
  }

  public get showDetailPlazo(): boolean {
    if (!this.cotizacion || !this.cotizacion.plazos || (this.cotizacion.plazos.length === 0)) {
      return false;
    }

    return this.cotizacion.plazos.some(p => p.isSelected);
  }

  private legalSectionCommon: ICotizadorGetAllSectionsResponseTerminoCondicion;
  private legalSectionPromo: ICotizadorGetAllSectionsResponseTerminoCondicion;
  private legalSectionUva: ICotizadorGetAllSectionsResponseTerminoCondicion;
  private legalSectionCommonError = false;
  private legalSectionPromoError = false;
  private legalSectionUvaError = false;

  constructor(
    private cotizadorService: CotizadorService,
    private paramsService: ParamsService
  ) {
    this.selectedOperation = this.operationTypes[0];

    const getTooltipsFail = () => {
      this.operationTypes.forEach(o => {
        o.extra.tooltip = _TOOLTIP_ERROR_TEXT;
      });
      this.tooltipCanon = _TOOLTIP_ERROR_TEXT;

      this.tooltipCanonAnticipado = _TOOLTIP_ERROR_TEXT;
      this.tooltipCuotaFinal = _TOOLTIP_ERROR_TEXT;
      this.tooltipCuotaFinalUva = _TOOLTIP_ERROR_TEXT;
      this.tooltipCanonFinal = _TOOLTIP_ERROR_TEXT;
    };

    // Get tooltips.
    this.cotizadorService.getAllTooltips()
      .subscribe((result_or_error: ICotizadorGetAllTooltipsResponseTooltipItem[] | BaseAppError) => {
        // Failed.
        if (result_or_error instanceof BaseAppError) {
          getTooltipsFail();
          return;
        }

        // Succeed.
        result_or_error.forEach(t => {
          switch (t.code) {
            case 'OPERACION_LEASING':
              this.operationTypes.some(o => {
                if (o.key === 'LE') {
                  o.extra.tooltip = t.text;
                  return true;
                }
              });
              break;
            case 'OPERACION_PRENDARIO':
              this.operationTypes.some(o => {
                if (o.key === 'RE') {
                  o.extra.tooltip = t.text;
                  return true;
                }
              });
              break;
            case 'CANON_ANTICIPADO':
              this.tooltipCanon = t.text;
              this.tooltipCanonAnticipado = t.text;
              break;
            case 'CANON_FINAL':
              this.tooltipCanonFinal = t.text;
              break;
            case 'CUOTA_FINAL':
              this.tooltipCuotaFinal = t.text;
              break;
            case 'CUOTA_FINAL_UVA':
              this.tooltipCuotaFinalUva = t.text;
              break;
          }
        });
      }, () => {
        getTooltipsFail();
      });
  }

  public getPlanesLoanInfo(plazo: PlazoModel): void {
    if (plazo.isLoanInfoComplete || plazo.loanInfoPromise) {
      return;
    }

    const __planes: { id: number, flag_TDF: boolean, subplanes: number[] }[] = [],
      __planes_subplanes_ids: { [key: string]: { flag_TDF: boolean, subplanes: number[] } } = {};

    plazo.planes.forEach(plan => {
      const __key = plan.planId.toString();
      __planes_subplanes_ids[__key] = (__planes_subplanes_ids[__key] || {
        flag_TDF: plan.isTDF,
        subplanes: []
      });
      __planes_subplanes_ids[__key].subplanes.push(plan.subplanId);
    });

    Object
      .keys(__planes_subplanes_ids)
      .forEach(key => {
        __planes.push({
          id: parseInt(key, 10),
          subplanes: __planes_subplanes_ids[key].subplanes,
          flag_TDF: __planes_subplanes_ids[key].flag_TDF
        });
      });

    plazo.isLoanInfoLoading = true;
    plazo.loanInfoPromise = new Promise<ICotizadorDoCuadroDeMarchaResponseCuadroItem[]>((resolve, reject) => {
      this.cotizadorService.doCuadroDeMarcha(this.selectedVersion.vehicleId, this.dealer.cuit, __planes, this.selectedPersonType.code,
        this.selectedVersion.isNew, this.selectedOperation.key, this.selectedVersion.price, this.loanAmount, this.selectedTaxCondition.id,
        this.selectedVersion.vehicleIVA)
        .subscribe((result_or_error: ICotizadorDoCuadroDeMarchaResponseCuadroItem[] | BaseAppError) => {
          // Failed.
          if (result_or_error instanceof BaseAppError) {
            reject(result_or_error);
            return;
          }

          // Succeed.
          resolve(result_or_error);
        }, (error: any) => {
          plazo.loanInfoError = true;
          reject(error);
        }, () => {
          plazo.isLoanInfoLoading = false;
        });
    });

    // Calculate installments.
    plazo.planes.forEach(plan => {
      plan.disableInstallments = true;
      plan.installments = [];
      plazo.loanInfoPromise.then((loan_data: ICotizadorDoCuadroDeMarchaResponseCuadroItem[]) => {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === plan.subplanId);
        if (__installments_data.length === 1) {
          plan.ultimaCuotaConSeguro = parseFloat(__installments_data[0].ultima_cuota_con_seguro);
          for (let i = 0; i < plan.installmentsCount; i = i + 1) {
            plan.installments.push(new InstallmentModel(
              i + 1,
              // Cuota Pura = Capital + Interés.
              (parseFloat(__installments_data[0].capital[i] || '0') + parseFloat(__installments_data[0].interes[i] || '0')),
              // Canon = Capital + Interés.
              (parseFloat(__installments_data[0].capital[i] || '0') + parseFloat(__installments_data[0].interes[i] || '0')),
              // Costo Mensual = Gasto.
              parseFloat(__installments_data[0].gasto || '0'),
              // IVA = IVA Interés + Gasto IVA.
              (parseFloat(__installments_data[0].iva_interes[i] || '0') + parseFloat(__installments_data[0].gasto_iva || '0'))
            ));
          }

          // Si el plan es de tipo UVA, solamente me quedo con la primera installment
          if (plan.isUva) {
            plan.installments = plan.installments.slice(0, 1);
          }

          plan.disableInstallments = false;
        } else {
          plan.errorInstallments = true;
        }
      }, () => {
        plan.errorInstallments = true;
      });
    });
  }

  private recalculatePlazos(): void {
    if (this.cotizacion && this.cotizacion.plazos) {
      this.cotizacion.plazos.forEach(plazo => {
        plazo.planes.forEach(plan => {
          plan.recalculateCuotaPromedio(this.isRetailOperation, this.loanAmount, this.loanInsuranceInstallment);
          plan.recalculatePrimeraCuota(this.isRetailOperation, this.loanInsuranceInstallment);
          plan.recalculateUltimaCuota(this.isRetailOperation, this.loanInsuranceInstallment);
          plan.recalculateCanonPromedioMensual(this.isRetailOperation, this.loanInsuranceInstallment);
          plan.recalculateCuotaPuraEnUvas(this.isRetailOperation);
        });
      });
    }
  }

  public changeInsuranceData(insurance: InsuranceModel): void {
    this.previousSelectedVersion = this.selectedVersion;
    this.insurance = insurance;
    this.recalculatePlazos();
  }

  public checkAndResetInsuranceData(): void {
    if (!this.previousSelectedVersion || (this.previousSelectedVersion.id !== this.selectedVersion.id)) {
      this.insurance = null;
      this.recalculatePlazos();
    }
  }

  private getLegalSection(sectionId: number): Promise<ICotizadorGetAllSectionsResponseSectionItem> {
    return new Promise<ICotizadorGetAllSectionsResponseSectionItem>((resolve, reject) => {
      this.cotizadorService.getAllSections()
        .subscribe((result_or_error: ICotizadorGetAllSectionsResponseSectionItem[] | BaseAppError) => {
          // Failed.
          if (result_or_error instanceof BaseAppError) {
            reject(result_or_error);
            return;
          }

          // Succeed.
          const __section = result_or_error.find(s => s.id === sectionId);
          if (__section) {
            resolve(__section);
          } else {
            reject();
          }
        }, (error: any) => {
          reject(error);
        });
    });
  }

  private getLegalSectionCommon(): Promise<any> {
    if (this.legalSectionCommon && !this.legalSectionCommonError) {
      return Promise.resolve(this.legalSectionCommon);
    }

    return new Promise<any>((resolve, reject) => {
      this.paramsService.getAllParams()
        .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
          // Failed.
          if (result_or_error instanceof BaseAppError) {
            this.legalSectionCommonError = true;
            reject(result_or_error);
            return;
          }

          // Succeed.
          const __param = result_or_error.find(p => p.key.toLowerCase() === 'seccion_terminos_cotizador');
          if (__param) {
            this.getLegalSection(parseInt(__param.value, 10))
              .then((section) => {
                if (section.termino_condicion && section.termino_condicion.content) {
                  this.legalSectionCommon = section.termino_condicion;
                  this.legalSectionCommonError = false;
                  resolve();
                } else {
                  this.legalSectionCommonError = true;
                  reject();
                }
              })
              .catch((reason) => {
                this.legalSectionCommonError = true;
                reject(reason);
              });
          } else {
            this.legalSectionCommonError = true;
            reject();
          }
        }, (error: any) => {
          this.legalSectionCommonError = true;
          reject(error);
        });
    });
  }

  private getLegalSectionPromo(): Promise<any> {
    if (this.legalSectionPromo && !this.legalSectionPromoError) {
      return Promise.resolve(this.legalSectionPromo);
    }

    return new Promise<any>((resolve, reject) => {
      this.paramsService.getAllParams()
        .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
          // Failed.
          if (result_or_error instanceof BaseAppError) {
            this.legalSectionPromoError = true;
            reject(result_or_error);
            return;
          }

          // Succeed.
          const __param = result_or_error.find(p => p.key.toLowerCase() === 'seccion_terminos_cotizador_promo');
          if (__param) {
            this.getLegalSection(parseInt(__param.value, 10))
              .then((section) => {
                if (section.termino_condicion && section.termino_condicion.content) {
                  this.legalSectionPromo = section.termino_condicion;
                  this.legalSectionPromoError = false;
                  resolve();
                } else {
                  this.legalSectionPromoError = true;
                  reject();
                }
              })
              .catch((reason) => {
                this.legalSectionPromoError = true;
                reject(reason);
              });
          } else {
            this.legalSectionPromoError = true;
            reject();
          }
        }, (error: any) => {
          this.legalSectionPromoError = true;
          reject(error);
        });
    });
  }

  private getLegalSectionUva(): Promise<any> {
    if (this.legalSectionUva && !this.legalSectionUvaError) {
      return Promise.resolve(this.legalSectionUva);
    }


    return new Promise<any>((resolve, reject) => {
      this.paramsService.getAllParams()
        .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
          // Failed.
          if (result_or_error instanceof BaseAppError) {
            this.legalSectionUvaError = true;
            reject(result_or_error);
            return;
          }

          // Succeed.
          const __param = result_or_error.find(p => p.key.toLowerCase() === 'seccion_terminos_cotizador_uva');
          if (__param) {
            this.getLegalSection(parseInt(__param.value, 10))
              .then((section) => {
                if (section.termino_condicion && section.termino_condicion.content) {
                  this.legalSectionUva = section.termino_condicion;
                  this.legalSectionUvaError = false;
                  resolve();
                } else {
                  this.legalSectionUvaError = true;
                  reject();
                }
              })
              .catch((reason) => {
                this.legalSectionUvaError = true;
                reject(reason);
              });
          } else {
            this.legalSectionUvaError = true;
            reject();
          }
        }, (error: any) => {
          this.legalSectionUvaError = true;
          reject(error);
        });
    });
  }

  private replaceDynamicVarsOnPlanLegalText(plan: PlanModel): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      plan.plazo.loanInfoPromise
        .then((loan_data) => {
          const __installments_data = loan_data.filter(l => l.p_sup_id_i === plan.subplanId);
          this.paramsService.getAllParams()
            .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
              // Failed.
              if (result_or_error instanceof BaseAppError) {
                plan.errorLegalText = true;
                return;
              }

              // Succeed.
              const __param_hora = result_or_error.find(p => p.key.toLowerCase() === 'cotizador_hora_limite_liquidacion');

              let __costo_mensual_param_key = 'cotizador_costo_mensual_';
              if (this.isRetailOperation) {
                __costo_mensual_param_key = __costo_mensual_param_key + 're';
              } else {
                __costo_mensual_param_key = __costo_mensual_param_key + 'le';
              }
              const __costo_mensual_param = result_or_error.find(p => p.key.toLowerCase() === __costo_mensual_param_key);

              if (__param_hora && __costo_mensual_param && (__installments_data.length === 1)) {
                const __fecha_inicio = moment(__installments_data[0].p_fecha_vig_desde, 'YYYY-MM-DDTHH:mm:ss'),
                  __fecha_fin = moment(__installments_data[0].p_fecha_vig_hasta, 'YYYY-MM-DDTHH:mm:ss');

                plan.legalTexts = plan.legalTexts
                  .replace('%TEXTO_GRANDE_INICIO%', '<span class="texto-legal-grande">')
                  .replace('%TEXTO_GRANDE_FIN%', '</span>')
                  .replace('%TNA%', __installments_data[0].tna.toFixed(2))
                  .replace('%TEA%', parseFloat(__installments_data[0].tea).toFixed(2))
                  .replace('%CFT%', parseFloat(__installments_data[0].cft).toFixed(2))
                  .replace('%CFTEF%', parseFloat(__installments_data[0].cft_efectivo).toFixed(2))
                  .replace('%COSTO_MENSUAL_LEASING_PRENDARIO%', parseFloat(__costo_mensual_param.value).toFixed(2))
                  .replace('%PROMO_MODELO%', (((this.selectedVehicle.name) || '') + ' ' + ((this.selectedVersion.name) || '')))
                  .replace('%PROMO_MONTO_MAXIMO_FINANCIAR%', plan.promoAmount.toFixed(2))
                  .replace('%PROMO_FECHA_INICIO%', (__fecha_inicio.isValid() && __fecha_inicio.format('DD/MM/YYYY')) || 'ERROR')
                  .replace('%FROMO_FECHA_FIN%', (__fecha_fin.isValid() && __fecha_fin.format('DD/MM/YYYY')) || 'ERROR')
                  .replace('%PROMO_FECHA_LIMITE_LIQUIDACION%', (__fecha_fin.isValid() &&
                    __fecha_fin.add(30, 'days').format('DD/MM/YYYY')) || 'ERROR')
                  .replace('%PROMO_HORA_LIMITE_LIQUIDACION%', __param_hora.value);
                resolve(plan.legalTexts);
              } else {
                plan.errorLegalText = true;
                reject();
              }
            }, () => {
              plan.errorLegalText = true;
              reject();
            });
        }, () => {
          plan.errorLegalText = true;
          reject();
        });
    });
  }

  public getLegalTextsForPlan(plan: PlanModel): void {
    if (!plan || plan.legalTexts || plan.isLoadingLegalTexts) {
      return;
    }

    plan.isLoadingLegalTexts = true;
    plan.errorLegalText = false;

    plan.legalTextsPromise = new Promise<string>((resolve, reject) => {
      if (plan.isPromo) {
        Promise.all<any>([
          this.getLegalSectionCommon(),
          this.getLegalSectionPromo(),
          plan.plazo.loanInfoPromise
        ])
          .then(() => {
            if (!this.legalSectionPromoError && !this.legalSectionCommonError) {
              plan.legalTexts = this.legalSectionCommon.content + this.legalSectionPromo.content;
              this.replaceDynamicVarsOnPlanLegalText(plan)
                .then(() => {
                  plan.errorLegalText = false;
                  resolve();
                })
                .catch(() => {
                  plan.errorLegalText = true;
                  reject();
                });
            } else {
              plan.errorLegalText = true;
              reject();
            }
          })
          .catch(() => {
            plan.errorLegalText = true;
            reject();
          })
          .finally(() => {
            plan.isLoadingLegalTexts = false;
          });
      } else if (plan.isUva) {
        Promise.all<any>([
          this.getLegalSectionCommon(),
          this.getLegalSectionUva(),
          plan.plazo.loanInfoPromise
        ])
          .then(() => {
            if (!this.legalSectionUvaError && !this.legalSectionCommonError) {
              plan.legalTexts = this.legalSectionCommon.content + this.legalSectionUva.content;
              this.replaceDynamicVarsOnPlanLegalText(plan)
                .then(() => {
                  plan.errorLegalText = false;
                  resolve();
                })
                .catch(() => {
                  plan.errorLegalText = true;
                  reject();
                });
            } else {
              plan.errorLegalText = true;
              reject();
            }
          })
          .catch(() => {
            plan.errorLegalText = true;
            reject();
          })
          .finally(() => {
            plan.isLoadingLegalTexts = false;
          });
      } else {
        Promise.all<any>([
          this.getLegalSectionCommon(),
          plan.plazo.loanInfoPromise
        ])
          .then(() => {
            if (!this.legalSectionCommonError) {
              plan.legalTexts = this.legalSectionCommon.content;
              this.replaceDynamicVarsOnPlanLegalText(plan)
                .then(() => {
                  plan.errorLegalText = false;
                  resolve();
                })
                .catch(() => {
                  plan.errorLegalText = true;
                  reject();
                });
            } else {
              plan.errorLegalText = true;
              reject();
            }
          })
          .catch(() => {
            plan.errorLegalText = true;
            reject();
          })
          .finally(() => {
            plan.isLoadingLegalTexts = false;
          });
      }
    });
  }
}
