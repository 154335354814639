import { Component, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// Models.
import { BaseAppError } from '../../services/error/base-app-error';
// Providers.
import { TcfaCommonModalService } from '../../services/tcfa-common-modal/tcfa-common-modal.service';
import { ContactService } from '../../services/contact/contact.service';
import { ErrorService } from '../../services/error/error.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { Utils } from '../../shared/utils.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaContactComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-contact',
  templateUrl: './tcfa-contact.component.html',
  styleUrls: ['./tcfa-contact.component.scss']
})
export class TcfaContactComponent implements OnInit {
  @ViewChild(TemplateRef) private contactModalTemplate: TemplateRef<any>; // FIXME: cambiar tipo...
  private contactModal: NgbModalRef;

  protected reCaptchaApiKey = _config.reCaptchaApiKey;
  protected reCaptchaSize = (Utils.isMobile('any') && 'compact' || 'normal');
  protected captchaResolved = false;

  protected message: any = {
    name: '',
    mail: '',
    text: ''
  };
  protected messageMaxLength = 400;
  protected messageLeftLength: number = this.messageMaxLength;
  protected disableSubmit = false;

  constructor(
    private modalService: NgbModal,
    private tcfaCommonModalService: TcfaCommonModalService,
    private contactService: ContactService,
    private errorService: ErrorService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
  }

  protected onNewMessage(): void {
    this.contactModal = this.modalService.open(this.contactModalTemplate, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-contact modal-animate',
      size: 'lg'
    });
    this.renderer.addClass(document.body, 'fixed-screen-hack');
    this.contactModal.result.finally(() => {
      this.renderer.removeClass(document.body, 'fixed-screen-hack');
    });
  }

  protected onMessageTextChange(): void {
    this.messageLeftLength = this.messageMaxLength - ((this.message.text && this.message.text.length) || 0);
  }

  protected onMessageSubmit(): void {
    let __error_message = '';
    if ((this.message.name.trim() === '') || (this.message.mail.trim() === '') || (this.message.text.trim() === '')) {
      __error_message = 'Todos los campos son obligatorios.';
    }

    const __email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line:max-line-length
    if (!__email_regex.test(this.message.mail.trim().toLowerCase())) {
      __error_message = 'Debe ingresar un e-mail válido.';
    }
    if (this.message.text.trim().length > this.messageMaxLength) {
      __error_message = `El mensaje no puede exceder los ${this.messageMaxLength} caracteres.`;
    }
    if (__error_message !== '') {
      this.tcfaCommonModalService.showErrorModal(__error_message);
      return;
    }

    this.disableSubmit = true;
    const __loading_modal = this.tcfaCommonModalService.showLoadingModal('Enviando consulta. Por favor espere.');
    this.contactService.newMessage({
      name: this.message.name.trim(),
      message: this.message.text.trim(),
      email: this.message.mail.trim()
    })
      .subscribe((result_or_error: boolean | BaseAppError) => {
        // Failed.
        if (result_or_error instanceof BaseAppError) {
          this.onMessageSubmitFail(result_or_error);
          return;
        }

        // Succeed.
        if (result_or_error) {
          this.tcfaCommonModalService
            .showAlertModal('Su consulta se ha enviado exitosamente.')
            .then(modal => modal.result
              .finally(() => {
                this.message.text = '';
                this.onMessageTextChange();
                this.contactModal.close();
              }));
        } else {
          this.onMessageSubmitFail(null);
        }
      }, (error: any) => {
        this.onMessageSubmitFail(error);
      }, () => {
        __loading_modal.then(modal => modal.close());
        this.disableSubmit = false;
        this.captchaResolved = false;
      });
  }

  private onMessageSubmitFail(error: any): void {
    const __SUBTAG = 'onMessageSubmitFail',
      __appError: BaseAppError = this.errorService.getAppError(error);
    _logger.error(_TAG, __SUBTAG, 'Error:', __appError.getMessage());

    let __message: string;
    if (!error || (__appError.getCode() !== ErrorService.getApiHttpErrorCode(400))) {
      if (error instanceof String) {
        __message = error.toString();
      } else if (typeof error === 'string') {
        __message = error;
      } else {
        __message = 'Ha ocurrido un error. Intente nuevamente en unos minutos.';
      }
    } else {
      __message = __appError.getMessage();
    }
    this.tcfaCommonModalService.showErrorModal(__message);
  }

  protected onCaptchaResolved(): void {
    this.captchaResolved = true;
  }
}
