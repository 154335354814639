import { ICotizadorGetDatosCalculoCuotasResponseTipoPersonaItem } from '../services/cotizador/i-cotizador-get-datos-calculo-cuotas-response-tipo-persona-item';
import { TaxConditionModel } from './tax-condition.model';
import { CanonModel } from './canon.model';


export class PersonTypeModel {
  public code: string;
  public id: number;
  public numberCode: number;
  public loanCode: string;
  public isDefault: boolean;
  public name: string;
  public taxConditions: TaxConditionModel[];
  public canons: CanonModel[];

  constructor(data: ICotizadorGetDatosCalculoCuotasResponseTipoPersonaItem) {
    this.code = data.codigo;
    this.numberCode = data.codigo_numerico;
    this.id = data.id;
    this.loanCode = data.codigo_prestamo;
    this.isDefault = data.default;
    this.name = data.descripcion;
    this.taxConditions = data.condicion_impositiva.map(c => new TaxConditionModel(c));
  }
}
