import { Injectable } from '@angular/core';
import * as moment from 'moment';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'PersistanceService';
_logger.debug(_TAG, 'loaded.');

const _TIMESTAMP_KEY_ACCESOR = '__$timestamp',
  _SUFFIX_KEY_ACCESOR = '__$suffix';


@Injectable({
  providedIn: 'root'
})
export class PersistanceService {

  constructor() {
  }

  public store(key: string, data: any, suffix: string = ''): void {
    const __SUBTAG = 'store';
    _logger.debug(_TAG, __SUBTAG, 'Merhod invoked. Key:', key, '; Data:', data, '; Suffix:', suffix);
    try {
      localStorage.setItem(key + _SUFFIX_KEY_ACCESOR, suffix);
      localStorage.setItem(key + suffix, JSON.stringify(data));
      localStorage.setItem(key + suffix + _TIMESTAMP_KEY_ACCESOR, moment().format());
    } catch (e) {
      _logger.debug(_TAG, __SUBTAG, 'Error while storing data. Key:', key, '; Error:', e);
    }
  }

  public clear(key: string, data: any, suffix: string = ''): void {
    const __SUBTAG = 'clear';
    _logger.debug(_TAG, __SUBTAG, 'Merhod invoked. Key:', key, '; Data:', data, '; Suffix:', suffix);
    try {
      localStorage.removeItem(key + _SUFFIX_KEY_ACCESOR);
      localStorage.removeItem(key + suffix);
      localStorage.removeItem(key + suffix + _TIMESTAMP_KEY_ACCESOR);
    } catch (e) {
      _logger.debug(_TAG, __SUBTAG, 'Error while clearing data. Key:', key, '; Error:', e);
    }
  }

  public retrieve(key: string, max_age: number, suffix: string = ''): any {
    const __SUBTAG = 'retrieve';
    _logger.debug(_TAG, __SUBTAG, 'Merhod invoked. Key:', key, '; Age:', max_age, '; Suffix:', suffix);
    try {
      const __storedTimestamp = moment(localStorage.getItem(key + suffix + _TIMESTAMP_KEY_ACCESOR)),
        __now = moment();
      if (__now.diff(__storedTimestamp) <= max_age) {
        _logger.debug(_TAG, __SUBTAG, 'Retrieving item. Key:', key);
        return JSON.parse(localStorage.getItem(key + suffix));
      }
      _logger.debug(_TAG, __SUBTAG, 'Item expired or invalid. Key:', key);
    } catch (e) {
      _logger.debug(_TAG, __SUBTAG, 'Error while retrieving data. Key:', key, '; Error:', e);
    }
    return null;
  }

  public isExpiredOrEmpty(key: string, max_age: number, suffix: string = ''): boolean {
    const __SUBTAG = 'isExpiredOrEmpty';
    _logger.debug(_TAG, __SUBTAG, 'Merhod invoked. Key:', key, '; Age:', max_age, '; Suffix:', suffix);
    try {
      const __storedTimestamp = moment(localStorage.getItem(key + suffix + _TIMESTAMP_KEY_ACCESOR)),
        __now = moment();
      if (__now.diff(__storedTimestamp) <= max_age) {
        _logger.debug(_TAG, __SUBTAG, 'Cache data is not expired. Key:', key);
        const __data = JSON.parse(localStorage.getItem(key + suffix));
        _logger.debug(_TAG, __SUBTAG, 'Cache data is null:', __data === null);
        return __data === null;
      }
    } catch (e) {
      _logger.debug(_TAG, __SUBTAG, 'Error while checking data. Key:', key, '; Error:', e);
    }
    return true;
  }
}
