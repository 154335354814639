import { ICotizadorGetCanalAnonimoResponseMarcaItem } from '../services/cotizador/i-cotizador-get-canal-anonimo-response-marca-item';
import { CanalAnonimoModelDataModel } from './canal-anonimo-model-data.model';
import { ICotizadorGetCanalAnonimoResponseModeloItem } from '../services/cotizador/i-cotizador-get-canal-anonimo-response-modelo-item';


export class CanalAnonimoBrandDataModel {
  public name: string;
  public id: number;
  public models: CanalAnonimoModelDataModel[];

  constructor(data: ICotizadorGetCanalAnonimoResponseMarcaItem) {
    this.name = data.descripcion;
    this.id = parseInt(data.id, 10);
    this.models = data.modelo.map(
      (model: ICotizadorGetCanalAnonimoResponseModeloItem) => new CanalAnonimoModelDataModel(model));
  }
}
