import { ErrorHandler, Injectable } from '@angular/core';
import { BaseAppError } from './base-app-error';
import { HttpErrorResponse } from '@angular/common/http';
// Models.
import { IAPIErrorResponse } from './i-api-error-response';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'ErrorService';
_logger.debug(_TAG, 'loaded.');


@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
  /** Unknown error. */
  public readonly UNKNOWN_ERROR = {code: 'UNKNOWN', message: 'Error desconocido.'};

  /** Known external errors. */
  public readonly EXTERNAL_KNOWN_ERROR = {
    NETWORK: {
      OFFLINE: {
        code: 'EXT:NETWORK:OFFLINE',
        message: 'No fue posible conectarse con el servidor. Por favor, compruebe su conexión a Internet e intente nuevamente.'
      }
    },
    API: {
      SERVER_BASE: {
        code: 'EXT:API:SERVER_BASE',
        message: 'Ha ocurrido un error en la red. Por favor, intente nuevamente en unos instantes.'
      },
      SESSION_EXPIRED: {code: 'EXT:API:SESSION_EXPIRED', message: 'Su sesión ha caducado.'}
    }
  };

  constructor() {
  }

  /**
   * Returns a known error code from any HTTP error number code.
   */
  private static getHttpErrorCode(code: number): string {
    return 'HTTP:' + code.toString();
  }

  /**
   * Returns a known error code from an API HTTP error number code.
   */
  public static getApiHttpErrorCode(code: number): string {
    return 'API:' + ErrorService.getHttpErrorCode(code);
  }

  /**
   * Errors handler. Default behaviour: console log if not production env.
   */
  public handleError(error: any): void {
    const __SUBTAG = 'handleError';
    if (!_config.production) {
      _logger.error(_TAG, __SUBTAG, error);
    }
  }

  /**
   * Parses API message errors.
   */
  private getApiErrorText(apiError: IAPIErrorResponse): string {
    // Get error message from API error response interface.
    return ((apiError && apiError.message) || this.UNKNOWN_ERROR.message);
    /*
    if (error.data.errors) { // FIXME: revisar este if
      if (!(error.data.errors.message instanceof Array)) {
        __error_message = error.data.errors.message;
      } else {
        __error_message = error.data.errors.message.join(' ');
      }
    }*/
  }

  /**
   * Handles App errors.
   */
  public getAppError(appError: any): BaseAppError {
    const __SUBTAG = 'getAppError';

    if (appError instanceof BaseAppError) {
      return appError;
    }

    _logger.debug(_TAG, __SUBTAG, 'Error:', appError);

    // Base error.
    let __errorCode: string = (appError && (appError.code || appError.errorCode) || this.UNKNOWN_ERROR.code),
      __errorMessage: string = (appError && (appError.message || appError.errorMessage) || this.UNKNOWN_ERROR.message);

    // HTTP Errors.
    if (appError instanceof HttpErrorResponse) {
      // Common HTTP errors.
      _logger.debug(_TAG, __SUBTAG, 'error is instanceof HttpErrorResponse');

      __errorCode = ErrorService.getHttpErrorCode(appError.status);
      __errorMessage = (appError.statusText || __errorMessage);

      _logger.debug(_TAG, __SUBTAG, 'error partial.', 'Code:', __errorCode, 'Msg:', __errorMessage);

      // Get Response body.
      let __responseBody: any = (appError.error || {});
      if ((__responseBody instanceof String) || (typeof __responseBody === 'string')) {
        try {
          _logger.debug(_TAG, __SUBTAG, 'parsing JSON HttpErrorResponse Body:', __responseBody);
          if (typeof __responseBody !== 'string') {
            __responseBody = JSON.parse(__responseBody.toString());
          } else {
            __responseBody = JSON.parse(__responseBody);
          }
        } catch (__e) {
          _logger.error(_TAG, __SUBTAG, 'Error parsing appError Response body as JSON:', __e);
        }
      }
      _logger.debug(_TAG, __SUBTAG, 'HttpErrorResponse Body:', __responseBody);

      // Get error context by URL.
      if (appError.url) {
        _logger.debug(_TAG, __SUBTAG, 'HttpErrorResponse URL:', appError.url);

        // TCFA API errors.
        if (appError.url.indexOf(_config.apiBaseURL) === 0) {
          _logger.debug(_TAG, __SUBTAG, 'HttpErrorResponse URL matches API:', _config.apiBaseURL);

          __errorCode = ErrorService.getApiHttpErrorCode(appError.status);
          __errorMessage = (this.getApiErrorText(__responseBody as IAPIErrorResponse) || __errorMessage);

          _logger.debug(_TAG, __SUBTAG, 'error partial.', 'Code:', __errorCode, 'Msg:', __errorMessage);
        }
      }

      // Override explicit errors messages.
      // Network error.
      if (appError.status === 0) {
        _logger.debug(_TAG, __SUBTAG, 'HttpErrorResponse overriding error status 0 (network error).');

        __errorMessage = this.EXTERNAL_KNOWN_ERROR.NETWORK.OFFLINE.message;
        // Server-side errors.
      } else if (appError.status >= 500) {
        _logger.debug(_TAG, __SUBTAG, 'HttpErrorResponse overriding error status 500 (server error).');

        __errorMessage = this.EXTERNAL_KNOWN_ERROR.API.SERVER_BASE.message;
        // Session error.
      } else if (appError.status === 401) {
        _logger.debug(_TAG, __SUBTAG, 'HttpErrorResponse overriding error status 401 (session error).');

        __errorMessage = this.EXTERNAL_KNOWN_ERROR.API.SESSION_EXPIRED.message;

        // Broadcast session expired event.
        // TODO: agregar...
      }

      // Known errors.
    } else if (appError instanceof Error) {
      // Base known errors.
      _logger.debug(_TAG, __SUBTAG, 'error is instanceof Error');

      __errorCode = (appError.name || __errorCode);
      __errorMessage = (appError.message || __errorMessage);

      // Numeric errors.
    } else if ((appError instanceof Number) || (typeof appError === 'number')) {
      // Base numeric errors.
      _logger.debug(_TAG, __SUBTAG, 'error is instanceof Number');

      __errorCode = appError.toString();
      __errorMessage = 'Error ' + appError.toString() + '.';

      // String errors (no code).
    } else if ((appError instanceof String) || (typeof appError === 'string')) {
      _logger.debug(_TAG, __SUBTAG, 'error is instanceof String');

      __errorMessage = (appError.toString() || __errorMessage);

      // Other erros.
    } else {
      _logger.debug(_TAG, __SUBTAG, 'error is instanceof None');

      // None.
    }

    _logger.debug(_TAG, __SUBTAG, 'error partial.', 'Code:', __errorCode, 'Msg:', __errorMessage);

    // Handle error & return error message.
    const __resultError: BaseAppError = new BaseAppError(__errorCode, __errorMessage);
    this.handleError(__resultError);
    return __resultError;
  }
}
