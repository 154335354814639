import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
// Models.
import { BaseAppError } from '../error/base-app-error';
import { IParamsGetAllParamsResponse } from './i-params-get-all-params-response';
import { ParamModel } from '../../models/param.model';
// Providers.
import { ErrorService } from '../error/error.service';
import { PersistanceService } from '../persistance/persistance.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'ParamsService';
_logger.debug(_TAG, 'loaded.');

const _CACHE_CONFIG = {
  GET_ALL_PARAMS: {
    KEY: 'get_all_params',
    AGE: 8 * 60 * 60 * 1000 // 8h * 60m * 60s * 1000ms.
  }
};


@Injectable({
  providedIn: 'root'
})
export class ParamsService {
  private baseURL = `${_config.apiBaseURL}/parametros`;

  private getAllParamsCache: Observable<ParamModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private persistanceService: PersistanceService
  ) {
  }

  public getAllParams(): Observable<ParamModel[] | BaseAppError> {
    const __SUBTAG = 'getAllParams';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    // Check cache.
    if (this.persistanceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ALL_PARAMS.KEY, _CACHE_CONFIG.GET_ALL_PARAMS.AGE)) {
      const __endpointURL = `${this.baseURL}`;
      _logger.debug(_TAG, __SUBTAG, 'request:', 'GET', __endpointURL);

      this.getAllParamsCache = this.http.get<IParamsGetAllParamsResponse>(__endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IParamsGetAllParamsResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IParamsGetAllParamsResponse>) => response.body.data.map(p => new ParamModel(p))),
          // Store in cache.
          tap((data: ParamModel[]) => {
            this.persistanceService.store(_CACHE_CONFIG.GET_ALL_PARAMS.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.getAllParamsCache) {
        const __cacheData: ParamModel[] = this.persistanceService.retrieve(_CACHE_CONFIG.GET_ALL_PARAMS.KEY,
          _CACHE_CONFIG.GET_ALL_PARAMS.AGE);
        this.getAllParamsCache = of(__cacheData)
          .pipe(
            // Log operation.
            tap((data: ParamModel[]) => {
              _logger.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.getAllParamsCache;
  }
}
