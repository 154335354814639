import { Injectable } from '@angular/core';
// Providers.
import { IScriptLoaderScript } from './i-script-loader-script';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'ScriptStore';
_logger.debug(_TAG, 'loaded.');


declare var document: any;

export const ScriptStore: IScriptLoaderScript[] = [
  {name: 'TCFASeguros', src: _config.tcfaSegurosSdkURL},
  {name: 'Facebook', src: _config.facebookSdkURL}
];

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  public load(...scripts: string[]): Promise<any> {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string): Promise<any> {
    const __SUBTAG = 'loadScript';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.', 'Script:', name);

    return new Promise((resolve) => {
      // Resolve if already loaded.
      if (this.scripts[name].loaded) {
        _logger.debug(_TAG, __SUBTAG, `Script ${name} already loaded.`);
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        // Load script.
        _logger.debug(_TAG, __SUBTAG, `Loading script ${name}...`);
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  // IE.
          _logger.debug(_TAG, __SUBTAG, `Loading script ${name}...`, 'Method: IE.');
          script.onreadystatechange = () => {
            _logger.debug(_TAG, __SUBTAG, `Loading script ${name}...`, 'Detected ready state change. State:', script.readyState);
            if ((script.readyState === 'loaded') || (script.readyState === 'complete')) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  // Others.
          _logger.debug(_TAG, __SUBTAG, `Loading script ${name}...`, 'Method: Others.');
          script.onload = () => {
            _logger.debug(_TAG, __SUBTAG, `Loading script ${name}...`, 'Detected load.');
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => {
          _logger.debug(_TAG, __SUBTAG, `Error while loading script ${name}. Error:`, error);
          resolve({script: name, loaded: false, status: 'Loaded'});
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
