export class Utils {
  private static _isPlatformWindows = /IEMobile/i.test(window.navigator.userAgent);
  private static _isPlatformAndroid = /Android/i.test(window.navigator.userAgent);
  private static _isPlatformBlackberry = /BlackBerry/i.test(window.navigator.userAgent);
  private static _isPlatformIos = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);

  public static isMobile(platform: 'windows' | 'android' | 'blackberry' | 'ios' | 'any'): any {
    switch (platform) {
      case 'windows':
        return Utils._isPlatformWindows;
      case 'android':
        return Utils._isPlatformAndroid;
      case 'blackberry':
        return Utils._isPlatformBlackberry;
      case 'ios':
        return Utils._isPlatformIos;
      case 'any':
        return Utils._isPlatformWindows || Utils._isPlatformAndroid || Utils._isPlatformBlackberry || Utils._isPlatformIos;
      default:
        return false;
    }
  }
}
