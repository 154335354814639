import { ICotizadorDoCotizarResponsePlanItem } from '../services/cotizador/i-cotizador-do-cotizar-response-plan-item';
import { ICotizadorDoCotizarResponseSubplanItem } from '../services/cotizador/i-cotizador-do-cotizar-response-subplan-item';
import { PlazoModel } from './plazo.model';
import { InstallmentModel } from './installment.model';


const __sumReducer = (accumulated, current): number => {
  return parseFloat(accumulated) + parseFloat(current);
};


export class PlanModel {
  public planId: number;
  public subplanId: number;
  public name: string;
  public description: string;
  public isTDF: boolean;
  public isCollapsed: boolean;
  public installmentsCount: number;
  public isPromo: boolean;
  public promoAdjust: boolean;
  public promoAmount: number;
  public esAgro: boolean;
  public isUva: boolean;
  public uvaCoefficient: number;
  public esAguinaldo: boolean;
  public esBaloon: boolean;
  public p_sup_peri_cuotas_ext_n: number;
  public p_sup_impo_cuotas_ext_n: number;
  public p_ref_porcentaje_n: number;
  public p_sup_anticipo_porcentaje_n: number;
  public p_sup_cant_cuotas_ext_n: number;

  public installments: InstallmentModel[];

  public plazo: PlazoModel;

  public disableInstallments: boolean;
  public errorInstallments: boolean;

  private cuotaPromedioWaitingForLoanPromise = false;
  private cuotaRefuerzoWaitingForLoanPromise = false;
  private canonPromedioMensualWaitingForLoanPromise = false;
  private primeraCuotaWaitingForLoanPromise = false;
  private ultimaCuotaWaitingForLoanPromise = false;
  private cuotaPuraEnUvasWaitingForLoanPromise = false;

  public primeraCuotaConSeguro: number;
  public errorPrimeraCuota: boolean;

  public ultimaCuotaConSeguro: number;
  public errorUltimaCuota: boolean;

  private cuotaPromedio: number;
  public errorCuotaPromedio: boolean;

  private canonAnticipado: number;
  public errorCanonAnticipado: boolean;

  private cuotaRefuerzo: number;
  public errorCuotaRefuerzo: boolean;

  private canonPromedioMensual: number;
  public errorCanonPromedioMensual: boolean;

  private opcionDeCompra: number;
  public errorOpcionDeCompra: boolean;

  private cuotaPuraEnUvas: number;
  public errorCuotaPuraEnUvas: boolean;

  public isLoadingPDF = false;

  public isLoadingLegalTexts = false;
  public errorLegalText = false;
  public legalTexts: string;
  public legalTextsPromise: Promise<any>;

  constructor(plan: ICotizadorDoCotizarResponsePlanItem, subplan: ICotizadorDoCotizarResponseSubplanItem, installments_count: number,
              loan_amount: number) {
    this.planId = plan.p_pla_id_i;
    this.subplanId = subplan.p_sup_id_i;
    this.name = plan.p_pla_descripcion_c;
    this.description = plan.p_pla_comentario_c;
    this.isTDF = (plan.p_plan_destino_c || '').includes(' TDF');
    this.isCollapsed = true;
    this.installmentsCount = installments_count;
    this.isPromo = (plan.p_es_plan_subvencionado_i === 1);
    this.promoAdjust = (plan.p_importe_max_fin_n < loan_amount);
    this.promoAmount = plan.p_importe_max_fin_n;
    this.esAgro = ((subplan.p_sup_anticipo_porcentaje_n && subplan.p_sup_cant_cuotas_ext_n && subplan.p_sup_peri_cuotas_ext_n &&
      subplan.p_sup_porc_cuotas_ext_n && true) || false);
    this.esAguinaldo = ((subplan.p_sup_anticipo_porcentaje_n && subplan.p_sup_cant_cuotas_ext_n && subplan.p_sup_impo_cuotas_ext_n
      && true) || false);
    this.esBaloon = ((subplan.p_sup_anticipo_porcentaje_n && subplan.p_ref_porcentaje_n && true) || false);
    this.isUva = (plan.p_factor_c === 'UVA'),
    this.uvaCoefficient = plan.p_coeficiente_n,
    this.p_sup_peri_cuotas_ext_n = subplan.p_sup_peri_cuotas_ext_n;
    this.p_sup_impo_cuotas_ext_n = subplan.p_sup_impo_cuotas_ext_n;
    this.p_ref_porcentaje_n = subplan.p_ref_porcentaje_n;
    this.p_sup_anticipo_porcentaje_n = subplan.p_sup_anticipo_porcentaje_n;
    this.p_sup_cant_cuotas_ext_n = subplan.p_sup_cant_cuotas_ext_n;
  }

  public recalculateCuotaPromedio(is_retail_operation: boolean, loan_amount: number, insurance_installment: number): number {
    this.cuotaPromedio = null;
    this.cuotaPromedioWaitingForLoanPromise = false;
    return this.getCuotaPromedio(is_retail_operation, loan_amount, insurance_installment);
  }

  public getCuotaPromedio(is_retail_operation: boolean, loan_amount: number, insurance_installment: number): number {
    if (!is_retail_operation) {
      return null;
    }

    if (this.cuotaPromedio || (this.cuotaPromedio === 0)) {
      return this.cuotaPromedio;
    }

    if (!this.cuotaPromedioWaitingForLoanPromise && this.plazo.loanInfoPromise) {
      this.cuotaPromedioWaitingForLoanPromise = true;
      this.plazo.loanInfoPromise.then(loan_data => {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === this.subplanId);

        if (__installments_data.length === 1) {
          // Plan Tradicional: Sumatoria(Cuota Final) / Plazo.
          this.cuotaPromedio = this.installments
            .map(installment => installment.getCuotaFinal(loan_amount, insurance_installment))
            .reduce(__sumReducer, 0) / this.installmentsCount;
          // Plan Agro: (Sumatoria(Cuota Final) - Capital a Financiar) / Plazo.
          if (this.esAgro) {
            this.cuotaPromedio = (this.installments
              .map(installment => installment.getCuotaFinal(loan_amount, insurance_installment))
              .reduce(__sumReducer, 0) - loan_amount) / this.installmentsCount;
          }
          // Plan Aguinaldo: (Sumatoria(Cuota Final) - (p_sup_cant_cuotas_ext_n * p_sup_impo_cuotas_ext_n)) / Plazo.
          if (this.esAguinaldo) {
            this.cuotaPromedio = (this.installments
              .map(installment => installment.getCuotaFinal(loan_amount, insurance_installment))
              .reduce(__sumReducer, 0) - (this.p_sup_cant_cuotas_ext_n * this.p_sup_impo_cuotas_ext_n)) / this.installmentsCount;
          }
          // Plan Baloon: (Sumatoria(Cuota Final) - (Monto a Financiar * p_ref_porcentaje_n / 100)) / Plazo.
          if (this.esBaloon) {
            this.cuotaPromedio = (this.installments
              .map(installment => installment.getCuotaFinal(loan_amount, insurance_installment))
              .reduce(__sumReducer, 0) - (loan_amount * this.p_ref_porcentaje_n / 100)) / this.installmentsCount;
          }
        } else {
          this.errorCuotaPromedio = true;
        }
      }, () => {
        this.errorCuotaPromedio = true;
      });
    }

    return null;
  }

  public getCanonAnticipado(is_retail_operation: boolean, vehicle_price: number): number {
    if (is_retail_operation) {
      return null;
    }

    if (!this.canonAnticipado && (this.canonAnticipado !== 0)) {
      this.canonAnticipado = ((this.p_sup_anticipo_porcentaje_n || 0) * vehicle_price / 100);
    }

    return this.canonAnticipado;
  }

  // Cuota Refuerzo = (*)Se calcula distinto según cada tipo de plan.
  public getCuotaRefuerzo(is_retail_operation: boolean, loan_amount: number): number {
    if (!is_retail_operation) {
      return null;
    }

    if (this.cuotaRefuerzo || (this.cuotaRefuerzo === 0)) {
      return this.cuotaRefuerzo;
    }

    if (!this.cuotaRefuerzoWaitingForLoanPromise && this.plazo.loanInfoPromise) {
      this.cuotaRefuerzoWaitingForLoanPromise = true;
      this.plazo.loanInfoPromise.then((loan_data) => {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === this.subplanId);

        if (__installments_data.length === 1) {
          // Plan Tradicional y Otros = N/A
          this.cuotaRefuerzo = -1; // No aplica.
          // Plan Agro: Monto a Financiar / p_sup_cant_cuotas_ext_n.
          if (this.esAgro && (this.p_sup_cant_cuotas_ext_n > 0)) {
            this.cuotaRefuerzo = loan_amount / this.p_sup_cant_cuotas_ext_n;
          }
          // Plan Aguinaldo: propiedad "p_sup_impo_cuotas_ext_n".
          if (this.esAguinaldo && (this.p_sup_impo_cuotas_ext_n > 0)) {
            this.cuotaRefuerzo = this.p_sup_impo_cuotas_ext_n;
          }
          // Plan Baloon: Monto a Financiar * p_ref_porcentaje_n / 100.
          if (this.esBaloon && (this.p_ref_porcentaje_n > 0)) {
            this.cuotaRefuerzo = loan_amount * this.p_ref_porcentaje_n / 100;
          }
        } else {
          this.errorCuotaRefuerzo = true;
        }
      }, () => {
        this.errorCuotaRefuerzo = true;
      });
    }
    return null;
  }

  public recalculateCanonPromedioMensual(is_retail_operation: boolean, insurance_installment: number): number {
    this.canonPromedioMensual = null;
    this.canonPromedioMensualWaitingForLoanPromise = false;
    return this.getCanonPromedioMensual(is_retail_operation, insurance_installment);
  };

  // Canon Promedio Mensual = [Sumatoria(Capital_Menos_Ultimo_Valor + Interés) / Plazo] + Gasto + Cuota Seguro Vehículo.
  public getCanonPromedioMensual(is_retail_operation: boolean, insurance_installment: number): number {
    if (is_retail_operation) {
      return null;
    }

    if (this.canonPromedioMensual || (this.canonPromedioMensual === 0)) {
      return this.canonPromedioMensual;
    }
    if (!this.canonPromedioMensualWaitingForLoanPromise && this.plazo.loanInfoPromise) {
      this.canonPromedioMensualWaitingForLoanPromise = true;
      this.plazo.loanInfoPromise.then((loan_data) => {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === this.subplanId);
        let __capital_menos_ultimo_valor, __interes_menos_ultimo_valor;

        if (__installments_data.length === 1) {
          __capital_menos_ultimo_valor = (__installments_data[0].capital || []).map(cap => cap);
          __capital_menos_ultimo_valor.splice(-1);
          __interes_menos_ultimo_valor = (__installments_data[0].interes || []).map(interes => interes);
          __interes_menos_ultimo_valor.splice(-1);
          this.canonPromedioMensual = ((__capital_menos_ultimo_valor.reduce(__sumReducer, 0) +
            (__interes_menos_ultimo_valor).reduce(__sumReducer, 0)) / (__installments_data[0].plazo || 1)) +
            parseFloat(__installments_data[0].gasto || '0') + (insurance_installment || 0);
        } else {
          this.errorCanonPromedioMensual = true;
        }
      }, () => {
        this.errorCanonPromedioMensual = true;
      });
    }

    return null;
  }

  public recalculatePrimeraCuota(is_retail_operation: boolean, insurance_installment: number): number {
    this.primeraCuotaConSeguro = null;
    this.primeraCuotaWaitingForLoanPromise = false;
    return this.getPrimeraCuota(is_retail_operation, insurance_installment);
  }

  public getPrimeraCuota(is_retail_operation: boolean, insurance_installment: number): number {
    if (!is_retail_operation) {
      return null;
    }

    if (this.primeraCuotaConSeguro || (this.primeraCuotaConSeguro === 0)) {
      return this.primeraCuotaConSeguro;
    }

    if (!this.primeraCuotaWaitingForLoanPromise && this.plazo.loanInfoPromise) {
      this.primeraCuotaWaitingForLoanPromise = true;
      this.plazo.loanInfoPromise.then((loan_data)=> {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === this.subplanId);

        if (__installments_data.length === 1) {
          this.primeraCuotaConSeguro = parseFloat(__installments_data[0].primera_cuota_con_seguro);
        } else {
          this.errorPrimeraCuota = true;
        }
      }, () => {
        this.errorPrimeraCuota = true;
      });
    }
  }

  public recalculateUltimaCuota(is_retail_operation: boolean, insurance_installment: number): number {
    this.ultimaCuotaConSeguro = null;
    this.ultimaCuotaWaitingForLoanPromise = false;
    return this.getUltimaCuota(is_retail_operation, insurance_installment);
  }

  // Última Cuota = ultima_cuota_con_seguro(*) + Cuota Seguro. (*)Cálculo realizado en el BackEnd.
  public getUltimaCuota(is_retail_operation: boolean, insurance_installment: number): number {
    if (!is_retail_operation) {
      return null;
    }

    if (this.ultimaCuotaConSeguro || (this.ultimaCuotaConSeguro === 0)) {
      return this.ultimaCuotaConSeguro;
    }

    if (!this.ultimaCuotaWaitingForLoanPromise && this.plazo.loanInfoPromise) {
      this.ultimaCuotaWaitingForLoanPromise = true;
      this.plazo.loanInfoPromise.then((loan_data) => {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === this.subplanId);

        if (__installments_data.length === 1) {
          this.ultimaCuotaConSeguro = parseFloat(__installments_data[0].ultima_cuota_con_seguro) + (insurance_installment || 0);
        } else {
          this.errorUltimaCuota = true;
        }
      }, () => {
        this.errorUltimaCuota = true;
      });
    }

    return null;
  }

  // Opción de Compra (sin IVA) = Valor de la Unidad * p_ref_porcentaje_n / 100.
  public getOpcionDeCompra(is_retail_operation: boolean, vehicle_price: number): number {
    if (is_retail_operation) {
      return null;
    }

    if (!this.opcionDeCompra && (this.opcionDeCompra !== 0)) {
      this.opcionDeCompra = vehicle_price * this.p_ref_porcentaje_n / 100;
    }

    return this.opcionDeCompra;
  }

  public getCapitalAFinanciarEnUvas(is_retail_operation: boolean, loan_amount: number): number {
    if (!is_retail_operation || !this.isUva) {
      return null;
    }

    return loan_amount / this.uvaCoefficient;
  }

  public recalculateCuotaPuraEnUvas(is_retail_operation: boolean): number {
    this.cuotaPuraEnUvas = null;
    this.cuotaPuraEnUvasWaitingForLoanPromise = false;
    return this.getCuotaPuraEnUvas(is_retail_operation);
  }

  public getCuotaPuraEnUvas(is_retail_operation: boolean): number {
    if (!is_retail_operation || !this.isUva) {
      return null;
    }

    if (this.cuotaPuraEnUvas || (this.cuotaPuraEnUvas === 0)) {
      return this.cuotaPuraEnUvas;
    }

    if (!this.cuotaPuraEnUvasWaitingForLoanPromise && this.plazo.loanInfoPromise) {
      this.cuotaPuraEnUvasWaitingForLoanPromise = true;
      this.plazo.loanInfoPromise.then((loan_data) => {
        const __installments_data = loan_data.filter(l => l.p_sup_id_i === this.subplanId);

        if (__installments_data.length === 1) {
          this.cuotaPuraEnUvas = this.installments[0].cuotaPura / this.uvaCoefficient;
        } else {
          this.errorCuotaPuraEnUvas = true;
        }
      }, () => {
        this.errorCuotaPuraEnUvas = true;
      });
    }

    return null;
  }
}
