import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// Components.
import { TcfaModalLoadingComponent } from '../../components/tcfa-modal-loading/tcfa-modal-loading.component';
import { TcfaModalErrorComponent } from '../../components/tcfa-modal-error/tcfa-modal-error.component';
import { TcfaModalAlertComponent } from '../../components/tcfa-modal-alert/tcfa-modal-alert.component';
import { TcfaModalSuccessComponent } from '../../components/tcfa-modal-success/tcfa-modal-success.component';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaCommonModalService';
_logger.debug(_TAG, 'loaded.');


export enum ModalType {
  LOADING = 0,
  ERROR = 1,
  ALERT = 2,
  SUCCESS = 3
}

@Injectable({
  providedIn: 'root'
})
export class TcfaCommonModalService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  private showModal(modalType: ModalType, message: string, title?: string): Promise<NgbModalRef> {
    let __modalContent: any,
      __modalWindowClassSuffix: string,
      __keyboard: boolean;
    switch (modalType) {
      case ModalType.LOADING:
        __modalContent = TcfaModalLoadingComponent;
        __modalWindowClassSuffix = 'loading';
        __keyboard = false;
        break;
      case ModalType.ERROR:
        __modalContent = TcfaModalErrorComponent;
        __modalWindowClassSuffix = 'error';
        __keyboard = true;
        break;
      case ModalType.SUCCESS:
        __modalContent = TcfaModalSuccessComponent;
        __modalWindowClassSuffix = 'success';
        __keyboard = true;
        break;
      default:
        __modalContent = TcfaModalAlertComponent;
        __modalWindowClassSuffix = 'alert';
        __keyboard = false;
    }

    return new Promise<NgbModalRef>(resolve => {
      setTimeout(() => {
        const __modal = this.modalService.open(__modalContent, {
          ariaLabelledBy: 'modal-basic-title',
          windowClass: `modal-common-${__modalWindowClassSuffix} modal-animate`,
          keyboard: __keyboard,
          backdrop: (!__keyboard && 'static') || true,
          centered: true,
          size: 'lg'
        });
        __modal.componentInstance.message = message;
        if (title && (modalType === ModalType.SUCCESS)) {
          __modal.componentInstance.title = title;
        }
        resolve(__modal);
      });
    });
  }

  public showLoadingModal(message: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.LOADING, message);
  }

  public showErrorModal(message: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.ERROR, message);
  }

  public showAlertModal(message: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.ALERT, message);
  }

  public showSuccessModal(message: string, title: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.SUCCESS, message, title);
  }
}
