import { Pipe, PipeTransform } from '@angular/core';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  PADDING = '000000';


@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  constructor() {
  }

  public transform(value: number | string, fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value.toString() || '')
      .replace(new RegExp(`[^0-9${_config.decimalSeparator}]+`, 'gim'), '')
      .split(_config.decimalSeparator);

    integer = integer
      .replace(new RegExp('^0+', 'gim'), '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, _config.milesSeparator);

    if (!integer) {
      integer = '0';
    }

    fraction = (fractionSize > 0)
      ? _config.decimalSeparator + (fraction + PADDING).substring(0, fractionSize)
      : '';

    return _config.currencySymbol + integer + fraction;
  }

  public parse(value: number | string, fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value.toString() || '')
      .replace(new RegExp(`[^0-9${_config.decimalSeparator}]+`, 'gim'), '')
      .split(_config.decimalSeparator);

    integer = integer.replace(new RegExp('^0+', 'gim'), '');

    if (!integer) {
      integer = '0';
    }

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? _config.decimalSeparator + (fraction + PADDING).substring(0, fractionSize)
      : '';

    return integer + fraction;
  }
}
