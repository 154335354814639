import { ISegurosCotizacion } from '../services/seguros/i-seguros-cotizacion';


export class InsuranceModel {
  public name: string;
  public pack: string;
  public zone: string;
  public prov: string;
  public installments: number;
  public id: number;
  public company_id: string;
  public pack_code: string;
  public zone_cp: string;
  public prov_id: string;
  public detail: string;

  constructor(insurance_data: ISegurosCotizacion) {
    this.name = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_compania_desc_c) || 'Error');
    this.pack = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_cobertura_desc_c) || 'Error');
    this.zone = ((insurance_data && insurance_data.localidad && insurance_data.localidad.name) || 'Error');
    this.prov = ((insurance_data && insurance_data.provincia && insurance_data.provincia.name) || 'Error');
    this.installments = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_cuota_n) || 0);
    this.id = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_cotizacion_id_c) || 0);
    this.company_id = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_compania_id_i) || 'Error');
    this.pack_code = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_familia_cod_c) || 'Error');
    this.zone_cp = ((insurance_data && insurance_data.localidad && insurance_data.localidad.cp) || 'Error');
    this.prov_id = ((insurance_data && insurance_data.provincia && insurance_data.provincia.id) || 'Error');
    this.detail = ((insurance_data && insurance_data.seguro && insurance_data.seguro.p_cob_desc_ampliada_c) || 'Error');
  }

  public getInsuranceDateForSdkSeguros(): ISegurosCotizacion {
    return {
      seguro: {
        p_cuota_n: this.installments,
        p_cotizacion_id_c: this.id,
        p_compania_desc_c: this.name,
        p_cobertura_desc_c: this.pack,
        p_compania_id_i: this.company_id,
        p_familia_cod_c: this.pack_code,
        p_cob_desc_ampliada_c: this.detail
      },
      localidad: {
        name: this.zone,
        cp: this.zone_cp
      },
      provincia: {
        name: this.prov,
        id: this.prov_id
      },
      success: true
    };
  }
}
