import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
// Models.
import { IContactMessageData } from './i-contact-message-data';
import { IContactNewMessageResponse } from './i-contact-new-message-response';
import { BaseAppError } from '../error/base-app-error';
// Providers.
import { ErrorService } from '../error/error.service';
import { TcfaContextService } from '../tcfa-context/tcfa-context.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'ContactService';
_logger.debug(_TAG, 'loaded.');


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private baseURL = `${_config.apiBaseURL}/cotizador/lexus`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private tcfaContextService: TcfaContextService
  ) {
  }

  public newMessage(message_data: IContactMessageData): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'newMessage';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');
    _logger.debug(_TAG, __SUBTAG, 'inputs:', 'Message data:', message_data);

    const __endpointURL = `${this.baseURL}/consulta`,
      __payload = {
        consulta: {
          nombre: message_data.name.trim(),
          texto: message_data.message.trim(),
          email: message_data.email.trim()
        },
        operacion: {
          tipo_operacion_id: (this.tcfaContextService.selectedOperation && this.tcfaContextService.selectedOperation.id),
          tipo_persona_id: (this.tcfaContextService.selectedPersonType && this.tcfaContextService.selectedPersonType.id),
          condicion_impositiva_id: (this.tcfaContextService.selectedTaxCondition && this.tcfaContextService.selectedTaxCondition.id),
          cuit_dealer: (this.tcfaContextService.dealer && this.tcfaContextService.dealer.cuit),
          vehiculo_nuevo: (this.tcfaContextService.selectedVersion && this.tcfaContextService.selectedVersion.isNew && 1 || 0),
          anio: (this.tcfaContextService.selectedVersion && this.tcfaContextService.selectedVersion.year),
          tipo_vehiculo_id: this.tcfaContextService.VEHICLE_TYPE,
          marca_id: (this.tcfaContextService.vehicles && this.tcfaContextService.vehicles.id),
          marca_descripcion: (this.tcfaContextService.vehicles && this.tcfaContextService.vehicles.name),
          modelo_id: (this.tcfaContextService.selectedVehicle && this.tcfaContextService.selectedVehicle.id),
          modelo_descripcion: (this.tcfaContextService.selectedVehicle && this.tcfaContextService.selectedVehicle.name),
          version_id: (this.tcfaContextService.selectedVersion && this.tcfaContextService.selectedVersion.id),
          version_descripcion: (this.tcfaContextService.selectedVersion && this.tcfaContextService.selectedVersion.name),
          vehiculo_id: (this.tcfaContextService.selectedVersion && this.tcfaContextService.selectedVersion.vehicleId),
          plan_id: null,
          plan_descripcion: null,
          subplan_id: null,
          subplan_descripcion: null,
          canon_anticipado: (this.tcfaContextService.selectedCanon && this.tcfaContextService.selectedCanon.key),
          monto_financiar: this.tcfaContextService.loanAmount
        }
      };
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload:', __payload);

    return this.http.post<IContactNewMessageResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IContactNewMessageResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response. Returns OK flag.
        map((response: HttpResponse<IContactNewMessageResponse>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }
}
