import { PlanModel } from './plan.model';
import { ICotizadorDoCuadroDeMarchaResponseCuadroItem } from '../services/cotizador/i-cotizador-do-cuadro-de-marcha-response-cuadro-item';


export class PlazoModel {
  public id: string;
  public installments: number;
  public isPromo: boolean;
  public isSelected: boolean;
  public isCollapsed: boolean;
  public isDisabled: boolean;
  public planes: PlanModel[];

  public isLoanInfoComplete = false;
  public loanInfoPromise: Promise<ICotizadorDoCuadroDeMarchaResponseCuadroItem[]>;
  public isLoanInfoLoading = false;
  public loanInfoError = false;

  constructor(code: string, is_promo: boolean, is_selected: boolean, is_collapsed: boolean, is_disabled: boolean, planes: PlanModel[]) {
    this.id = code;
    this.installments = parseInt(code, 10);
    this.isPromo = is_promo;
    this.isSelected = is_selected;
    this.isCollapsed = is_collapsed;
    this.isDisabled = is_disabled;
    this.planes = planes.sort((a, b) => {
      let __a = 1, __b = 1;
      if (a.isPromo) {
        __a = 0;
      }
      if (b.isPromo) {
        __b = 0;
      }
      return __a - __b;
    });

    this.planes.forEach(plan => plan.plazo = this);
  }
}
