import { ICotizadorGetCanalAnonimoResponse } from '../services/cotizador/i-cotizador-get-canal-anonimo-response';
import { CanalAnonimoBrandDataModel } from './canal-anonimo-brand-data.model';


export class CanalAnonimoModel {
  public carsData: CanalAnonimoBrandDataModel;

  constructor(data: ICotizadorGetCanalAnonimoResponse) {
    this.carsData = new CanalAnonimoBrandDataModel(data.data.autos);
  }
}
