import { Component, OnInit } from '@angular/core';
// Models.
import { ParamModel } from '../../models/param.model';
import { BaseAppError } from '../../services/error/base-app-error';
// Providers.
import { ParamsService } from '../../services/params/params.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaVideoComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-video',
  templateUrl: './tcfa-video.component.html',
  styleUrls: ['./tcfa-video.component.scss']
})
export class TcfaVideoComponent implements OnInit {
  protected youtubeVideoId: string;

  constructor(
    private paramsService: ParamsService
  ) {
  }

  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    this.paramsService.getAllParams()
      .subscribe((result_or_error: ParamModel[] | BaseAppError) => {
        // Failed.
        if (result_or_error instanceof BaseAppError) {
          this.youtubeVideoId = _config.promoVideoID;
          return;
        }

        // Succeed.
        const __param = result_or_error.find(p => p.key.toLowerCase() === 'lexus_video_cotizacion_url');
        if (__param) {
          const __regEx = /(?:https?:\/\/youtu\.be\/)(\w+)/i,
            __parseResult = __regEx.exec(__param.value); // Format: 'https://youtu.be/Dpkb4NZ5IEQ'
          if (__parseResult && (__parseResult.length >= 1)) {
            this.youtubeVideoId = __regEx.exec(__param.value)[1];
          } else {
            _logger.debug(_TAG, __SUBTAG, 'Parse video ID param failed.');
            this.youtubeVideoId = _config.promoVideoID;
          }
        } else {
          _logger.debug(_TAG, __SUBTAG, 'Get video ID param failed.');
          this.youtubeVideoId = _config.promoVideoID;
        }
      }, (error: any) => {
        _logger.debug(_TAG, __SUBTAG, 'Get video ID error:', error);
        this.youtubeVideoId = _config.promoVideoID;
      });
  }
}
