import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacebookService } from 'ngx-facebook';
// Components.
import { TcfaModalShareWithFriendsComponent } from '../tcfa-modal-share-with-friends/tcfa-modal-share-with-friends.component';
// Providers.
import { ScriptLoaderService } from '../../services/script-loader/script-loader.service';
import { DateroLogService } from '../../services/datero-log/datero-log.service';
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaModalShareComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-modal-share',
  templateUrl: './tcfa-modal-share.component.html',
  styleUrls: ['./tcfa-modal-share.component.scss']
})
export class TcfaModalShareComponent implements OnInit {
  @ViewChild(NgScrollbar) textScrollbar: NgScrollbar;
  @Input() disableFacebookShare = false;
  @Input() disableGooglePlusShare = false;
  @Input() disableShareWithFriends = false;
  @Input() shareUrl: string;
  @Input() dateroExtraData: { plazo?: number, plan_id?: number, subplan_id?: number };

  private facebookSdkPromise: Promise<any>;
  private facebookInitPromise: Promise<any>;

  constructor(
    public activeModal: NgbActiveModal,
    private scriptLoaderService: ScriptLoaderService,
    private facebookService: FacebookService,
    private modalService: NgbModal,
    private dateroLogService: DateroLogService,
    private tcfaContextService: TcfaContextService
  ) {
  }

  ngOnInit() {
    this.facebookSdkPromise = this.scriptLoaderService.load('Facebook');
    this.facebookSdkPromise
      .then(() => {
        this.facebookInitPromise = this.facebookService.init({
          appId: _config.facebookAppId,
          xfbml: true,
          version: _config.facebookSdkVersion
        });
      })
      .catch(() => {
        this.disableFacebookShare = true;
      });
  }

  /** Share loan on Facebook by API URL to allow OpenGraph to get the HTML tags. */
  protected onShareFacebook(): void {
    const __SUBTAG = 'onShareFacebook';
    if (!this.disableFacebookShare) {
      Promise.all<any>([this.facebookSdkPromise, this.facebookInitPromise])
        .then(() => {
          this.facebookService
            .ui({
              href: this.shareUrl,
              method: 'share'
            })
            .then(response => {
              _logger.debug(_TAG, __SUBTAG, 'Facebook share response:', response);

              // Datero Log event 11: "Compartir".
              if (!this.dateroExtraData) {
                this.dateroExtraData = {};
              }
              this.dateroLogService
                .doLog(11, this.dateroExtraData.plazo, this.tcfaContextService.dealer.cuit, this.dateroExtraData.plan_id,
                  this.dateroExtraData.subplan_id, null, this.tcfaContextService.vehicles.id, this.tcfaContextService.selectedVehicle.id,
                  this.tcfaContextService.selectedVersion.id, 1)
                .subscribe();
            });
        })
        .catch(() => {
          this.disableFacebookShare = true;
        })
        .finally(() => {
          this.activeModal.close();
        });
    }
  }

  /** Share loan on GooglePlus by API URL to allow OpenGraph to get the HTML tags. */
  protected onShareGooglePlus(): void {
    if (!this.disableGooglePlusShare) {
      window.open(_config.googlePlusShareURL + encodeURIComponent(this.shareUrl), '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

      // Datero Log event 11: "Compartir".
      if (!this.dateroExtraData) {
        this.dateroExtraData = {};
      }
      this.dateroLogService
        .doLog(11, this.dateroExtraData.plazo, this.tcfaContextService.dealer.cuit, this.dateroExtraData.plan_id,
          this.dateroExtraData.subplan_id, null, this.tcfaContextService.vehicles.id, this.tcfaContextService.selectedVehicle.id,
          this.tcfaContextService.selectedVersion.id, 1)
        .subscribe();

      this.activeModal.close();
    }
  }

  /** Share loan with friends by sending an e-mail. */
  protected onShareWithFriends(): void {
    if (!this.disableShareWithFriends) {
      const __modal = this.modalService.open(TcfaModalShareWithFriendsComponent, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: `modal-tcfa-share-with-friends modal-animate`,
        keyboard: true,
        backdrop: true,
        centered: true,
        size: 'lg'
      });
      __modal.componentInstance.dateroExtraData = this.dateroExtraData;
      __modal.componentInstance.shareUrl = this.shareUrl;

      this.activeModal.close();
    }
  }
}
