import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
// Shared.
import { EnvironmentManager } from './app/shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig();

if (_config.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
