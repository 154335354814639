import { environment } from '../../environments/environment';
import { environment as environmentProd } from '../../environments/environment.prod';
import { environment as environmentTest } from '../../environments/environment.test';
import { environment as environmentQa } from '../../environments/environment.qa';
import { environment as environmentDev } from '../../environments/environment.dev';


export interface ITcfaEnvironmentConfiguration {
  apiBaseURL: string;
  pediTuPrestamoURL: string;

  tcfaSegurosSdkURL: string;
  tcfaSegurosSdkEnv: string;

  facebookAppId: string;
  facebookAppSecret: string;

  reCaptchaApiKey: string;
}

export interface ITcfaSharedConfiguration {
  i18nLocaleShort: string;
  i18nLocaleFull: string;
  decimalSeparator: string;
  milesSeparator: string;
  currencySymbol: string;

  tcfaSegurosSdkTheme: string;

  googlePlusShareURL: string;

  facebookSdkURL: string;
  facebookSdkVersion: string;

  promoVideoID: string;
  dealerDefaultCUIT: string;
}

export interface ITcfaConfiguration extends ITcfaEnvironmentConfiguration, ITcfaSharedConfiguration {
  production: boolean;
}


const _SHARED_CONFIG: ITcfaSharedConfiguration = {
    i18nLocaleShort: 'es',
    i18nLocaleFull: 'es-AR',
    decimalSeparator: ',',
    milesSeparator: '.',
    currencySymbol: '$',

    tcfaSegurosSdkTheme: 'lexus',

    googlePlusShareURL: 'https://plus.google.com/share?url=',

    facebookSdkURL: 'https://connect.facebook.net/en_US/sdk.js',
    facebookSdkVersion: 'v2.8',

    promoVideoID: 'g3sXV9XiYVE',
    dealerDefaultCUIT: '22222222222'
  },
  _DOMAINS = {
    DEV: ['localhost:4200'],
    QA: ['qa.www.lexusfinancial.codeicus'],
    TEST: ['test.lexusfinancial.com.ar'],
    PROD: ['www.lexusfinancial.com.ar']
  };


/**
 * <Singleton>
 */
export class EnvironmentManager {
  private static _instance: EnvironmentManager = new EnvironmentManager();

  private config: ITcfaConfiguration;

  private constructor() {
  }

  public static getInstance(): EnvironmentManager {
    if (!EnvironmentManager._instance) {
      EnvironmentManager._instance = new EnvironmentManager();
    }
    return EnvironmentManager._instance;
  }

  private buildConfig(): void {
    const __config: any = {};

    // Get shared configuration.
    Object.keys(_SHARED_CONFIG)
      .forEach(key => __config[key] = _SHARED_CONFIG[key]);

    // Get env configuration.
    const __currentDomain = location.host;
    let __envConfig: ITcfaEnvironmentConfiguration;
    __config.production = false;
    if (_DOMAINS.PROD.some(d => d === __currentDomain)) {
      __envConfig = environmentProd;
      __config.production = true;
    } else if (_DOMAINS.TEST.some(d => d === __currentDomain)) {
      __envConfig = environmentTest;
    } else if (_DOMAINS.QA.some(d => d === __currentDomain)) {
      __envConfig = environmentQa;
    } else if (_DOMAINS.DEV.some(d => d === __currentDomain)) {
      __envConfig = environmentDev;
    } else {
      __envConfig = environment; // Default environment.
    }
    Object.keys(__envConfig)
      .forEach(key => __config[key] = __envConfig[key]);

    this.config = __config;
  }

  public getTcfaConfig(): ITcfaConfiguration {
    if (!this.config) {
      this.buildConfig();
    }
    return this.config;
  }
}
