import { Component, OnInit } from '@angular/core';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaFooterComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-footer',
  templateUrl: './tcfa-footer.component.html',
  styleUrls: ['./tcfa-footer.component.scss']
})
export class TcfaFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
