import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YTPlayerModule } from 'angular-youtube-player';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { JwtModule } from '@auth0/angular-jwt';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FacebookModule } from 'ngx-facebook';
// i18n config.
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeExtraEsAr from '@angular/common/locales/extra/es-AR';
// Components.
import { TcfaHeaderComponent } from './components/tcfa-header/tcfa-header.component';
import { TcfaFooterComponent } from './components/tcfa-footer/tcfa-footer.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TcfaContactComponent } from './components/tcfa-contact/tcfa-contact.component';
import { TcfaVideoComponent } from './components/tcfa-video/tcfa-video.component';
import { TcfaModalLoadingComponent } from './components/tcfa-modal-loading/tcfa-modal-loading.component';
import { TcfaModalErrorComponent } from './components/tcfa-modal-error/tcfa-modal-error.component';
import { TcfaModalAlertComponent } from './components/tcfa-modal-alert/tcfa-modal-alert.component';
import { CarPageComponent } from './pages/car-page/car-page.component';
import { LoansComponent } from './components/loans/loans.component';
import { TcfaSegurosModalComponent } from './components/tcfa-seguros-modal/tcfa-seguros-modal.component';
import { TcfaLegalTextModalComponent } from './components/tcfa-legal-text-modal/tcfa-legal-text-modal.component';
import { TcfaModalShareComponent } from './components/tcfa-modal-share/tcfa-modal-share.component';
import { TcfaModalShareWithFriendsComponent } from './components/tcfa-modal-share-with-friends/tcfa-modal-share-with-friends.component';
import { TcfaModalSuccessComponent } from './components/tcfa-modal-success/tcfa-modal-success.component';
// Directives.
import { CurrencyFormatterDirective } from './directives/currency-formatter/currency-formatter.directive';
// Pipes.
import { CurrencyPipe } from './pipes/currency/currency.pipe';
// Providers.
import { SessionService } from './services/session/session.service';
// Shared.
import { SimpleLogger } from './shared/simple-logger.shared';
import { EnvironmentManager } from './shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'AppModule';
_logger.debug(_TAG, 'loaded.');


/** i18n config. */
const _LOCALES = {es: localeEs, 'es-AR': localeExtraEsAr};
registerLocaleData(_LOCALES[_config.i18nLocaleShort], _config.i18nLocaleFull, _LOCALES[_config.i18nLocaleFull]);


/** Domain part of API endpoints base URL . */
const _DOMAIN_REGEXP: RegExp = /^(?:http[s]?:\/\/)?([^/\n\r]+)\/?(?:[^\n\r]+)?/i,
  _BASE_URL_DOMAIN: string = _config.apiBaseURL.match(_DOMAIN_REGEXP)[1];


@NgModule({
  declarations: [
    AppComponent,
    TcfaHeaderComponent,
    TcfaFooterComponent,
    HomePageComponent,
    TcfaContactComponent,
    TcfaVideoComponent,
    TcfaModalLoadingComponent,
    TcfaModalErrorComponent,
    TcfaModalAlertComponent,
    CarPageComponent,
    LoansComponent,
    CurrencyFormatterDirective,
    CurrencyPipe,
    TcfaSegurosModalComponent,
    TcfaLegalTextModalComponent,
    TcfaModalShareComponent,
    TcfaModalShareWithFriendsComponent,
    TcfaModalSuccessComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: SessionService.getSessionToken,
        whitelistedDomains: [_BASE_URL_DOMAIN],
        headerName: 'X-Session-Token',
        authScheme: '',
        blacklistedRoutes: []
      }
    }),
    AppRoutingModule,
    FormsModule,
    NgbModule,
    YTPlayerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SwiperModule,
    NgScrollbarModule,
    FacebookModule.forRoot()
  ],
  providers: [
    // Localization (i18n).
    {provide: LOCALE_ID, useValue: _config.i18nLocaleFull},
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TcfaModalLoadingComponent,
    TcfaModalErrorComponent,
    TcfaModalAlertComponent,
    TcfaSegurosModalComponent,
    TcfaLegalTextModalComponent,
    TcfaModalShareComponent,
    TcfaModalShareWithFriendsComponent,
    TcfaModalSuccessComponent
  ]
})
export class AppModule {
}
