import { ITcfaEnvironmentConfiguration } from '../app/shared/environment-manager.shared';


export const environment: ITcfaEnvironmentConfiguration = {
  apiBaseURL: 'https://api.tcfautos.com.ar/index.php',
  pediTuPrestamoURL: 'https://www.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal',

  tcfaSegurosSdkURL: 'https://widgetseguros.tcfautos.com.ar/sdk/sdk.js',
  tcfaSegurosSdkEnv: 'PROD',

  facebookAppId: '268431527208021',
  facebookAppSecret: '368aae8765d75b091fb8364a93020581',

  reCaptchaApiKey: '6Lfbh34UAAAAADFBQCYKTnYrjgD9h9uQVZ3rojTo'
};
