import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanModel } from '../../models/plan.model';
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
import { NgScrollbar } from 'ngx-scrollbar';


const _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'TcfaLegalTextModalComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-tcfa-legal-text-modal',
  templateUrl: './tcfa-legal-text-modal.component.html',
  styleUrls: ['./tcfa-legal-text-modal.component.scss']
})
export class TcfaLegalTextModalComponent implements OnInit, OnChanges {
  @ViewChild(NgScrollbar) textScrollbar: NgScrollbar;
  @Input() plan: PlanModel;

  constructor(
    public activeModal: NgbActiveModal,
    private tcfaContextService: TcfaContextService
  ) {
  }

  ngOnInit() {
    if (!this.plan.legalTexts) {
      this.tcfaContextService.getLegalTextsForPlan(this.plan);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.textScrollbar.update();
    }, 200);
  }
}
