import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
// Components.
import { TcfaLegalTextModalComponent } from '../tcfa-legal-text-modal/tcfa-legal-text-modal.component';
import { TcfaSegurosModalComponent } from '../tcfa-seguros-modal/tcfa-seguros-modal.component';
import { TcfaModalShareComponent } from '../tcfa-modal-share/tcfa-modal-share.component';
// Models.
import { PlazoModel } from '../../models/plazo.model';
import { PlanModel } from '../../models/plan.model';
import { InsuranceModel } from '../../models/insurance.model';
import { BaseAppError } from '../../services/error/base-app-error';
import { ICotizadorDoObtainPdfResponse } from '../../services/cotizador/i-cotizador-do-obtain-pdf-response';
// Providers.
import { TcfaContextService } from '../../services/tcfa-context/tcfa-context.service';
import { DateroLogService } from '../../services/datero-log/datero-log.service';
import { ParamsService } from '../../services/params/params.service';
import { CotizadorService } from '../../services/cotizador/cotizador.service';
import { TcfaCommonModalService } from '../../services/tcfa-common-modal/tcfa-common-modal.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'LoansComponent';
_logger.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss']
})
export class LoansComponent implements OnInit {
  @ViewChild('secondaryBarFixedLowerBound') protected secondaryBarFixedLowerBound: ElementRef<HTMLDivElement>;
  @ViewChild('SecondaryBar') protected SecondaryBar: ElementRef<HTMLDivElement>;

  @Output() loanAmountChange = new EventEmitter<[number, { [key: string]: boolean }]>();

  private screenHeight: number;
  private screenWidth: number;
  private secondPlanSectionOffsetTop: number;

  protected get legalSeguro(): string {
    // Legal text for Cars.
    const __insurance_text = 'El seguro corresponde a ' +
      ((this.tcfaContextService.insurance && this.tcfaContextService.insurance.name) || '') +
      ', pack ' + ((this.tcfaContextService.insurance && this.tcfaContextService.insurance.pack) || '') +
      ', zona ' + ((this.tcfaContextService.insurance && this.tcfaContextService.insurance.zone) || '') +
      ', ' + ((this.tcfaContextService.insurance && this.tcfaContextService.insurance.prov) || ''),
      __additional_legal_text = '. El premio del seguro podrá registrar variaciones si se selecciona otra aseguradora y/u otra zona de' +
        ' guarda del vehículo. El valor del seguro es estimado y depende de las variaciones de la aseguradora elegida.';

    return __insurance_text + __additional_legal_text;
  }

  protected get aclaracionCaidaDeCuota(): string {
    const __text = "La cuota pura fue calculada con la cotización de UVA utilizada por TCFA al día de hoy, " +
        "por lo cual podrá diferir al momento del otorgamiento del crédito.";
    return __text;
  }

  constructor(
    protected tcfaContextService: TcfaContextService,
    private dateroLogService: DateroLogService,
    private viewportScroller: ViewportScroller,
    private elementRef: ElementRef,
    private paramsService: ParamsService,
    private modalService: NgbModal,
    private tcfaCommonModalService: TcfaCommonModalService,
    private cotizadorService: CotizadorService,
    private router: Router
  ) {
    this.onResize();
  }

  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');
  }

  public autoSelectPlanFromSharedLink(): void {
    const __SUBTAG = 'autoSelectPlanFromSharedLink';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked. Cotizacion:', this.tcfaContextService.cotizacion,
      '; Shared Link:', this.tcfaContextService.sharedLinkData);

    if (this.tcfaContextService.sharedLinkData && (this.tcfaContextService.sharedLinkData.link_id >= 2) &&
      this.tcfaContextService.sharedLinkData.plazo && this.tcfaContextService.sharedLinkData.subplan_id) {
      let __plan: PlanModel;
      this.tcfaContextService.cotizacion.plazos
        .some(plazo => plazo.planes
          .some(plan => {
            if ((plan.installmentsCount === this.tcfaContextService.sharedLinkData.plazo) &&
              (plan.subplanId === this.tcfaContextService.sharedLinkData.subplan_id)) {
              __plan = plan;
              return true;
            }
          }));
      if (__plan) {
        const __loadingModal = this.tcfaCommonModalService.showLoadingModal(
          'Aguarde unos instantes mientras cotizamos el seguro del vehículo.');
        this.tcfaContextService.changeInsuranceData(new InsuranceModel({
          localidad: {
            cp: this.tcfaContextService.sharedLinkData.insurance_zone_cp,
            name: this.tcfaContextService.sharedLinkData.insurance_pack_zone
          },
          provincia: {
            id: this.tcfaContextService.sharedLinkData.insurance_prov_id,
            name: this.tcfaContextService.sharedLinkData.insurance_prov_name
          },
          seguro: {
            p_cob_desc_ampliada_c: this.tcfaContextService.sharedLinkData.insurance_detail,
            p_cobertura_desc_c: this.tcfaContextService.sharedLinkData.insurance_pack_name,
            p_compania_desc_c: this.tcfaContextService.sharedLinkData.insurance_company_name,
            p_compania_id_i: this.tcfaContextService.sharedLinkData.insurance_company_id.toString(),
            p_cotizacion_id_c: parseInt(this.tcfaContextService.sharedLinkData.insurance_id, 10),
            p_cuota_n: this.tcfaContextService.sharedLinkData.insurance_installment,
            p_familia_cod_c: this.tcfaContextService.sharedLinkData.insurance_pack_id
          },
          success: true
        }));
        __plan.plazo.loanInfoPromise
          .then(() => {
            this.onSelectPlan(__plan);
          })
          .finally(() => {
            __loadingModal.then(modal => {
              modal.dismiss();
            });
            this.tcfaContextService.sharedLinkData = null;
          });
      }
    }
  }

  private resetFixedBarBounds(): void {
    setTimeout(() => {
      this.secondPlanSectionOffsetTop = null;
    }, 100);
  }

  protected onSelectPlazo(plazo: PlazoModel): void {
    if (plazo.isDisabled) {
      return;
    }
    if (!plazo.isLoanInfoComplete) {
      this.tcfaContextService.getPlanesLoanInfo(plazo);
    }
    plazo.isSelected = !plazo.isSelected;
    this.resetFixedBarBounds();

    // Scroll to plazo table.
    if (plazo.isSelected) {
      setTimeout(() => {
        this.viewportScroller.scrollToAnchor('plazo' + plazo.id);
      }, 500);
    }

    // Datero Log event 2: user clicks plazo.
    if (plazo.isSelected) {
      this.dateroLogService.doLog(2, plazo.installments, this.tcfaContextService.dealer.cuit).subscribe();
    }
  }

  protected onTogglePlazoCollapse(plazo: PlazoModel): void {
    plazo.isCollapsed = !plazo.isCollapsed;
    this.resetFixedBarBounds();
  }

  // Change loan amount handler.
  protected onChangeLoanAmount(plan: PlanModel): void {
    this.tcfaCommonModalService.showAlertModal('Se ajustará el capital a financiar en todos los planes.')
      .then(modal => {
        modal.result
          .then(() => {
            // Save pre selected plazos to render them selected again after the cotizar POST.
            const __preSelectedPlazos: { [key: string]: boolean } = {};
            this.tcfaContextService.cotizacion.plazos.forEach((plazo_item) => {
              if (plazo_item.isSelected) {
                __preSelectedPlazos[plazo_item.installments.toString()] = true;
              }
            });
            this.loanAmountChange.emit([plan.promoAmount, __preSelectedPlazos]);
          });
      });
  }

  /** Checks if the user selected a loan insurance. Returns promise that resolves when the insurance is selected. */
  private checkCotizacionSeguro(reset: boolean = false, plan: PlanModel = null): Promise<InsuranceModel> {
    const __SUBTAG = 'checkCotizacionSeguro';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    if (this.tcfaContextService.insurance && !reset) {
      _logger.debug(_TAG, __SUBTAG, 'About to return: pre-data.');
      return Promise.resolve(this.tcfaContextService.insurance);
    }

    if (!this.tcfaContextService.cotizacion) {
      _logger.debug(_TAG, __SUBTAG, 'About to reject: missing cotizacion.');
      return Promise.reject('Información faltante.');
    }

    // Datero Log event 5: user clicks "ver cuotas" to select insurance (insurance not selected yet).
    if (plan) {
      this.dateroLogService.doLog(5, plan.installmentsCount, this.tcfaContextService.dealer.cuit, plan.planId, plan.subplanId).subscribe();
    }

    _logger.debug(_TAG, __SUBTAG, 'About to return: TCFA Seguros SDK promise.');
    return new Promise<InsuranceModel>((resolve, reject) => {
      const __modal = this.modalService.open(TcfaSegurosModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: `modal-tcfa-seguros modal-animate`,
        keyboard: false,
        backdrop: true,
        centered: true,
        size: 'lg'
      });

      __modal.result
        .then((result) => {
          _logger.debug(_TAG, __SUBTAG, 'About to resolve: operation ended with result:', result);
          resolve(result);
        }, (reason) => {
          if (reason === ModalDismissReasons.ESC) {
            _logger.debug(_TAG, __SUBTAG, 'About to reject: Seguros modal closed by pressing ESC.');
            reject(reason);
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            _logger.debug(_TAG, __SUBTAG, 'About to reject: Seguros modal closed by clicking on a backdrop.');
            reject(reason);
          } else {
            _logger.debug(_TAG, __SUBTAG, 'About to reject: Seguros modal closed with reason:', reason);
            reject(reason);
          }
        });
    });
  }

  protected onSelectPlan(plan: PlanModel): void {
    const __SUBTAG = 'onSelectPlan';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked.');

    if (plan.disableInstallments || plan.errorInstallments || plan.errorUltimaCuota || plan.errorCuotaPromedio || plan.errorCuotaRefuerzo ||
      plan.errorCanonAnticipado || plan.errorOpcionDeCompra || plan.errorCanonPromedioMensual || plan.errorPrimeraCuota || plan.errorCuotaPuraEnUvas) {
      _logger.debug(_TAG, __SUBTAG, 'Method interrupt.');
      return;
    }

    // Validacion seguro seleccionado.
    this.checkCotizacionSeguro(false, plan)
      .then((check_insurance_data) => {
        _logger.debug(_TAG, __SUBTAG, 'checkCotizacionSeguro return. Result:', check_insurance_data);

        this.tcfaContextService.getLegalTextsForPlan(plan);

        plan.isCollapsed = !plan.isCollapsed;
        this.resetFixedBarBounds();

        if (!plan.isCollapsed) {
          // Scroll to installments table.
          setTimeout(() => {
            this.viewportScroller.scrollToAnchor('plan' + plan.subplanId);
          }, 800);

          plan.plazo.loanInfoPromise
            .then((loan_data) => {
              const __installments_data = loan_data.filter(l => l.p_sup_id_i === plan.subplanId);

              if (__installments_data.length === 1) {
                // Datero Log event 3: insurance selected.
                this.dateroLogService.doLog(
                  3,
                  plan.installmentsCount,
                  this.tcfaContextService.dealer.cuit,
                  plan.planId,
                  plan.subplanId,
                  __installments_data[0].tna
                ).subscribe();
              } else {
                _logger.debug(_TAG, __SUBTAG, 'Datero error on filter installments info');
              }
            })
            .catch((error) => {
              _logger.debug(_TAG, __SUBTAG, 'Datero error on get installments info ->', error);
            });
        }
      }, (error) => {
        _logger.debug(_TAG, __SUBTAG, 'checkCotizacionSeguro reject. Reason:', error);

        // Datero Log event 6: insurance not selected.
        this.dateroLogService
          .doLog(6, plan.installmentsCount, this.tcfaContextService.dealer.cuit, plan.planId, plan.subplanId)
          .subscribe();

        let __error_message = 'No se pudo obtener la cotización del seguro. Este dato es necesario a fin de calcular la financiación.';
        if (error === 'close') {
          __error_message = 'Es necesario seleccionar un seguro a fin de calcular la financiación.';
        }
        this.tcfaCommonModalService.showErrorModal(__error_message);
      });
  }

  protected onChoosePlan(plan: PlanModel): void {
    const __pao_params: any = {
        cuit_cliente: 0,
        cuit_dealer: (this.tcfaContextService.dealer.cuit || 0),
        id_publicacion: 0,
        id_campania_pao: 0,
        tipo_persona: (this.tcfaContextService.selectedPersonType && this.tcfaContextService.selectedPersonType.loanCode)
      },
      __stats_params = {};

    // Validar seguro seleccionado.
    this.checkCotizacionSeguro(false, plan)
      .then(() => {
        // Add insurance data.
        if (this.tcfaContextService.insurance && this.tcfaContextService.insurance.id) {
          __pao_params.id_cotizacion_seg = this.tcfaContextService.insurance.id;
        }

        __pao_params.canal = 'COT';

        plan.plazo.loanInfoPromise
          .then((loan_data) => {
            const __installments_data = loan_data.filter(l => l.p_sup_id_i === plan.subplanId);

            if (__installments_data.length === 1) {
              // Datero Log event 4: user clicks PAO.
              this.dateroLogService.doLog(4, plan.installmentsCount, this.tcfaContextService.dealer.cuit,
                plan.planId, plan.subplanId, __installments_data[0].tna)
                .subscribe((result_or_error: number | BaseAppError) => {
                  // Failed.
                  if (result_or_error instanceof BaseAppError) {
                    _logger.debug('Datero error on get id_session ->', result_or_error);
                    return;
                  }

                  // Succeed.
                  __pao_params.id_sesion = result_or_error;
                }, (error: any) => {
                  _logger.debug('Datero fail on get id_session ->', error);
                }, () => {
                  window.open(_config.pediTuPrestamoURL + '?' +
                    Object.entries(__pao_params).map(([key, val]) => `${key}=${val}`).join('&'), '_self');
                });
            } else {
              _logger.debug('Datero error on filter installments info.');
            }
          })
          .catch((error) => {
            _logger.debug('Datero error on get installments info ->', error);
          });
      }, (error) => {
        let __error_message = 'No se pudo obtener la cotización del seguro. Este dato es necesario a fin de solicitar el aprobado.';

        if (error === 'close') {
          __error_message = 'Es necesario seleccionar un seguro a fin de solicitar el aprobado.';
        }

        this.tcfaCommonModalService.showErrorModal(__error_message);
      });
  }

  private getPlanPdf(plan: PlanModel): Promise<ICotizadorDoObtainPdfResponse> {
    return new Promise<any>((resolve, reject) => {
      if (!plan.legalTexts) {
        this.tcfaContextService.getLegalTextsForPlan(plan);
      }

      plan.legalTextsPromise
        .then(() => {
          this.cotizadorService
            .doObtainPdf(this.tcfaContextService.isRetailOperation, this.tcfaContextService.selectedOperation.name, plan.isPromo,
              this.tcfaContextService.selectedVehicle.image,
              this.tcfaContextService.vehicles.name + ' ' + this.tcfaContextService.selectedVehicle.name +
              ' ' + this.tcfaContextService.selectedVersion.name,
              plan.name, this.tcfaContextService.selectedTaxCondition.name, this.tcfaContextService.selectedPersonType.name,
              plan.installmentsCount, this.legalSeguro, plan.getCuotaPromedio(
                this.tcfaContextService.isRetailOperation,
                this.tcfaContextService.loanInsuranceInstallment,
                this.tcfaContextService.loanInsuranceInstallment
              ), this.tcfaContextService.vehiclePriceByOperation, this.tcfaContextService.loanAmount,
              plan.getCanonAnticipado(this.tcfaContextService.isRetailOperation,
                this.tcfaContextService.vehiclePriceByOperation),
              plan.getCuotaRefuerzo(this.tcfaContextService.isRetailOperation, this.tcfaContextService.loanAmount),
              plan.getCanonPromedioMensual(this.tcfaContextService.isRetailOperation, this.tcfaContextService.loanInsuranceInstallment),
              plan.getUltimaCuota(this.tcfaContextService.isRetailOperation, this.tcfaContextService.loanInsuranceInstallment),
              plan.getOpcionDeCompra(this.tcfaContextService.isRetailOperation, this.tcfaContextService.vehiclePriceByOperation),
              plan.legalTexts, plan.p_ref_porcentaje_n,
              plan.installments.map((installment) => [
                installment.number,
                ((this.tcfaContextService.isRetailOperation && (_config.currencySymbol +
                  installment.cuotaPura.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}))) ||
                  (_config.currencySymbol + installment.canon.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}))),
                (_config.currencySymbol +
                  this.tcfaContextService.loanInsuranceInstallment.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})),
                (_config.currencySymbol +
                  installment.costoMensual.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})),
                (_config.currencySymbol + installment.iva.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})),
                ((this.tcfaContextService.isRetailOperation && (_config.currencySymbol +
                  installment.getCuotaFinal(this.tcfaContextService.loanAmount,
                    this.tcfaContextService.loanInsuranceInstallment, plan.isUva)
                    .toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}))) ||
                  (_config.currencySymbol + installment.getCanonFinal(this.tcfaContextService.loanInsuranceInstallment)
                    .toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})))
              ]),
              plan.isUva, this.aclaracionCaidaDeCuota,
              plan.getPrimeraCuota(this.tcfaContextService.isRetailOperation, this.tcfaContextService.loanInsuranceInstallment),
              plan.getCapitalAFinanciarEnUvas(this.tcfaContextService.isRetailOperation, this.tcfaContextService.loanAmount),
              plan.getCuotaPuraEnUvas(this.tcfaContextService.isRetailOperation))
            .subscribe((result_or_error: ICotizadorDoObtainPdfResponse | BaseAppError) => {
              // Failed.
              if (result_or_error instanceof BaseAppError) {
                reject(result_or_error);
                return;
              }

              // Succeed.
              resolve(result_or_error);
            }, (error: any) => {
              reject(error);
            });
        }, (error) => {
          reject(error);
        });
    });
  }

  protected onDownloadInstallments(plan: PlanModel): void {
    const __SUBTAG = 'onDownloadInstallments';
    _logger.debug(_TAG, __SUBTAG, 'Method invoked. Plan:', plan);

    if (plan.isLoadingPDF) {
      return;
    }
    plan.isLoadingPDF = true;

    const __loading_modal = this.tcfaCommonModalService.showLoadingModal('Generando el documento. Por favor espere.');
    this.getPlanPdf(plan)
      .then((pdf_response) => {
        const __decoded_pdf = window.atob(pdf_response.data.file_pdf),
          __pdf_len = __decoded_pdf.length,
          __bytes = new Uint8Array(__pdf_len),
          __pdf_link: HTMLAnchorElement = window.document.createElement('a') as HTMLAnchorElement;

        for (let i = 0; i < __pdf_len; i = i + 1) {
          __bytes[i] = __decoded_pdf.charCodeAt(i);
        }
        const __blob = new Blob([__bytes], {type: 'application/pdf'});
        __pdf_link.download = (pdf_response.data.titulo || 'Cuadro de marcha') + '.pdf';
        const __download_url = URL.createObjectURL(__blob);
        __pdf_link.href = __download_url;
        window.document.body.appendChild(__pdf_link);
        __pdf_link.click();
        window.document.body.removeChild(__pdf_link);
        URL.revokeObjectURL(__download_url);
        __loading_modal.then(modal => modal.close());
      })
      .catch((reason: any) => {
        _logger.debug(_TAG, __SUBTAG, 'getPlanPdf failed. Error:', reason);
        __loading_modal.then(modal => modal.dismiss('error'));
        this.tcfaCommonModalService.showErrorModal('No se pudo obtener el documento.');
      })
      .finally(() => {
        plan.isLoadingPDF = false;
      });
  }

  protected onPrintInstallments(plan: PlanModel): void {
    if (plan.isLoadingPDF) {
      return;
    }
    plan.isLoadingPDF = true;

    // New windows must be allowed.
    const __pdf_window = window.open('');
    if (__pdf_window) {
      const __loading_modal = this.tcfaCommonModalService.showLoadingModal('Generando el documento. Por favor espere.');
      __pdf_window.document.title = 'TCFAutos - Imprimir cotización: Cargando documento, Por favor espere.';
      __pdf_window.document.body.innerText = 'Cargando documento, Por favor espere.';
      this.getPlanPdf(plan)
        .then((pdf_response) => {
          const __iframe: HTMLIFrameElement = window.document.createElement('iframe') as HTMLIFrameElement;
          __iframe.src = 'data:application/pdf;base64,' + pdf_response.data.file_pdf;
          __iframe.style.width = '100%';
          __iframe.style.height = '100%';
          __iframe.style.border = '0';
          __iframe.style.margin = '0';
          __iframe.style.padding = '0';
          __pdf_window.document.body.innerText = '';
          __pdf_window.document.body.style.margin = '0';
          __pdf_window.document.body.style.padding = '0';
          __pdf_window.document.documentElement.style.margin = '0';
          __pdf_window.document.documentElement.style.padding = '0';
          __pdf_window.document.body.appendChild(__iframe);
          __pdf_window.document.title = 'TCFAutos - Imprimir cotización: ' + pdf_response.data.titulo;
          __pdf_window.focus();
          __loading_modal.then(modal => modal.close());
        })
        .catch(() => {
          __pdf_window.document.body.innerText = 'No se pudo obtener el documento.';
          __pdf_window.close();
          __loading_modal.then(modal => modal.dismiss('error'));
          this.tcfaCommonModalService.showErrorModal('No se pudo obtener el documento.');
        })
        .finally(() => {
          plan.isLoadingPDF = false;
        });
    } else {
      this.tcfaCommonModalService.showAlertModal('Debe permitir la apertura de ventanas emergentes para visualizar el documento.');
    }
  }

  protected onShareInstallments(plan: PlanModel): void {
    const __modal = this.modalService.open(TcfaModalShareComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: `modal-tcfa-share modal-animate`,
      keyboard: true,
      backdrop: true,
      centered: true,
      size: 'lg'
    });
    __modal.componentInstance.dateroExtraData = {plazo: plan.installmentsCount, plan_id: plan.planId, subplan_id: plan.subplanId};
    __modal.componentInstance.shareUrl = this.cotizadorService.getUrlToSocialShare({
      link_type: 2,
      operation_type: this.tcfaContextService.selectedOperation.key,
      vehicle_brand: this.tcfaContextService.vehicles.id,
      vehicle_brand_name: this.tcfaContextService.vehicles.name,
      vehicle_model: this.tcfaContextService.selectedVehicle.id,
      vehicle_model_name: this.tcfaContextService.selectedVehicle.name,
      vehicle_version: this.tcfaContextService.selectedVersion.id,
      vehicle_version_name: this.tcfaContextService.selectedVersion.name,
      vehicle_condition: ((this.tcfaContextService.selectedVersion.isNew && 1) || 0),
      vehicle_year: this.tcfaContextService.selectedVersion.year,
      vehicle_type: this.tcfaContextService.VEHICLE_TYPE,
      vehicle_id: this.tcfaContextService.selectedVersion.vehicleId,
      vehicle_price: this.tcfaContextService.vehiclePriceByOperation,
      person_type: this.tcfaContextService.selectedPersonType.id,
      loan_amount: this.tcfaContextService.loanAmount,
      tax_condition: this.tcfaContextService.selectedTaxCondition.code,
      canon: (this.tcfaContextService.selectedCanon && this.tcfaContextService.selectedCanon.key),
      plan: null, // Plan from campaign.
      plazo: plan.installmentsCount,
      subplan: plan.subplanId,
      insurance_id: this.tcfaContextService.insurance.id,
      insurance_company_name: this.tcfaContextService.insurance.name,
      insurance_pack_name: this.tcfaContextService.insurance.pack,
      insurance_zone_name: this.tcfaContextService.insurance.zone,
      insurance_prov_name: this.tcfaContextService.insurance.prov,
      insurance_installments: this.tcfaContextService.insurance.installments,
      insurance_company_id: this.tcfaContextService.insurance.company_id,
      insurance_pack_code: this.tcfaContextService.insurance.pack_code,
      insurance_zone_cp: this.tcfaContextService.insurance.zone_cp,
      insurance_prov_id: this.tcfaContextService.insurance.prov_id,
      insurance_detail: this.tcfaContextService.insurance.detail
    });
  }

  protected onShowLegalText(plan: PlanModel): void {
    const __modal = this.modalService.open(TcfaLegalTextModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: `modal-tcfa-legal modal-animate`,
      keyboard: false,
      backdrop: true,
      centered: true,
      size: 'lg'
    });
    __modal.componentInstance.plan = plan;
  }

  // Change modal handler.
  protected onChangeVehicleModel(): void {
    // Datero Log event 8: "Ver Otros Vehiculos".
    this.dateroLogService.doLog(8).subscribe();

    this.tcfaContextService.cotizacion = null;
    this.tcfaContextService.selectedVehicle = null;
    this.router.navigate(['/home']);
    // Scroll top.
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor('app-main-header');
    }, 100);

    this.resetFixedBarBounds();
  }

  protected onChangePlan(): void {
    // Datero Log event 9: "Ver Otros Planes".
    this.dateroLogService.doLog(9).subscribe();
  }

  protected onChangeInsurance(): void {
    this.checkCotizacionSeguro(true);
    this.resetFixedBarBounds();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event?): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  private getSecondaryBarFixedLowerBound(): number {
    if (this.secondaryBarFixedLowerBound && this.secondaryBarFixedLowerBound.nativeElement) {
      return this.secondaryBarFixedLowerBound.nativeElement.offsetTop;
    }
    return undefined;
  }

  private getSecondaryBarFixedOffset(): number {
    if (this.SecondaryBar && this.SecondaryBar.nativeElement) {
      return this.SecondaryBar.nativeElement.clientHeight;
    }
    return undefined;
  }

  /** Returns the bottom of the 1st plan section. */
  private getSecondaryBarFixedUpperBound(): number {
    if (!this.elementRef || !this.elementRef.nativeElement) {
      return undefined;
    }
    const __plan_elements = this.elementRef.nativeElement.querySelectorAll('.contenedor-principal-plancuadro.collapse.show');
    if (__plan_elements.length >= 2) {
      if (!this.secondPlanSectionOffsetTop) {
        let __y = 0,
          __elem = __plan_elements.item(1);

        if (__elem.offsetParent) {
          do {
            __y += __elem.offsetTop;
            __elem = __elem.offsetParent;
          } while (__elem);
        }
        this.secondPlanSectionOffsetTop = __y >= 0 ? __y : 0;
      }
      return this.secondPlanSectionOffsetTop + this.getSecondaryBarFixedOffset();
    }
    return undefined;
  }

  private getViewportBottomScroll(): number {
    return this.viewportScroller.getScrollPosition()[1] + this.screenHeight;
  }

  protected secondaryBarFixed(): boolean {
    return (this.getViewportBottomScroll() >= this.getSecondaryBarFixedUpperBound()) &&
      (this.getViewportBottomScroll() <= this.getSecondaryBarFixedLowerBound());
  }

  protected orderPlanes(isRetail: boolean, planes: any): any {
    if (!isRetail) {
      return planes.sort((a, b) => a.canonPromedioMensual > b.canonPromedioMensual ? 1 : a.canonPromedioMensual === b.canonPromedioMensual ? 0 : -1);
    } else {
      return planes.sort(function (a, b) {
        let _a = a.isUva ? a.primeraCuotaConSeguro : a.cuotaPromedio,
            _b = b.isUva ? b.primeraCuotaConSeguro : b.cuotaPromedio;
        return _a > _b ? 1 : _a === _b ? 0 : -1
      });
    }
  }
}
