import { ICotizadorGetDatosCalculoCuotasResponseCodicionImpositivaItem } from '../services/cotizador/i-cotizador-get-datos-calculo-cuotas-response-codicion-impositiva-item';


export class TaxConditionModel {
  public code: string;
  public isDefault: boolean;
  public name: string;
  public id: number;
  public ivaInteres: number;

  constructor(data: ICotizadorGetDatosCalculoCuotasResponseCodicionImpositivaItem) {
    this.code = data.codigo;
    this.isDefault = data.default;
    this.name = data.descripcion;
    this.id = data.id;
    this.ivaInteres = data.iva_interes;
  }
}
