import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
// Models.
import { BaseAppError } from '../error/base-app-error';
import { ICotizadorGetCanalAnonimoResponse } from './i-cotizador-get-canal-anonimo-response';
import { CanalAnonimoModel } from '../../models/canal-anonimo.model';
import { ICotizadorGetAllVehicleImagesResponse } from './i-cotizador-get-all-vehicle-images-response';
import { ICotizadorGetAllVehicleImagesResponseImageItem } from './i-cotizador-get-all-vehicle-images-response-image-item';
import { ICotizadorGetDatosCalculoCuotasResponse } from './i-cotizador-get-datos-calculo-cuotas-response';
import { PersonTypeModel } from '../../models/person-type.model';
import { ICotizadorGetVehicleLoanInfoResponse } from './i-cotizador-get-vehicle-loan-info-response';
import { ICotizadorDoCotizarResponse } from './i-cotizador-do-cotizar-response';
import { CotizacionModel } from '../../models/cotizacion.model';
import { ICotizadorDoCuadroDeMarchaResponse } from './i-cotizador-do-cuadro-de-marcha-response';
import { ICotizadorDoCuadroDeMarchaResponseCuadroItem } from './i-cotizador-do-cuadro-de-marcha-response-cuadro-item';
import { ICotizadorGetAllSectionsResponse } from './i-cotizador-get-all-sections-response';
import { ICotizadorGetAllSectionsResponseSectionItem } from './i-cotizador-get-all-sections-response-section-item';
import { ICotizadorDoObtainPdfResponse } from './i-cotizador-do-obtain-pdf-response';
import { ICotizadorGetAllTooltipsResponseTooltipItem } from './i-cotizador-get-all-tooltips-response-tooltip-item';
import { ICotizadorGetAllTooltipsResponse } from './i-cotizador-get-all-tooltips-response';
import { ICotizadorShareWithFriendsResponse } from './i-cotizador-share-with-friends-response';
// Providers.
import { ErrorService } from '../error/error.service';
import { PersistanceService } from '../persistance/persistance.service';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _config = EnvironmentManager.getInstance().getTcfaConfig(),
  _logger: SimpleLogger = SimpleLogger.getInstance(),
  _TAG = 'CotizadorService';
_logger.debug(_TAG, 'loaded.');

const _CACHE_CONFIG = {
  GET_ALL_VEHICLE_IMAGES: {
    KEY: 'get_all_vehicle_images',
    AGE: 10 * 60 * 1000 // 10m * 60s * 1000ms.
  },
  GET_ALL_VEHICLE_IMAGE_LOGOS: {
    KEY: 'get_all_vehicle_image_logos',
    AGE: 10 * 60 * 1000 // 10m * 60s * 1000ms.
  },
  GET_DATOS_CALCULO_CUOTAS: {
    KEY: 'get_datos_calculo_cuotas',
    AGE: 8 * 60 * 60 * 1000 // 8h * 60m * 60s * 1000ms.
  },
  GET_ALL_SECTIONS: {
    KEY: 'get_all_sections',
    AGE: 16 * 60 * 60 * 1000 // 16h * 60m * 60s * 1000ms.
  },
  GET_ALL_TOOLTIPS: {
    KEY: 'get_all_tooltips',
    AGE: 24 * 60 * 60 * 1000 // 24h * 60m * 60s * 1000ms.
  }
};


@Injectable({
  providedIn: 'root'
})
export class CotizadorService {
  private baseURL = `${_config.apiBaseURL}/cotizador`;

  private getDatosCalculoCuotasCache: Observable<PersonTypeModel[] | BaseAppError>;
  private getAllSectionsCache: Observable<ICotizadorGetAllSectionsResponseSectionItem[] | BaseAppError>;
  private getAllTooltipsCache: Observable<ICotizadorGetAllTooltipsResponseTooltipItem[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private persistanceService: PersistanceService
  ) {
  }

  public getCanalAnonimo(): Observable<CanalAnonimoModel | BaseAppError> {
    const __SUBTAG = 'getCanalAnonimo';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

      const __endpointURL = `${this.baseURL}/lexus/canal_anonimo`;
      _logger.debug(_TAG, __SUBTAG, 'request:', 'GET', __endpointURL);

      return this.http.get<ICotizadorGetCanalAnonimoResponse>(__endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<ICotizadorGetCanalAnonimoResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<ICotizadorGetCanalAnonimoResponse>) => new CanalAnonimoModel(response.body)),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
  }

  public getAllVehicleImages(brand_id: number, models_id: number[]):
    Observable<ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError> {
    const __SUBTAG = 'getAllVehicleImages',
      __cache_validator = brand_id.toString() + '_' + models_id.join('_');
    _logger.info(_TAG, __SUBTAG, 'method invoked.');
    _logger.debug(_TAG, __SUBTAG, 'inputs:', 'Brand ID:', brand_id, '; Models ID:', models_id.join(', '));

    // Check cache.
    if (this.persistanceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.KEY, _CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.AGE,
      __cache_validator)) {
      const __endpointURL = `${this.baseURL}/imagen_unidad`,
        __payload = {
          marca_id: brand_id,
          modelos_id: models_id
        };
      _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', __payload);

      return this.http.post<ICotizadorGetAllVehicleImagesResponse>(__endpointURL, __payload, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<ICotizadorGetAllVehicleImagesResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<ICotizadorGetAllVehicleImagesResponse>) => response.body.data),
          // Store in cache (base response).
          tap((data: ICotizadorGetAllVehicleImagesResponseImageItem[]) => {
            this.persistanceService.store(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.KEY, data, __cache_validator);
          }),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      return of(this.persistanceService.retrieve(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.KEY, _CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.AGE,
        __cache_validator))
        .pipe(
          // Log operation.
          tap((data: ICotizadorGetAllVehicleImagesResponseImageItem[]) => {
            _logger.debug(_TAG, __SUBTAG, 'cache response:', data);
          }),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    }

    // Invalid cache state.
    this.persistanceService.clear(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.KEY, _CACHE_CONFIG.GET_ALL_VEHICLE_IMAGES.AGE, __cache_validator);
    return this.getAllVehicleImages(brand_id, models_id);
  }

  public getAllVehicleImageLogos(brand_id: number, models_id: number[]):
    Observable<ICotizadorGetAllVehicleImagesResponseImageItem[] | BaseAppError> {
    const __SUBTAG = 'getAllVehicleImageLogos',
      __cache_validator = brand_id.toString() + '_' + models_id.join('_');
    _logger.info(_TAG, __SUBTAG, 'method invoked.');
    _logger.debug(_TAG, __SUBTAG, 'inputs:', 'Brand ID:', brand_id, '; Models ID:', models_id.join(', '));

    // Check cache.
    if (this.persistanceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.KEY,
      _CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.AGE, __cache_validator)) {
      const __endpointURL = `${this.baseURL}/imagen_logo_unidad`,
        __payload = {
          marca_id: brand_id,
          modelos_id: models_id
        };
      _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', __payload);

      return this.http.post<ICotizadorGetAllVehicleImagesResponse>(
        __endpointURL, __payload, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<ICotizadorGetAllVehicleImagesResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response. Returns OK flag.
          map((response: HttpResponse<ICotizadorGetAllVehicleImagesResponse>) => response.body.data),
          // Store in cache (base response).
          tap((data: ICotizadorGetAllVehicleImagesResponseImageItem[]) => {
            this.persistanceService.store(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.KEY, data, __cache_validator);
          }),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      return of(this.persistanceService.retrieve(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.KEY,
        _CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.AGE, __cache_validator))
        .pipe(
          // Log operation.
          tap((data: ICotizadorGetAllVehicleImagesResponseImageItem[]) => {
            _logger.debug(_TAG, __SUBTAG, 'cache response:', data);
          }),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    }

    // Invalid cache state.
    this.persistanceService.clear(_CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.KEY, _CACHE_CONFIG.GET_ALL_VEHICLE_IMAGE_LOGOS.AGE,
      __cache_validator);
    return this.getAllVehicleImageLogos(brand_id, models_id);
  }

  public getDatosCalculoCuotas(): Observable<PersonTypeModel[] | BaseAppError> {
    const __SUBTAG = 'getDatosCalculoCuotas';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    // Check cache.
    if (this.persistanceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_DATOS_CALCULO_CUOTAS.KEY, _CACHE_CONFIG.GET_DATOS_CALCULO_CUOTAS.AGE)) {
      const __endpointURL = `${_config.apiBaseURL}/cuotas/data`;
      _logger.debug(_TAG, __SUBTAG, 'request:', 'GET', __endpointURL);

      this.getDatosCalculoCuotasCache = this.http.get<ICotizadorGetDatosCalculoCuotasResponse>(__endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<ICotizadorGetDatosCalculoCuotasResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<ICotizadorGetDatosCalculoCuotasResponse>) => response.body.data.tipos_persona
            .map(p => new PersonTypeModel(p))),
          // Store in cache.
          tap((data: PersonTypeModel[]) => {
            this.persistanceService.store(_CACHE_CONFIG.GET_DATOS_CALCULO_CUOTAS.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.getDatosCalculoCuotasCache) {
        const __cacheData: PersonTypeModel[] = this.persistanceService.retrieve(_CACHE_CONFIG.GET_DATOS_CALCULO_CUOTAS.KEY,
          _CACHE_CONFIG.GET_DATOS_CALCULO_CUOTAS.AGE);
        this.getDatosCalculoCuotasCache = of(__cacheData)
          .pipe(
            // Log operation.
            tap((data: PersonTypeModel[]) => {
              _logger.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }
    return this.getDatosCalculoCuotasCache;
  }

  public getVehicleLoanInfo(vehicle_is_new: boolean, vehicle_year: number, person_code: string,
                            dealer_cuit: string, vehicle_type_id: number, brand_id: number,
                            model_id: number, version_id: number): Observable<ICotizadorGetVehicleLoanInfoResponse | BaseAppError> {
    const __SUBTAG = 'getVehicleLoanInfo';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    const __endpointURL = `${this.baseURL}/auto`,
      __payload = {
        p_tipo_operacion_c: 'RE', // Operation type.
        p_tipo_persona_c: person_code, // Person type.
        p_veh_nuevo_i: (vehicle_is_new ? 1 : 0), // Vehicle condition.
        p_veh_anio_i: vehicle_year, // Vehicle year.
        p_cuit_dealer_c: dealer_cuit, // CUIT dealer.
        p_tve_id_i: vehicle_type_id, // Vehicle type.
        p_mar_id_i: brand_id, // Vehicle brand ID FU.
        p_mod_id_i: model_id, // Vehicle model ID FU.
        p_ver_id_i: version_id // Vehicle version ID FU.
      };
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload:', __payload);

    return this.http.post<ICotizadorGetVehicleLoanInfoResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<ICotizadorGetVehicleLoanInfoResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<ICotizadorGetVehicleLoanInfoResponse>) => response.body),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public doCotizar(cuit_dealer: string, operation_code: string, brand_id: number, modelo_id: number, version_id: number,
                   person_code: string, loan_amount: number, canon: string, tax_id: number, is_new: boolean, vehicle_id: number,
                   vehicle_price: number, remove_plan_filter: boolean, vehicle_year: number,
                   vehicle_type: number): Observable<CotizacionModel | BaseAppError> {
    const __SUBTAG = 'doCotizar';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    const __endpointURL = `${this.baseURL}/auto/cotizar`,
      __payload = {
        p_cuit_dealer_c: cuit_dealer,
        p_tipo_operacion_c: operation_code,
        planes: [],
        p_mar_id_i: brand_id,
        p_mod_id_i: modelo_id,
        p_ver_id_i: version_id,
        p_tipo_persona_c: person_code,
        monto_financiar: loan_amount,
        canon_anticipado: canon,
        condicion_impositiva_id: tax_id,
        p_veh_nuevo_i: (is_new && 1 || 0),
        p_veh_id_i: vehicle_id,
        p_precio_usado_n: vehicle_price,
        remove_plan_filter: remove_plan_filter,
        p_veh_anio_i: vehicle_year,
        p_tve_id_i: vehicle_type
      };
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload:', __payload);

    return this.http.post<ICotizadorDoCotizarResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<ICotizadorDoCotizarResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<ICotizadorDoCotizarResponse>) => new CotizacionModel(response.body.data.p_planes_o, loan_amount)),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public doCuadroDeMarcha(vehicle_id: number, cuit_dealer: string, planes: { id: number, flag_TDF: boolean, subplanes: number[] }[],
                          person_code: string, vehicle_is_new: boolean, operation_code: string, vehicle_price: number, loan_amount: number,
                          tax_id: number, vehicle_iva: number):
    Observable<ICotizadorDoCuadroDeMarchaResponseCuadroItem[] | BaseAppError> {
    const __SUBTAG = 'doCuadroDeMarcha';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');
    _logger.debug(_TAG, __SUBTAG, 'inputs:', 'Vehicle ID:', vehicle_id, '; CUIT Dealer:', cuit_dealer, '; Planes:', planes,
      '; Person Code:', person_code, '; Vehicle New:', vehicle_is_new, '; Op. Code:', operation_code,
      '; Loan amount:', loan_amount, '; Cond. Imp.:', tax_id, '; Vehicle IVA:', vehicle_iva);

    const __endpointURL = `${this.baseURL}/auto/cuadro_de_marcha`,
      __payload = {
        p_veh_id_i: (vehicle_id),
        p_cuit_dealer_c: cuit_dealer,
        planes: planes,
        p_tipo_persona_c: person_code,
        p_veh_nuevo_i: (vehicle_is_new ? 1 : 0),
        p_tipo_operacion_c: operation_code,
        p_precio_usado_n: vehicle_price,
        monto_financiar: loan_amount,
        condicion_impositiva_id: tax_id,
        x_veh_iva: vehicle_iva
      };
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', __payload);

    return this.http.post<ICotizadorDoCuadroDeMarchaResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<ICotizadorDoCuadroDeMarchaResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response. Returns OK flag.
        map((response: HttpResponse<ICotizadorDoCuadroDeMarchaResponse>) => response.body.data.cuadro_de_marcha),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public getAllSections(): Observable<ICotizadorGetAllSectionsResponseSectionItem[] | BaseAppError> {
    const __SUBTAG = 'getAllSections';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    // Check cache.
    if (this.persistanceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ALL_SECTIONS.KEY, _CACHE_CONFIG.GET_ALL_SECTIONS.AGE)) {
      const __endpointURL = `${this.baseURL}/seccion`;
      _logger.debug(_TAG, __SUBTAG, 'request:', 'GET', __endpointURL);

      this.getAllSectionsCache = this.http.get<ICotizadorGetAllSectionsResponse>(__endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<ICotizadorGetAllSectionsResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<ICotizadorGetAllSectionsResponse>) => response.body.data),
          // Store in cache.
          tap((data: ICotizadorGetAllSectionsResponseSectionItem[]) => {
            this.persistanceService.store(_CACHE_CONFIG.GET_ALL_SECTIONS.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.getAllSectionsCache) {
        const __cacheData: ICotizadorGetAllSectionsResponseSectionItem[] = this.persistanceService.retrieve(
          _CACHE_CONFIG.GET_ALL_SECTIONS.KEY, _CACHE_CONFIG.GET_ALL_SECTIONS.AGE);
        this.getAllSectionsCache = of(__cacheData)
          .pipe(
            // Log operation.
            tap((data: ICotizadorGetAllSectionsResponseSectionItem[]) => {
              _logger.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }
    return this.getAllSectionsCache;
  }

  public getAllTooltips(): Observable<ICotizadorGetAllTooltipsResponseTooltipItem[] | BaseAppError> {
    const __SUBTAG = 'getAllTooltips';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    // Check cache.
    if (this.persistanceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ALL_TOOLTIPS.KEY, _CACHE_CONFIG.GET_ALL_TOOLTIPS.AGE)) {
      const __endpointURL = `${this.baseURL}/tooltip`;
      _logger.debug(_TAG, __SUBTAG, 'request:', 'GET', __endpointURL);

      this.getAllTooltipsCache = this.http.get<ICotizadorGetAllTooltipsResponse>(__endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<ICotizadorGetAllTooltipsResponse>) => {
            _logger.debug(_TAG, __SUBTAG, 'response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<ICotizadorGetAllTooltipsResponse>) => response.body.data),
          // Store in cache.
          tap((data: ICotizadorGetAllTooltipsResponseTooltipItem[]) => {
            this.persistanceService.store(_CACHE_CONFIG.GET_ALL_TOOLTIPS.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.getAllTooltipsCache) {
        const __cacheData: ICotizadorGetAllTooltipsResponseTooltipItem[] = this.persistanceService.retrieve(
          _CACHE_CONFIG.GET_ALL_TOOLTIPS.KEY, _CACHE_CONFIG.GET_ALL_TOOLTIPS.AGE);
        this.getAllTooltipsCache = of(__cacheData)
          .pipe(
            // Log operation.
            tap((data: ICotizadorGetAllTooltipsResponseTooltipItem[]) => {
              _logger.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }
    return this.getAllTooltipsCache;
  }

  public doObtainPdf(is_retail_op: boolean, operation_name: string, is_plan_promo: boolean, vehicle_img: string, model_name: string,
                     plan_name: string, tax_cond_name: string, person_name: string, installents_count: number, legal_text: string,
                     cuota_promedio: number, vehicle_price, loan_amount: number, canon_anticipado: number, cuota_refuerzo: number,
                     canon_promedio_mensual: number, ultima_cuota: number, opcion_de_compra: number, plan_legal_text: string,
                     p_ref_porcentaje_n: number, cuadro_de_marcha: any[][], is_plan_uva: boolean, aclaracion_caida_de_cuota: string,
                     primera_cuota: number, capital_a_financiar_en_uvas: number,
                     cuota_pura_en_uvas: number): Observable<ICotizadorDoObtainPdfResponse | BaseAppError> {
    const __SUBTAG = 'doObtainPdf';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    const __endpointURL = `${this.baseURL}/lexus/pdf`,
      __payload = {
        tipo_operacion: operation_name,
        plan_promocion: is_plan_promo,
        url_img: vehicle_img,
        modelo: model_name,
        plan: plan_name,
        condicion_impositiva: tax_cond_name,
        tipo_persona: person_name,
        plazo: installents_count + ' Meses',
        legal_seguro: legal_text,
        descripcion_plazo: [
          {
            titulo: '',
            valor: plan_name
          },
          {
            titulo: ((is_retail_op && (is_plan_uva ? 'Cuota inicial\nen $' : 'Cuota promedio')) || 'Valor de la unidad'),
            valor: (is_retail_op && (is_plan_uva ?
                _config.currencySymbol + primera_cuota.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}) :
                _config.currencySymbol + cuota_promedio.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})) ||
              (_config.currencySymbol + vehicle_price.toFixed(0)))
          },
          {
            titulo: ((is_retail_op && (is_plan_uva ? 'Capital a\nfinanciar en $' : 'Capital a financiar')) || 'Canon anticipado'),
            valor: ((is_retail_op && (_config.currencySymbol +
              loan_amount.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}))) ||
              (_config.currencySymbol + canon_anticipado.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})))
          },
          {
            titulo: ((is_retail_op && (is_plan_uva ? 'Capital a\nfinanciar en uvas' : 'Cuota refuerzo')) || 'Canon promedio mensual'),
            valor: ((is_retail_op && (is_plan_uva ?
                capital_a_financiar_en_uvas.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}) :
                ((cuota_refuerzo >= 0) ?
                  (_config.currencySymbol +
                    cuota_refuerzo.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})) : 'No posee'))) ||
              (_config.currencySymbol + canon_promedio_mensual.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})))
          },
          {
            titulo: ((is_retail_op && (is_plan_uva ? 'Cuota pura en\nuvas' : 'Última cuota')) || 'Opción de compra'),
            valor: ((is_retail_op && (is_plan_uva ?
                cuota_pura_en_uvas.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}) :
                _config.currencySymbol + ultima_cuota.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0}))) ||
              (_config.currencySymbol + opcion_de_compra.toLocaleString(_config.i18nLocaleFull, {maximumFractionDigits: 0})))
          }
        ],
        titulo_cuadro_marcha: [
          ((is_retail_op && 'Cuota pura') || 'Canon'),
          'Seguro del vehículo',
          'Costo mensual',
          'IVA',
          (((is_retail_op && 'Cuota') || 'Canon') + ' final')
        ],
        cuadro_marcha: cuadro_de_marcha,
        texto_legal: plan_legal_text,
        opcion_compra: (is_retail_op ? null : (p_ref_porcentaje_n + '% O.C.')),
        plan_uva: is_plan_uva,
        aclaracion_caida_de_cuota: aclaracion_caida_de_cuota
      };
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', __payload);

    return this.http.post<ICotizadorDoObtainPdfResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<ICotizadorDoObtainPdfResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response. Returns OK flag.
        map((response: HttpResponse<ICotizadorDoObtainPdfResponse>) => response.body),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public shareWithFriends(name: string, email: string, friend_name: string, friend_mail: string, message: string,
                          brand_name: string, brand_id: number, model_name: string, model_id: number, version_name: string,
                          year: number, is_new: boolean, price: number, link: string): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'shareWithFriends';
    _logger.info(_TAG, __SUBTAG, 'method invoked.');

    const __endpointURL = `${this.baseURL}/lexus/compartir/amigo`,
      __payload = {
        nombre: name,
        email: email,
        nombreAmigo: friend_name,
        emailAmigo: friend_mail,
        mensaje: message,
        marca: brand_name,
        marcaId: brand_id,
        modelo: model_name,
        modeloId: model_id,
        version: version_name,
        year: year,
        kms: 0,
        is0km: is_new,
        price: price,
        link: link
      };
    _logger.debug(_TAG, __SUBTAG, 'request:', 'POST', __endpointURL, '; Payload', __payload);

    return this.http.post<ICotizadorShareWithFriendsResponse>(__endpointURL, __payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<ICotizadorShareWithFriendsResponse>) => {
          _logger.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response. Returns OK flag.
        map((response: HttpResponse<ICotizadorShareWithFriendsResponse>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  /** Builds an URL suitable to share on social networks. */
  public getUrlToSocialShare(params: any): string {
    const __params: any = {};
    let __url = `${this.baseURL}/lexus/showSocial/`;

    if (__url.indexOf('//') === 0) {
      __url = 'http:' + __url;
    }

    // Link IDs.
    __params.il = (params.link_type || 1);

    // Operation IDs.
    if (params.operation_type) {
      __params.io = params.operation_type;
    }

    // Vehicle's IDs.
    if (params.vehicle_brand || params.vehicle_brand_name) {
      __params.vb = params.vehicle_brand;
      __params.vbn = params.vehicle_brand_name;
    }
    if (params.vehicle_model || params.vehicle_model_name) {
      __params.vm = params.vehicle_model;
      __params.vmn = params.vehicle_model_name;
    }
    if (params.vehicle_version || params.vehicle_version_name) {
      __params.vv = params.vehicle_version;
      __params.vvn = params.vehicle_version_name;
    }
    if (params.vehicle_condition) {
      __params.vc = params.vehicle_condition;
    }
    if (params.vehicle_year) {
      __params.vy = params.vehicle_year;
    }
    if (params.vehicle_type) {
      __params.vt = params.vehicle_type;
    }
    if (params.vehicle_id) {
      __params.vi = params.vehicle_id;
    }

    // Person's IDs.
    if (params.person_type) {
      __params.pt = params.person_type;
    }
    if (params.tax_condition) {
      __params.tc = params.tax_condition;
    }

    // Loan Keys.
    if (params.loan_amount) {
      __params.la = params.loan_amount;
    }
    if (params.canon) {
      __params.ca = params.canon;
    }

    // Plan IDs.
    if (params.plan) {
      __params.ip = params.plan;
    }

    // Subplan IDs.
    if (params.plazo) {
      __params.pl = params.plazo;
    }
    if (params.subplan) {
      __params.is = params.subplan;
    }

    // Insurance IDs.
    if (params.insurance_id) {
      __params.ii = params.insurance_id;
    }
    if (params.insurance_company_name) {
      __params.icn = params.insurance_company_name;
    }
    if (params.insurance_pack_name) {
      __params.ipn = params.insurance_pack_name;
    }
    if (params.insurance_zone_name) {
      __params.izn = params.insurance_zone_name;
    }
    if (params.insurance_prov_name) {
      __params.izpn = params.insurance_prov_name;
    }
    if (params.insurance_installments) {
      __params.iic = params.insurance_installments;
    }
    if (params.insurance_company_id) {
      __params.ici = params.insurance_company_id;
    }
    if (params.insurance_pack_code) {
      __params.ipi = params.insurance_pack_code;
    }
    if (params.insurance_zone_cp) {
      __params.izcp = params.insurance_zone_cp;
    }
    if (params.insurance_prov_id) {
      __params.izpi = params.insurance_prov_id;
    }
    if (params.insurance_detail) {
      __params.idet = btoa(params.insurance_detail);
    }

    return __url + '?' + Object.entries(__params).map(([key, val]) => `${key}=${val}`).join('&');
  }
}
